import React, { useState, useEffect } from 'react';
import axios from '../common/axios';

const SigningPage = () => {
  const [data, setData] = useState([]);



  useEffect(() => {
      const fetchData = async () => {
        try {
            const res = await axios.get('/getsigning'); 
            if(res.data.success){
              setData(res.data.data);
            }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  
  
    const doCount = data.filter(v=>v.상태.includes("서명이 시작되었습니다")).length
  
  return(
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
										<div className="card-header border-0 pt-5">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold fs-3 mb-1">계약 서명 이력</span>
												<span className="text-muted mt-1 fw-semibold fs-7">{data.length}번의 계약 서명이 있었으며 현재 
                                                {doCount}
                                                건의 계약에 서명을 해야합니다.</span>
											</h3>
											<div className="card-toolbar">
											</div>
										</div>
										<div className="card-body py-3">
											<div className="table-responsive">
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													<thead>
														<tr className="fw-bold text-muted">
				
															<th className="min-w-120px">프로젝트 이름</th>
															<th className="min-w-180px">계약명</th>
															<th className="min-w-280px">계약 상태</th>
														</tr>
													</thead>
													<tbody>
                                                        {data && data.map((v,index)=>(
														<tr key={index}>
                                                        <td className="text-dark fw-bold text-hover-primary fs-6">{v.프로젝트}</td>
															<td className="text-dark fw-bold text-hover-primary fs-6">{v.계약명}</td>
															<td>
																<span className= {v.상태 && v.상태.includes("모든 사용자가 서명을 완료했습니다")?"badge  badge-light-success fs-6":v.상태 && v.상태.includes("서명이 시작되었습니다.")?"badge badge-light-danger fs-6" :"badge badge-light-warning fs-6" } >{v.상태}</span>
															</td>
															<td className="text-end">
											
															</td>
														</tr>
												))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
    </div>
    </div>
)};

export default SigningPage;