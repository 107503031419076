import React, { useState, useEffect } from 'react';
import axios from '../../common/axios';
import { Field } from '@progress/kendo-react-form';
import { FormInput } from './form-components';
import { userNameValidator, emailValidator, passwordValidator } from './validators';
import { Button } from '@progress/kendo-react-buttons';
import { useRecoilState } from 'recoil';
import {formStateAtom} from '../../atom/formState';
import Swal from 'sweetalert2';

export const UserDetails = () => {
  const [passwd, setpasswd] = useState('')
  const [passwdconfirm, setpasswdconfirm] = useState('')
  const [username, setusername] = useState('')
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useRecoilState(formStateAtom);


  useEffect(() => {
    if(username ==="" && formState.username !== undefined){
      setusername(formState.username)
    }
	  }, [username,formState]);


  const WrappedPasswordValidator = (value) => {
    return passwordValidator(value, username);
  }
  const onChange = (event)=>{setpasswd(event.target.value)}
  const onChangeconfirm = (event)=>{setpasswdconfirm(event.target.value)}
  const onChange2 = (event)=>{setusername(event.target.value)}

  const confirmvalidator = value =>
  !value || passwdconfirm !== passwd ? "비밀번호가 일치하지 않거나 비어 있습니다." : undefined;


  const handleButtonClick = async () => {
    try {
      // 서버로 요청을 보내고 사용자 이름 중복 여부를 확인합니다.
      const response = await axios.post(`/checkDuplicate`, { username });
      const { isDuplicate } = response.data;
  
      // 결과를 상태 변수에 저장합니다.
      setIsDuplicate(isDuplicate);
  
      if (isDuplicate) {
        Swal.fire({
          icon: 'error',
          title: '중복된 아이디!',
          text: '이미 사용 중인 아이디입니다. 다른 아이디를 선택해주세요.',
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '사용할 수 있는 아이디!',
          text: '이 아이디는 사용할 수 있습니다. 확인을 클릭하면 해당 아이디를 사용하게 됩니다.',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
        }).then((result) => {
          if (result.isConfirmed) {
            setFormState(prev => ({...prev, username}));
            setDisable(true); // 이제 사용자가 이 아이디를 확정하였으므로 중복 검사 버튼 비활성화
          }
        });
      }
    } catch (error) {
      console.error("Error checking duplicate:", error);
    }
  };

  

  return (
  <div>
    <div style={{display: 'flex',justifyContent: 'space-between'}}>
    <Field key={'username'} id={'username'} name={'username'} disabled={disable} label={'회사 아이디'} component={FormInput}  validator={(value) => userNameValidator(value, isDuplicate, disable)} onChange={onChange2} wrapperStyle={{width: "75%" ,marginRight: '5px'}} />
    <Button themeColor={"secondary"} type='button' style={{width: "20%", height:32,marginTop:32}} onClick={handleButtonClick}>중복 검사</Button>
    </div>
    <Field key={'useremail'} id={'useremail'} disabled={isDuplicate} name={'useremail'} label={'회사 이메일'} hint={'비밀번호 재설정에 필요한 이메일입니다.'} type={'email'} component={FormInput} validator={emailValidator} />
    <Field key={'password'} id={'password'}disabled={isDuplicate} name={'password'} label={'비밀번호'} type={'password'} component={FormInput}onChange={onChange} validator={WrappedPasswordValidator} />
    <Field key={'passwordconfirm'} id={'passwordconfirm'}disabled={isDuplicate} name={'passwordconfirm'} label={'비밀번호 확인'} type={'password'} validator={confirmvalidator} onChange={onChangeconfirm} component={FormInput}  />
  </div>);

};