import React, { useState, useEffect } from 'react';
import axios from '../common/axios';

const BiddingPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
      const fetchData = async () => {


        try {
          const res = await axios.get('/getbidding'); 
          if(res.data.success){
            setData(res.data.data);
          }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  
  
  return(
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
										<div className="card-header border-0 pt-5">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold fs-3 mb-1">입찰 이력</span>
												<span className="text-muted mt-1 fw-semibold fs-7">{data.length}번의 입찰</span>
											</h3>
											<div className="card-toolbar">
											</div>
										</div>
										<div className="card-body py-3">
											<div className="table-responsive">
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													<thead>
														<tr className="fw-bold text-muted">
				
															<th className="min-w-150px">프로젝트 이름</th>
															<th className="min-w-150px">입찰명</th>
															{/* <th className="min-w-140px">입찰일</th> */}
															<th className="min-w-120px">입찰 결과</th>
														</tr>
													</thead>
													<tbody>
														{data.length>0 &&  data.map((v, index)=>(
														<tr key={index}>
                                                        <td className="text-dark fw-bold text-hover-primary fs-6">{v.ugenProjectName}</td>
															<td className="text-dark fw-bold text-hover-primary fs-6">{v.BiddingTitle}</td>
															{/* <td className="text-dark fw-bold text-hover-primary fs-6"></td> */}
															<td>
																<span className= {v.BidResultSYS ==="성공"?"badge badge-light-success":"badge badge-light-waring"}>{v.BidResultSYS}</span>
															</td>
															<td className="text-end">
											
															</td>
														</tr>
												))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
    </div>
    </div>
)};

export default BiddingPage;