import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormDatePicker, FormInput } from './form-components';
import {  nameValidator, requiredValidator} from './validators';
import dayjs from 'dayjs';

const isValidBirthDate = (value) => {
  if (!value) return "생년월일은 필수 입력 항목입니다.";
  
  const date = dayjs(value);
  if (!date.isValid()) return "유효하지 않은 날짜 형식입니다.";
  
  const now = dayjs();
  if (date.isAfter(now)) return "생년월일은 현재 날짜보다 이후일 수 없습니다.";
  
  if (date.isBefore(dayjs().subtract(120, 'year'))) return "유효하지 않은 생년월일입니다.";
  
  return undefined;
};

export const CompanyLeaderDetails = 
<div>
     <div style={{display: 'flex',justifyContent: 'space-between'}}>
    <Field key={'대표명'} id={'대표명'} name={"대표명"} label={'대표자 성명'} component={FormInput} validator={nameValidator} wrapperStyle={{width: "49%" ,marginRight: '5px'}} />
    <Field key={'대표자성명영문'} id={'대표자성명영문'} name={"대표자성명영문"} label={'대표자 성명(영문)'} component={FormInput} wrapperStyle={{width:'49%'}} />
    </div>
    
    <Field key={'생년월일'} id={'생년월일'} name={"생년월일"} label={'생년월일'} component={FormDatePicker}  validator={isValidBirthDate}/>
    <Field key={'PhoneNumber'} id={'PhoneNumber'} name={"PhoneNumber"} label={'휴대폰 번호'} mask={"000-0000-0000"}  component={FormInput} />
   
  </div>;