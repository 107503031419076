import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import { Dialog} from '@progress/kendo-react-dialogs';
import { Form, FormElement ,Field, FieldWrapper} from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { Upload ,ExternalDropZone} from '@progress/kendo-react-upload';
import { Button } from '@progress/kendo-react-buttons';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
  } from "@progress/kendo-react-intl";
import krMessage2 from '../login/multistep/kr.json';
import Swal from 'sweetalert2';

loadMessages(krMessage2, "ko-KR");


const uploadRef = React.createRef();

const FormUpload = fieldRenderProps => {
	const {
	  value,
	  id,
	  optional,
	  label,
	  hint,
	  validationMessage,
	  touched,
	  ...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';
	const onChangeHandler = event => {
	  fieldRenderProps.onChange({
		value: event.newState
	  });
	};
	const onRemoveHandler = event => {
	  fieldRenderProps.onChange({
		value: event.newState
	  });
	};

	const display = fieldRenderProps.value && fieldRenderProps.value.length > 0? "none":'block';

	return <FieldWrapper>
		  <Label id={labelId} editorId={id} optional={optional} className='k-form-label'>
			{label}
		  </Label>
		  <LocalizationProvider language="ko-KR">
            <IntlProvider locale="kr">
		  <div className={'k-form-field-wrap'}>
			<Upload ref={uploadRef}       
            restrictions={{
                allowedExtensions: [".pdf"],
              }} 
			id={id} autoUpload={false} showActionButtons={false} multiple={true} files={value} onAdd={onChangeHandler} onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		  </div>
		  <div
          style={{
            height: "10px",
          }}></div>
		<div style={{display:display}}>
		  <ExternalDropZone uploadRef={uploadRef} />
		</div>
		  </IntlProvider>
		  </LocalizationProvider>
		</FieldWrapper>;
  };


const BondPage = () => {
  const [data, setData] = useState([]);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [currentBond, setCurrentBond] = useState(null);


  useEffect(() => {
      const fetchData = async () => {
        try {
            const res = await axios.get('/getbondList'); 
            if(res.data.success){
              setData(res.data.data);
            }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  
	const handleButtonClick = (e) => {
		setVisibleDialog(true);
		setCurrentBond(e)

	};
	
	const handleCloseModal = () => {
		setVisibleDialog(false);
		setCurrentBond(null)
	  };



	  const onStepSubmit = React.useCallback(event => {
	
		const affectedFiles = event.values[currentBond.record]
		const updatedState = [];

		affectedFiles.forEach((file) => {
		  const cc = file.getRawFile();
		  const filetype = file.extension;

		  const reader = new FileReader();
	  
		  reader.onload = function (loadEvent) {
			const base64 = loadEvent.target.result;
			const updatedFile = {
			  name: file.name,
			  base64: base64,
			};
			updatedState.push(updatedFile);

			if (updatedState.length === affectedFiles.length) {
				handleCloseModal();
				Swal.fire({
					title: '확인 요청?',
					text: "입력한 정보를 정말 제출하시겠습니까?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: '예',
					cancelButtonText: '아니오'
				}).then((result) => {
					if (result.isConfirmed) {
						sendInfo(updatedState); 
					}
				});
			}
		  };
		  reader.readAsDataURL(cc);

		});

	  })


	  const sendInfo = (e) => {
		axios.post("/updatewithattach", 
			{
				bpname: "SHI Contract", 
				project: currentBond.projectCode,
				record: currentBond.record,
				files: e
			})  
		.then(response => {
			if (response.data.success) {
				Swal.fire({
					title: 'Success',
					text: '성공적으로 제출되었습니다.',
					icon: 'success',
					confirmButtonText: 'OK'
				})
				.then(() => {
					window.location.reload();  
				});
			} else {
				let errorMessage = response.data.error || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
				Swal.fire({
					title: 'Error',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			}
		})
		.catch(err => {
			let errorMessage = '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
			if (err.response && err.response.data && err.response.data.error) {
				errorMessage += ' ' + err.response.data.error;
			}
			Swal.fire({
				title: 'Error',
				text: errorMessage,
				icon: 'error',
				confirmButtonText: 'OK'
			});
		});
	}
	

  return(
	<>
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
										<div className="card-header border-0 pt-5">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold fs-3 mb-1">보증보험 업로드</span>
												<span className="text-muted mt-1 fw-semibold fs-7">{data.length}개의 계약에 보증보험을 업로드해야합니다.</span>
											</h3>
											<div className="card-toolbar">
											</div>
										</div>
										<div className="card-body py-3">
											<div className="table-responsive">
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													<thead>
														<tr className="fw-bold text-muted">
				
															<th className="min-w-100px">프로젝트 이름</th>
															<th className="min-w-250px">계약명</th>
															<th className="min-w-150px">계약금(공급원가)</th>
															<th className="min-w-120px">선급금(공급원가)</th>
															<th className="min-w-200px">필요 보증 리스트</th>
															<th className="min-w-200px">업로드된 보증 파일 리스트</th>
														</tr>
													</thead>
													<tbody>
                                                        {data && data.map((v,index)=>(
														<tr key={index}>
                                                        <td className="text-dark fw-bold text-hover-primary fs-6">{v.프로젝트}</td>
															<td className="text-dark fw-bold text-hover-primary fs-6">{v.계약명}</td>
															<td className="text-dark fw-bold text-hover-primary fs-6">{v.계약금_공급}</td>
															<td className="text-dark fw-bold text-hover-primary fs-6">{v.선급금_공급}</td>
															<td>
															{v["보증필요리스트"].map((vf, index) => (
																<span key={index} className="text-dark text-hover-primary fs-7 d-block">{vf}</span>
																))}
															</td>
															<td>
															{v["파일 리스트"].length === 0?<span key={index} className="text-dark text-hover-danger fs-7 d-block">첨부된 파일이 없습니다</span>: v["파일 리스트"].map((vf, index) => (
																<span key={index} className="text-dark text-hover-primary fs-7 d-block">{vf}</span>
																))}
															</td>
															<td className="text-end">
															<span onClick={(e) => {e.preventDefault();handleButtonClick(v);}} className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
															<i className="ki-duotone ki-folder-up ">
															<span className="path1"></span>
															<span className="path2"></span>
															</i>
															</span>
															</td>
														</tr>
												))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
    </div>
    </div>
	{visibleDialog &&
        <Dialog title={currentBond.계약명 + " 보증 파일 업로드"}  width={600} onClose={handleCloseModal}> 
            <Form className="my-auto pb-5" onSubmitClick={onStepSubmit} render={formRenderProps => <div>
                    {/* 스텝 바디 */}
                    <FormElement>
					<Field 
					key={currentBond.record} className="attach"
					id={currentBond.record} 
					name={currentBond.record} 
					label={'보증보험 파일 첨부'} 
					hint={`파일을 pdf로 올리세요.${currentBond.보증필요리스트2.join(";")}을 한번에 첨부하세요.`} 
					component={FormUpload}
					/>
                    <div className="d-flex flex-stack pt-15">
                        <div>
                            <Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> 업로드
                            <i className="ki-duotone ki-arrow-right fs-4 ms-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i></Button>
                        </div>
                    </div>
                </FormElement>
                </div>}/>

		</Dialog>
	}
	</>
)};

export default BondPage;