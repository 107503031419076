import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import { Dialog} from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

const ContractPage = () => {
  const [data, setData] = useState({});
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [currentContract, setCurrentContract] = useState(null);
  const [selected, setSelected] = React.useState(0);
  
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleShowModal = (contract) => {
    setCurrentContract(contract);
    setVisibleDialog(true);
  };

  const handleCloseModal = () => {
    setCurrentContract(null);
    setVisibleDialog(false);
  };

  
  useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get('/getcontracts'); 
          if(res.data.success){
            setData(res.data.data);
          }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  

	const handleDownload = (base64String) => {
		// base64 문자열을 Uint8Array로 변환
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
	  
		// Blob 객체 생성
		const blob = new Blob([byteArray], { type: 'application/octet-stream' });
	  
		// Blob 객체를 URL로 변환
		const url = window.URL.createObjectURL(blob);
	  
		// 'a' 태그를 생성하고 설정하여 파일을 다운로드
		const a = document.createElement('a');
		a.href = url;
		a.download = 'download.zip';  
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	  
		// URL 해제
		window.URL.revokeObjectURL(url);
	  }
	
  return(
	<>
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
			<div className="card-header border-0 pt-5">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold fs-3 mb-1">계약 현황</span>
					<span className="text-muted mt-1 fw-semibold fs-7">{data.length}개의 계약</span>
				</h3>
				<div className="card-toolbar">
				</div>
			</div>
			<div className="card-body py-3">
				<div className="table-responsive">
					<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
						<thead>
							<tr className="fw-bold text-muted">
								<th className="min-w-100px">프로젝트명</th>
								<th className="min-w-250px">계약명</th>
								<th className="min-w-80px">계약 상태</th>
								<th className="min-w-150px">계약 시작일/종료일</th>
								<th className="min-w-150px">계약금 / 기성지급액
								<span className="ms-1" title="계약금액은 승인된 변경계약금액을 포함한 금액입니다." >
									<i className="ki-duotone ki-information fs-6">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
									</i>
								</span>
								</th>
								<th className="min-w-50px">부가세율(%)</th>
								<th className="min-w-50px">선급금율(%)</th>

							</tr>
						</thead>
						<tbody>
							{data.length>0 && data.map((v, index)=>(
							<tr key={index}>
								<td className="text-dark fw-bold text-hover-primary fs-6">{v["프로젝트"]}</td>
								<td className="text-dark fw-bold text-hover-primary fs-6">{v["계약명"]}</td>
								<td>
									<span className=
									{v["계약 상태"] ==="계약 완료"? 'badge badge-light-success':
									v["계약 상태"] ==="계약 서류 첨부 완료"?'badge badge-light-warning': 
									v["계약 상태"] ==="전자 서명 중"?'badge badge-light-danger':'badge badge-light-info'}>{v["계약 상태"]}</span>
								</td>
								<td>
								<span className="text-muted fw-semibold text-muted d-block fs-7">{v["계약 시작일"]}</span>
								<span className="text-muted fw-semibold text-muted d-block fs-7">{v["계약 종료일"]}</span>
								</td>
								<td>
								<span className="text-dark text-hover-primary fs-7 d-block">{(v["최종계약금액"]).toLocaleString()}원</span>
								<span className="text-muted text-hover-primary fs-7 d-block">{v["기성 지급 누계"].toLocaleString()}원</span>
								</td>
								<td className="text-muted fw-semibold fs-7">{v["부가세율 (%)"]}</td>
								<td className="text-muted fw-semibold fs-7">{v["선급금 (%)"]}</td>

								<td className="text-end">	
								<a className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'onClick={() => handleShowModal(v)}>
								<i className="ki-solid ki-dots-horizontal fs-1"></i>
								</a>												
								</td>
							</tr>
							))}
					
						</tbody>
					</table>
				</div>
			</div>
		</div>
    </div>
    </div>
	{visibleDialog && (
        <Dialog title={currentContract['프로젝트'] +" "+currentContract['계약명']}  width={500} onClose={handleCloseModal}> 
  			<TabStrip selected={selected} onSelect={handleSelect}>
			  <TabStripTab title="계약 정보">
			  	<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">계약 상세: {currentContract['계약 상세']}</span><br/>
					</div>
				</div>

				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">계약금 (공급원가): {currentContract['계약금 (공급원가)'].toLocaleString()}원</span><br/>
					</div>
				</div>
				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">계약금 (부가세): {currentContract['계약금 (부가세)'].toLocaleString()}원</span><br/>
					</div>
				</div>				
				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">계약금 (총액): {currentContract['계약금 (총액)'].toLocaleString()}원</span><br/>
					</div>
				</div>

				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">선급금 (공급원가): {currentContract['선급금 (공급원가)'].toLocaleString()}원</span><br/>
					</div>
				</div>
				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">선급금 (부가세): {currentContract['선급금 (부가세)'].toLocaleString()}원</span><br/>
					</div>
				</div>
				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">선급금 (총액): {currentContract['선급금 (총액)'].toLocaleString()}원</span><br/>
					</div>
				</div>
				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">인지세액 (총액): {currentContract['인지세액'].toLocaleString()}원</span><br/>
					</div>
				</div>

				<div className="menu-item">
					<div className='menu-link'>
						<span className="menu-bullet">
							<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
						</span>
						<span className="menu-title">수급사업자 인지세 비율: {currentContract['수급사업자 인지세 비율']}%</span><br/>
					</div>
				</div>



			  </TabStripTab>
			  <TabStripTab title="보증 관련 정보">
			<div className="menu-item">
				<div className='menu-link'>
			  <span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">선급금이행보증증권 제출 여부: {currentContract['선급금이행보증증권 제출 여부']}, 요율: {currentContract['선급금이행보증 요율']}%</span><br/>
				</div>
			</div>

			<div className="menu-item">
				<div className='menu-link'>
				<span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">계약이행보증보험 제출 여부: {currentContract['계약이행보증보험증권 제출 여부']}, 요율: {currentContract['계약이행보증보험 요율']}%</span><br/>
				</div>
			</div>

			<div className="menu-item">
				<div className='menu-link'>
				<span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">하자보수보증증권 제출 여부: {currentContract['하자보수보증증권 제출 여부']}, 요율: {currentContract['하자보수 요율']}%</span><br/>
				</div>
			</div>

			<div className="menu-item">
				<div className='menu-link'>
			 	<span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">건설기계대여대금지급보증 제출 여부: {currentContract['건설기계대여대금지급보증 제출 여부']}</span><br/>
				</div>
			</div>

			<div className="menu-item">
				<div className='menu-link'>
				<span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">근재보험증권 제출 여부: {currentContract['근재보험증권 제출 여부']}</span>
				</div>
			</div>


			<div className="menu-item">
				<div className='menu-link'>
				<span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">하도급대금 지급보증금 및 산재보험 다운로드 &nbsp;&nbsp;
				{currentContract['보증파일'] !== undefined &&
				
				<span className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"  onClick={() => handleDownload(currentContract['보증파일'])}>
            <i className="ki-duotone ki-file-down  fs-2x">
            <span className="path1"></span>
            <span className="path2"></span>
            </i>
            </span>}
				</span>
				</div>
			</div>

													
			  </TabStripTab>
			  <TabStripTab title="기성지급 관련 정보">
			  <div className="menu-item">
				<div className='menu-link'>
			  <span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">기성지급기한: {currentContract['기성지급기한']}</span><br/>
				</div>
			</div>
			<div className="menu-item">
				<div className='menu-link'>
			  <span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">월 지급횟수: 월 {currentContract['월 지급횟수']}회</span><br/>
				</div>
			</div>

			<div className="menu-item">
				<div className='menu-link'>
			  <span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">현금 비율: {currentContract['현금 비율']}%</span><br/>
				</div>
			</div>

			<div className="menu-item">
				<div className='menu-link'>
			  <span className="menu-bullet">
					<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
				</span>
				<span className="menu-title">기업구매카드 비율: {currentContract['기업구매카드 비율']}%</span><br/>
				</div>
			</div>

			  </TabStripTab>
			</TabStrip>
        </Dialog>
		)}
</>
)};

export default ContractPage;