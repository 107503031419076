import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import { UserDetails } from './multistep/userinfo';
import { CompanyDetails } from './multistep/companyinfo'
import { CompanyLeaderDetails } from './multistep/companyleaderinfo';
import { useRecoilState } from 'recoil';
import { formStateAtom } from "../atom/formState";
import axios from '../common/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const Signup = () => {
  const stepPages = [<CompanyDetails />, CompanyLeaderDetails, <UserDetails />];
  const [UnifierValues, setUnifierValues] = useState({});

  let navigate = useNavigate();

  const [step, setStep] = React.useState(0);
  const [formState, setFormState] = useRecoilState(formStateAtom);

  const [steps, setSteps] = React.useState([
    {
      label: '회사 정보',
      isValid: undefined
    }, {
      label: '대표자 정보',
      isValid: undefined
    }, {
      label: '로그인 정보',
      isValid: undefined
    }]);
  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;

  const onStepSubmit = React.useCallback(event => {
    const { isValid, values } = event;
    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));

    setSteps(currentSteps);
    if (!isValid) {
      return;
    }

    setStep(() => Math.min(step + 1, lastStepIndex));
    setFormState(prevFormState => ({
      ...prevFormState,
      ...values
    }));

    if (isLastStep) {
      setUnifierValues(values)
      Swal.fire({
        title: '입력한 정보로 계정 신청 요청을 하시겠습니까?',
        text: "계정 신청 결과는 삼성중공업에서 검토 후 가입 여부를 로그인 정보에 넣은 회사 이메일로 알려드립니다.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(values)
          sendInfo(values); // 여기에 실제 함수 호출을 넣으세요.
        }
      });
    }

  }, [steps, isLastStep, step, lastStepIndex]);

  const sendInfo = (values) => {
    axios.post(`/vsacrete`, values)
      .then(response => {
        const { success, message } = response.data;

        if (success) {
          Swal.fire({
            title: 'Success',
            text: message,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            navigate('/home');
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: message || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch(err => {
        let errorMessage = '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
        if (err.response && err.response.data && err.response.data.error) {
          errorMessage += ' ' + err.response.data.error;
        }
        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
  };

  const onPrevClick = React.useCallback(event => {
    event.preventDefault();
    setStep(() => Math.max(step - 1, 0));
  }, [step, setStep]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <div style={{ width: "100%", height: "100vh", background: "#f2f2f2" }}><div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: isMobile ? window.innerWidth : 600,
    position: "absolute",
    top: isMobile ? "0%" : "50%",
    left: "50%",
    transform: isMobile ? "translate(-50%, 0%)" : "translate(-50%, -50%)",
    borderRadius: 10,
    boxShadow: "0 5px 10px 0px rgba(0, 0, 0, 0.1)",
    padding: "33px 33px 33px 33px",
    background: "#fff"

  }}>
    <Stepper value={step} items={steps} />
    <Form initialValues={formState} onSubmitClick={onStepSubmit} render={formRenderProps => <div style={{
      alignSelf: 'center'
    }}>
      <FormElement style={{
        width: isMobile ? window.innerWidth - 60 : 480
      }}>

        {stepPages[step]}
        <span style={{
          marginTop: '40px'
        }} className={'k-form-separator'} />
        <div style={{
          justifyContent: 'space-between',
          alignContent: 'center'
        }} className={'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>
          <span style={{
            alignSelf: 'center'
          }}>Step {step + 1} of 3</span>
          <div>
            {step !== 0 ? <Button style={{
              marginRight: '16px'
            }} onClick={onPrevClick}>
              이전
            </Button> : undefined}
            <Button themeColor={'primary'} disabled={!(formRenderProps.allowSubmit)} onClick={formRenderProps.onSubmit}>
              {isLastStep ? '제출' : '다음'}
            </Button>
          </div>
        </div>
      </FormElement>
    </div>} />
  </div>
  </div>;
};