import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import Swal from 'sweetalert2';
// import { FormCheckbox,FormCheckbox2, FormDropDownList, FormInput ,FormUpload, FormMultiSelect} from './newEvaluation/steps/forms';
import { CompanyNameValidator, TaxNumberValidator, fileValidator, infoValidator, requiredValidator } from '../login/multistep/validators';
import { Circles } from 'react-loader-spinner';
import dayjs from 'dayjs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, FormElement, Field, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload';
import { Button } from '@progress/kendo-react-buttons';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
} from "@progress/kendo-react-intl";
import krMessage2 from '../login/multistep/kr.json';


const uploadRef = React.createRef();


export const UpdateFile = ({ }) => {

	const [files, setFiles] = useState([]);


	const FormUpload = fieldRenderProps => {
		const {
			value,
			id,
			optional,
			label,
			hint,
			validationMessage,
			touched,
			...others
		} = fieldRenderProps;
		const showValidationMessage = touched && validationMessage;
		const showHint = !showValidationMessage && hint;
		const hintId = showHint ? `${id}_hint` : '';
		const errorId = showValidationMessage ? `${id}_error` : '';
		const labelId = label ? `${id}_label` : '';
		const onChangeHandler = event => {
			setFiles(event.newState)
			fieldRenderProps.onChange({
				value: event.newState
			});
		};
		const onRemoveHandler = event => {
			setFiles(event.newState)
			fieldRenderProps.onChange({
				value: event.newState,
			});
		};

		const display = fieldRenderProps.value && fieldRenderProps.value.length > 0 ? "none" : 'block';

		return <FieldWrapper>
			<Label id={labelId} editorId={id} optional={optional} className='k-form-label'>
				{label}
			</Label>
			<LocalizationProvider language="ko-KR">
				<IntlProvider locale="kr">
					<div className={'k-form-field-wrap'}>
						<Upload ref={uploadRef}
							// restrictions={{
							//     allowedExtensions: [".pdf"],
							//   }} 
							id={id} autoUpload={false} showActionButtons={false} multiple={true} files={value} onAdd={onChangeHandler} onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />
						{showHint && <Hint id={hintId}>{hint}</Hint>}
						{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
					</div>
					<div
						style={{
							height: "10px",
						}}></div>
					<div style={{ display: display }}>
						<ExternalDropZone uploadRef={uploadRef} />
					</div>
				</IntlProvider>
			</LocalizationProvider>
		</FieldWrapper>;
	};


	const [data, setData] = useState({});
	const [record, setRecord] = useState("");
	const [visibleDialog, setVisibleDialog] = useState(false);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get('/getvendorfilelist');
				if (res.data.success) {
					setData(res.data.data);
					setRecord(res.data.record)
					setLoading(false);
				}

			} catch (error) {
				console.error('데이터 가져오기 중 오류 발생:', error);
				setLoading(false);
			}
		};
		fetchData();
	}, []);



	const handleButtonClick = () => {
		setVisibleDialog(true);

	};

	const handleCloseModal = () => {
		setVisibleDialog(false);
	};



	const onStepSubmit = React.useCallback(event => {

		const affectedFiles = event.values["파일"]
		const updatedState = [];

		affectedFiles.forEach((file) => {
			const cc = file.getRawFile();
			const filetype = file.extension;

			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};
				updatedState.push(updatedFile);

				if (updatedState.length === affectedFiles.length) {
					handleCloseModal();
					Swal.fire({
						title: '확인 요청?',
						text: "입력한 정보를 정말 제출하시겠습니까?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: '예',
						cancelButtonText: '아니오'
					}).then((result) => {
						if (result.isConfirmed) {
							sendInfo(updatedState);
						}
					});
				}
			};
			reader.readAsDataURL(cc);

		});

	})


	const sendInfo = (e) => {
		// console.log(e)
		axios.post("/updatewithattach",
			{
				bpname: "Vendors",
				project: "",
				record: record,
				files: e
			})
			.then(response => {
				if (response.data.success) {
					Swal.fire({
						title: 'Success',
						text: '성공적으로 제출되었습니다.',
						icon: 'success',
						confirmButtonText: 'OK'
					})
						.then(() => {
							window.location.reload();
						});
				} else {
					let errorMessage = response.data.error || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
					Swal.fire({
						title: 'Error',
						text: errorMessage,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}
			})
			.catch(err => {
				let errorMessage = '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
				if (err.response && err.response.data && err.response.data.error) {
					errorMessage += ' ' + err.response.data.error;
				}
				Swal.fire({
					title: 'Error',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	}


	if (loading) {
		return <div className="loadingContainer">
			<Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
		</div>;;
	}
	return (
		<>
			<div className='app-content flex-column-fluid'>
				<div className='app-container container-xxl'>
					<div className="card mb-5 mb-xl-8">
						<div className="card-header border-0 pt-5">
							<h2 className="fw-bold d-flex align-items-center text-dark">첨부파일 리스트
								<span className="ms-1" title="현재 첨부된 파일들입니다.">
									<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
									</i>
								</span></h2>
							<div className="card-toolbar">
								<button className='btn btn-sm fw-bold btn-primary' type='button' onClick={handleButtonClick} >
									<i className="ki-duotone ki-file-up fs-3">
										<span className="path1"></span>
										<span className="path2"></span>
									</i>
									새 파일 업로드
								</button>
							</div>
						</div>
						<div className="card-body py-3">
							<div className="table-responsive">
								<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
									<thead>
										<tr className="fw-bold text-muted">

											<th className="min-w-100px">제목</th>
											<th className="min-w-250px">파일 이름</th>
											<th className="min-w-150px">이슈 날짜</th>
											<th className="min-w-120px">파일크기</th>
										</tr>
									</thead>
									<tbody>
										{data && data.map((v, index) => (
											<tr key={index}>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.title}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.file_name}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{dayjs(v.issue_date).format("YYYY년 MM월 DD일")}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.file_size}</td>
												<td className="text-end">
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			{visibleDialog &&
				<Dialog title={"파일 업로드"} width={600} onClose={handleCloseModal}>
					<Form className="my-auto pb-5" onSubmitClick={onStepSubmit} render={formRenderProps => <div>
						{/* 스텝 바디 */}
						<FormElement>
							<Field
								key={"파일"} className="attach"
								id={"파일"}
								name={"파일"}
								label={'파일 첨부'}
								hint={`파일이 여러개라면 한번에 첨부하셔도 됩니다.`}
								component={FormUpload}
							/>
							<div className="d-flex flex-stack pt-15">
								<div>
									<button type="button" className="btn btn-lg btn-primary" disabled={!files.length > 0} onClick={formRenderProps.onSubmit}> 업로드
										<i className="ki-duotone ki-send fs-4 ms-1">
											<span className="path1"></span>
											<span className="path2"></span>
										</i></button>
								</div>
							</div>
						</FormElement>
					</div>} />

				</Dialog>
			}
		</>
	);
};

