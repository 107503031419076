import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import _ from "lodash";
import { Dialog } from '@progress/kendo-react-dialogs';
import Swal from 'sweetalert2';
import PaymentTabe from './paymentTable';
import { useSetRecoilState } from 'recoil';
import { sovDataState } from '../atom/sovDataState';
import { useNavigate } from 'react-router-dom';


const PaymentPage = () => {
	const [data, setData] = useState([]);
	const [ContractData, setContractData] = useState([]);
	const [showCard, setShowCard] = useState([]);
	const navigate = useNavigate();

	const totalLength = data.reduce((sum, group) => {
		if (Array.isArray(group.lineitems)) {
			return sum + group.lineitems.length;
		}
		return sum;
	}, 0);


	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get('/getcontractsimple');
				if (res.data.success) {
					setContractData(res.data.data);
					setShowCard(new Array(res.data.data.length).fill(true));
				}

			} catch (error) {
				console.error('데이터 가져오기 중 오류 발생:', error);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get('/getpayment');
				if (res.data.success) {
					const data = ContractData.map((v) => ({ ...v, ...res.data.data.find(vf => vf.uniq === v.uniq) }))
					setData(_.sortBy(_.sortBy(_.sortBy(
						data.map((v) => ({
							...v,
							lineitems: v.lineitems && v.lineitems
								.map((vf) => ({ ...vf, 상태: vf.상태 === "Pending" || vf.상태 === "LoA_Requested" ? "승인 대기중" : vf.상태 === "Approved" ? "승인" : vf.상태 === "Revision_Required" ? "수정 필요" : vf.상태 === "Canceled" ? "취소됨" : "반려" }))
						})), "프로젝트"), "계약명"), "청구번호"));
				}

			} catch (error) {
				console.error('데이터 가져오기 중 오류 발생:', error);
			}
		};
		fetchData();
	}, [ContractData]);

	const toggleCard = (index) => {
		const newShowCard = [...showCard];
		newShowCard[index] = !newShowCard[index];
		setShowCard(newShowCard);
	}


	const handleButtonClick = async (project, record, title, number) => {
		const encodedProject = encodeURIComponent(project);
		const encodedRecord = encodeURIComponent(record);
		const encodedTitle = encodeURIComponent(title);
		const encodedNumber = encodeURIComponent(number);

		navigate(`/createNewPA/${encodedProject}/${encodedRecord}/${encodedTitle}/${encodedNumber}`);
	};

	const downloadFile = async (recordNo, projectNumber) => {
		try {
			const response = await axios.get(`/getpaymentfiles?recordNo=${recordNo}&projectNumber=${projectNumber}`, {
				responseType: 'blob', // 중요: 파일 데이터를 그대로 다운로드 받기 위해 blob 타입으로 응답 받음
			});

			// 파일 다운로드 처리
			const contentDisposition = response.headers['content-disposition'];
			let filename = "기성첨부서류.zip";
			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
				if (fileNameMatch.length === 2) {
					filename = fileNameMatch[1];
				}
			}

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename); // 다운로드 파일명 지정
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			console.error('파일 다운로드 중 오류 발생:', error);
			// 오류 처리
		}
	};

	return (
		<div className='app-content flex-column-fluid'>
			<div className='app-container container-xxl'>

				<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6" style={{ marginBottom: 20 }}>
					<i className="ki-duotone ki-information fs-2tx text-warning me-4">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
					</i>
					<div className="d-flex flex-stack flex-grow-1">
						<div className="fw-semibold">
							<h4 className="text-gray-900 fw-bold">전체 기성 현황 요약</h4>
							<div className="fs-6 text-gray-700">{data.length}개의 계약에서 {totalLength} 기성 이력이 있습니다.
							</div>
						</div>
					</div>
				</div>
				{data.map((v, index) => (
					<div key={index} className="card mb-5 mb-xl-8">
						<div className="card-header border-0 pt-5">

							<h3 className="card-title align-items-start flex-column cursor-pointer" onClick={() => toggleCard(index)}>

								<span className="card-label fw-bold fs-3 mb-1">
									<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5" onClick={() => toggleCard(index)}>
										{showCard[index] ?
											<i className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
												<span className="path1"></span>
												<span className="path2"></span>
											</i> :
											<i className="ki-duotone ki-plus-square toggle-off fs-1">
												<span className="path1"></span>
												<span className="path2"></span>
												<span className="path3"></span>
											</i>}
									</div>
									{v.uniq} 기성 이력
								</span>
								<span className="text-muted mt-1 fw-semibold fs-7">{v.lineitems === undefined ? 0 : v.lineitems && v.lineitems.length}개의 기성 청구 이력이 있습니다.</span>
							</h3>
							<div className="card-toolbar">
								{v.lineitems && v.lineitems.some(item => item.상태 === "수정 필요") ? (
									<span
										onClick={(e) => {
											e.preventDefault();
											const encodedProject = encodeURIComponent(v.projectCode);
											const encodedRecord = encodeURIComponent(v.record);
											const encodedTitle = encodeURIComponent(v.uniq);
											const encodedNumber = encodeURIComponent(
												v.lineitems && v.lineitems.filter(v => v.상태 === "승인").length > 0
													? v.lineitems.filter(v => v.상태 === "승인").length + 1
													: 1
											);
											navigate(`/modifiyPA/${encodedProject}/${encodedRecord}/${encodedTitle}/${encodedNumber}`);
										}}
										className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
									>
										<i className="ki-solid ki-pencil text-primary fs-3"></i>
									</span>
								) : ((v.lineitems && !v.lineitems.some(item => item.상태 === "보류")) || (v.lineitems === undefined || v.lineitems.length === 0) )&&(
									<span
										onClick={(e) => {
											e.preventDefault();
											handleButtonClick(
												v.projectCode,
												v.record,
												v.uniq,
												v.lineitems && v.lineitems.filter(v => v.상태 === "승인").length > 0
													? v.lineitems.filter(v => v.상태 === "승인").length + 1
													: 1
											);
										}}
										className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
									>
										<i className="ki-solid ki-plus text-danger fs-3"></i>
									</span>
								)}
							</div>
						</div>
						<div className="card-body py-3">
							{showCard[index] &&
								<div className="table-responsive">
									<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
										<thead>
											<tr className="fw-bold text-muted">

												<th className="min-w-100px">청구번호</th>
												<th className="min-w-120px">청구일</th>
												<th className="min-w-200px">기성 신청 금액</th>
												<th className="min-w-100px">기성진행 상태</th>
												<th className="min-w-100px"> 기성 누적/계약 총액</th>
												<th className="min-w-100px">기성 비율 (금회/누적)</th>
												<th className="min-w-100px">제출 기성서류 다운로드</th>
											</tr>
										</thead>
										<tbody>
											{v.lineitems && v.lineitems.map((vf, index) => (
												<tr key={index}>
													<td className="text-dark fw-bold text-hover-primary fs-6">{vf.청구번호}</td>
													<td className="text-dark fw-bold text-hover-primary fs-6">{vf.청구일}</td>
													<td className="text-dark fw-bold text-hover-primary fs-6">{vf.기성신청금액 && vf.기성신청금액.toLocaleString()} 원</td>
													<td className="text-dark fw-bold text-hover-primary fs-6">{vf.상태}</td>
													<td>
														<span className="text-muted text-hover-primary fs-7 d-block">{(_.sumBy(v.lineitems.slice(0, index + 1).filter(f => f.상태 === "승인"), "기성신청금액")).toLocaleString()} 원</span>
														<span className="text-muted text-hover-primary fs-7 d-block">{v.계약금액 && v.계약금액.toLocaleString()} 원</span>
													</td>
													<td>
														<span className="text-muted text-hover-primary fs-7 d-block">{((vf.기성신청금액 / v.계약금액) * 100).toFixed(2)} %</span>
														<span className="text-muted text-hover-primary fs-7 d-block">{(((_.sumBy(v.lineitems.slice(0, index + 1).filter(f => f.상태 === "승인"), "기성신청금액")) / v.계약금액) * 100).toFixed(1)} %</span>
													</td>
													<td>
														<span onClick={() => downloadFile(vf.record_no, vf.project_projectnumber)} className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
															<i className="ki-duotone ki-folder-down">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>}
						</div>
					</div>
				))}
			</div>
		</div>

	)
};

export default PaymentPage;
