import { atomFamily ,atom} from 'recoil';

export const selectedValueAtomFamily = atomFamily({
  key: 'selectedValue',
  default: null,
});


export const selectedValue1 = atom({
  key: 'selectedValue1',
  default: null,
});


export const selectedValue2 = atom({
  key: 'selectedValue2',
  default: null,
});
