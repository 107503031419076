import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import Swal from 'sweetalert2';
import dayjs from "dayjs";

const AgreemntPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
      const fetchData = async () => {


        try {
          const res = await axios.get('/getagreement'); 
          if(res.data.success){
            setData(res.data.data);
          }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  
    const handleButtonAPIClick = (v) => {
		Swal.fire({
			title: '작업합의서 수정 요청',
			text: "이 요청을 보내면 삼성중공업 담당자분에게 수정 요청이 접수됩니다.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '네, 보내기',
			cancelButtonText: '아니요, 취소'
		}).then((result) => {
			if (result.isConfirmed) {
				axios.get(`/udpatecontract3?record=${v.record}&project=${v.projectCode}`)
				.then(response => {
					if(response.data.success){
					Swal.fire({
						title: '성공',
						text: "요청이 성공적으로 접수되었습니다. 다시 검토 요청을 받기 전까지는 해당 계약 검토는 보이지 않습니다.",
						icon: 'success',
						confirmButtonText: '확인'
					}).then((result) => {
						if (result.isConfirmed) {
							// Navigate to /confirmContract
							window.location.href = "/log-agreement";
						}
					});}else {
						Swal.fire({
							title: '오류',
							text: "요청 처리 중 오류가 발생했습니다.",
							icon: 'error',
							confirmButtonText: '확인'
						});
					}
				})
				.catch(error => {
					// Handle any errors from the axios request
					Swal.fire({
						title: '오류',
						text: "요청 처리 중 오류가 발생했습니다.",
						icon: 'error',
						confirmButtonText: '확인'
					});
				});
			}
		});
	};
	
	
	
	  const handleButtonAPIClick2 = (v) => {
		Swal.fire({
		  title: '계약 진행 요청',
		  text: "이 요청을 보내면 계약서 사인 요청이 시작됩니다.",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: '네, 보내기',
		  cancelButtonText: '아니요, 취소'
		}).then((result) => {
		  if (result.isConfirmed) {
			axios.get(`/udpatecontract4?record=${v.record}&project=${v.projectCode}`)
			.then(response => {
				if(response.data.success){
				Swal.fire({
					title: '성공',
					text: "요청이 성공적으로 접수되었습니다. 해당 계약은 전자서명으로 넘어갔습니다.",
					icon: 'success',
					confirmButtonText: '확인'
				}).then((result) => {
					if (result.isConfirmed) {
						// Navigate to /confirmContract
						window.location.href = "/log-agreement";
					}
				});}else {
					Swal.fire({
						title: '오류',
						text: "요청 처리 중 오류가 발생했습니다.",
						icon: 'error',
						confirmButtonText: '확인'
					});
				}
			})
			.catch(error => {
				// Handle any errors from the axios request
				Swal.fire({
					title: '오류',
					text: "요청 처리 중 오류가 발생했습니다.",
					icon: 'error',
					confirmButtonText: '확인'
				});
			});
		  }
		});
	  };

  
  return(
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">작업합의서</span>
                    <span className="text-muted mt-1 fw-semibold fs-7">{data && data.filter(v=>v.status ==="Agreement_Transfer").length}개의 작업합의서에 응답을 해야합니다.</span>
                </h3>
                <div className="card-toolbar">
                </div>
            </div>
            <div className="card-body py-3">
                <div className="table-responsive">
                    <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                        <thead>
                            <tr className="fw-bold text-muted">

                                <th className="min-w-100px">프로젝트 이름</th>
                                <th className="min-w-150px">계약명</th>
                                <th className="min-w-80px">상태</th>
                                <th className="min-w-100px"></th>
                                <th className="min-w-140px">하수급금액(예상, VAT 제외)</th>
                                <th className="min-w-120px">작업명</th>
                                <th className="min-w-140px">선작업 사유</th>
                                <th className="min-w-100px">작업시작일</th>
                                <th className="min-w-100px">견적제출 마감일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length>0 &&  data.map((v, index)=>(
                            <tr key={index}>
                                <td className="text-dark fw-bold text-hover-primary fs-7">{v.projectName}</td>
                                <td className="text-dark fw-bold text-hover-primary fs-7">{v.계약명}</td>
                                <td>
								<span className={v.status ==="Agreement_Transfer"?"badge badge-light-danger":"badge badge-light-success"} >	{v.status === "Agreement_Transfer"? "응답필요":"불필요"}</span>
								</td>
								<td>
								{v.status === "Agreement_Transfer" && 
                                <>
                                <button className="btn btn-sm btn-light" style={{padding:5}} onClick={()=>handleButtonAPIClick(v)}>수정 요청</button> <br/>
								<button className="btn btn-sm btn-light" style={{padding:5}} onClick={()=>handleButtonAPIClick2(v)}>서명 진행</button> 
                                </>
                                }
								</td>
                                <td className="text-dark fw-bold text-hover-primary fs-7">{v["하수급금액(예상, VAT 제외)"] &&v["하수급금액(예상, VAT 제외)"].toLocaleString()}원</td>
                                <td className="text-dark fw-bold text-hover-primary fs-7">{v["작업명"]}</td>
                                <td className="text-dark fw-bold text-hover-primary fs-7">{v["선작업 사유"]}</td>
                                <td className="text-dark fw-bold text-hover-primary fs-8">{dayjs(v["작업시작일"]).format("YY년 MM월 DD일")}</td>
                                <td className="text-dark fw-bold text-hover-primary fs-8">{dayjs(v["견적제출 마감일"]).format("YY년 MM월 DD일")}</td>
                                
                                <td className="text-end">
                          
                                </td>
                            </tr>
                    ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </div>
)};

export default AgreemntPage;