import React, { useState } from 'react';
import { formStateAtom2 } from '../../../atom/formState2';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { FormCheckbox2 } from './forms';
import { Field } from '@progress/kendo-react-form';
import { ContactValidator } from '../../../login/multistep/validators';

export const ContactInfo = ({ }) => {

  const setFormState = useSetRecoilState(formStateAtom2);
  const formState = useRecoilValue(formStateAtom2);

  const tableColumns = [
    {
      label: '영업',
      inputs: [
        { name: 'name', placeholder: '', maxWidth: 100 },
        { name: 'phone', placeholder: '', maxWidth: 120 },
        { name: 'email', placeholder: '', maxWidth: 200 },
      ],
    },
    {
      label: '납기',
      inputs: [
        { name: 'name', placeholder: '', maxWidth: 100 },
        { name: 'phone', placeholder: '', maxWidth: 120 },
        { name: 'email', placeholder: '', maxWidth: 200 },
      ],
    },
    {
      label: '품질',
      inputs: [
        { name: 'name', placeholder: '', maxWidth: 100 },
        { name: 'phone', placeholder: '', maxWidth: 120 },
        { name: 'email', placeholder: '', maxWidth: 200 },
      ],
    },
    {
      label: '세금계산서',
      inputs: [
        { name: 'name', placeholder: '', maxWidth: 100 },
        { name: 'phone', placeholder: '', maxWidth: 120 },
        { name: 'email', placeholder: '', maxWidth: 200 },
      ],
    },
    {
      label: '입찰',
      inputs: [
        { name: 'name', placeholder: '', maxWidth: 100 },
        { name: 'phone', placeholder: '', maxWidth: 120 },
        { name: 'email', placeholder: '', maxWidth: 200 },
      ],
    },
  ];

  // 상태로 사용할 tableData를 초기화합니다.
  const [tableData, setTableData] = useState(formState.contact ? formState.contact : {});

  const tabalarray = tableData && Object.entries(tableData).map(([type, data]) => ({
    type,
    ...data
  }));
  // input 값이 변경될 때 호출되는 핸들러 함수
  const handleInputChange = (e, columnName, inputName) => {
    const { value } = e.target;

    setTableData((prevTableData) => {
      // 현재 columnName의 데이터를 복제합니다.
      const updatedColumnData = { ...prevTableData[columnName], [inputName]: value };

      // 업데이트된 columnName 데이터에서 모든 값이 ""인지 확인합니다.
      const allEmpty = Object.values(updatedColumnData).every(val => val === "");

      // 모든 값이 ""이면 해당 columnName 키를 삭제합니다.
      if (allEmpty) {
        const { [columnName]: _, ...newTableData } = prevTableData;
        return newTableData;
      } else {
        // 그렇지 않으면 업데이트된 데이터로 columnName을 설정합니다.
        return {
          ...prevTableData,
          [columnName]: updatedColumnData,
        };
      }
    });
  };


  const [isInputVisible, setIsInputVisible] = useState(true);

  const toggleInputVisibility = () => {
    setIsInputVisible((prevIsInputVisible) => !prevIsInputVisible);
    setFormState(prevFormState => ({
      ...prevFormState,
      contact: tableData
    }));
  };

  //    const onChange = (event)=>{setselectedValue(event.target.value)}

  const [selectedValue, setselectedValue] = React.useState(false)
  const [status, setStatus] = React.useState(false)

  React.useEffect(() => {
    if (tabalarray.length > 0) {
      setselectedValue(tabalarray && tabalarray.find(v => v.type === "입찰")?.email ? true : false);
      setStatus(isInputVisible)
    }
  }, [tabalarray, isInputVisible]);



  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="pb-10 pb-lg-15">
          <h2 className="fw-bold d-flex align-items-center text-dark">업무 담당자 정보
            <span className="ms-1" data-bs-toggle="tooltip" title="입찰을 참가하기 위해서는 입찰자 정보는 꼭 넣어야합니다.">
              <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </span></h2>
        </div>
        <div className="card mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h4 className='card-title align-items-start flex-column'>
              <span className="text-muted mt-1 fw-semibold fs-7">담당자가 나누어있지 않다면, 같은 정보를 넣으셔도 됩니다. <br /><span className='badge badge-light-danger fw-semibold me-1'>입찰 담당자의 이메일로 입찰 초청이 되니 유의바랍니다.</span></span>
            </h4>
            <button className='btn btn-sm btn-flex btn-secondary fw-bold' type='button' onClick={toggleInputVisibility} style={{ height: 40 }}>
              {!isInputVisible ? <>
                <i className="ki-duotone ki-user-edit">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i> 수정</>
                : <>  <i className="ki-duotone ki-user">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>저장</>}

            </button>
          </div>
          <div className="card-body py-3">
            <div className="table-responsive design">
              <table className="table align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted bg-light">
                    <th className="min-w-50px rounded-start">항목</th>
                    <th className="min-w-80px">성명</th>
                    <th className="min-w-100px">휴대폰 번호</th>
                    <th className="min-w-150px rounded-end">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {tableColumns.map((column, columnIndex) => (
                    <tr key={columnIndex}>
                      <td className="text-dark fw-bold text-hover-primary fs-6">{column.label}</td>
                      {isInputVisible ? (
                        column.inputs.map((input, inputIndex) => (
                          <td key={inputIndex} className="text-dark fw-bold text-hover-primary fs-6">
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder={input.placeholder}
                              name={input.name}
                              style={{ maxWidth: input.maxWidth }}
                              value={tableData[column.label]?.[input.name] || ''}
                              onChange={(e) => handleInputChange(e, column.label, input.name)}
                            />
                          </td>
                        ))
                      ) : (
                        <>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {tableData[column.label]?.name || ''}
                          </td>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {tableData[column.label]?.phone || ''}
                          </td>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {tableData[column.label]?.email || ''}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Field
              key={'담당자정보'}
              id={'담당자정보'}
              name={"담당자정보"}
              component={FormCheckbox2}
              value={selectedValue}
              validator={() => ContactValidator(tableData, selectedValue, status)}
            />
          </div>
        </div>
      </div>

    </div>
  );
};
