import React, { useEffect, useState } from 'react';
import { Form, Field } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { guid } from '@progress/kendo-react-common';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import dayjs from 'dayjs';
import { FormDatePicker } from '../../../../login/multistep/form-components';
import { requiredValidator } from '../../../../login/multistep/validators';
import { formStateAtom2 } from '../../../../atom/formState2';
import { useSetRecoilState } from 'recoil';

export const FormTable = ({ tabeldata, formprop, column_list, newkey, tabletitle }) => {

  /////////////////////////////////////////////////


  const DATA_ITEM_KEY = "datakey";
  const FORM_DATA_INDEX = 'formDataIndex';
  // const requiredValidator = value => value ? '' : 'The name is required';
  const TextInputWithValidation = fieldRenderProps => {
    const {
      validationMessage,
      visited,
      ...others
    } = fieldRenderProps;
    return <div>
      <Input {...others} />
      {visited && validationMessage && <div role="alert" className="k-form-error k-text-start">
        {validationMessage}
      </div>}
    </div>;
  };
  const FormSubmitContext = React.createContext(() => undefined);
  const GridEditContext = React.createContext({});
  const GridInlineFormRow = props => {
    const {
      onRowAction,
      editIndex
    } = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const onSubmit = React.useCallback(e => {
      onRowAction({
        rowIndex: editIndex,
        operation: 'save',
        dataItem: e
      });
    }, [onRowAction, editIndex]);
    if (isInEdit) {
      return <Form key={JSON.stringify(props.dataItem)} initialValues={props.dataItem} onSubmit={onSubmit} render={formRenderProps => {
        return <FormSubmitContext.Provider value={formRenderProps.onSubmit}>
          {props.children}
        </FormSubmitContext.Provider>;
      }} />;
    }
    return props.children;
  };
  const NameCell = props => {
    const {
      editIndex
    } = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return <td>
      {isInEdit ? <Field component={TextInputWithValidation} name={`${props.field}`} validator={requiredValidator} /> : props.dataItem[props.field || '']}
    </td>;
  };

  const DateCell = (props) => {
    const { editIndex } = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return (
      <td>
        {isInEdit ? (
          <Field
            component={FormDatePicker}
            name={`${props.field}`}
            validator={requiredValidator}
          />
        ) : (
          props.dataItem[props.field] === "Invalid date" ? '' :
            dayjs(props.dataItem[props.field || ""]).format('YYYY년 MM월 DD일')

        )}
      </td>
    );
  };


  const CommandCell = props => {
    const onSubmit = React.useContext(FormSubmitContext);
    const {
      onRowAction,
      setEditIndex,
      editIndex
    } = React.useContext(GridEditContext);

    const rowIndex = props.dataItem[FORM_DATA_INDEX];
    const isInEdit = rowIndex === editIndex;
    const isNewItem = props.dataItem[newkey] === undefined;
    const onSaveClick = React.useCallback(e => {
      e.preventDefault();
      onSubmit(e);
    }, [onSubmit]);
    const onRemoveClick = React.useCallback(e => {
      e.preventDefault();
      onRowAction({
        rowIndex: props.dataItem[FORM_DATA_INDEX],
        operation: 'remove',
        dataItem: props.dataItem
      });
    }, [onRowAction, props.dataItem]);
    const onEditClick = React.useCallback(e => {
      e.preventDefault();
      setEditIndex(rowIndex);
    }, [rowIndex, setEditIndex]);

    const onDiscardClick = React.useCallback(e => {
      e.preventDefault();
      onRowAction({
        rowIndex: props.dataItem[FORM_DATA_INDEX],
        operation: 'discard',
        dataItem: props.dataItem
      });
    }, [onRowAction, props.dataItem]);
    const onCancelClick = React.useCallback(e => {
      e.preventDefault();
      onRowAction({
        rowIndex: props.dataItem[FORM_DATA_INDEX],
        operation: 'cancel',
        dataItem: props.dataItem
      });
    }, [onRowAction, props.dataItem]);

    return isInEdit ? <td className="k-command-cell">

      <button type="button" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={onSaveClick}>
        <i className="ki-duotone ki-double-check">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </button>
      <button type="button" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" onClick={isNewItem ? onDiscardClick : onCancelClick}>
        <i className="ki-outline ki-cross"></i>
      </button>
    </td> : <td className="k-command-cell">

      <button type="button" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={onEditClick}>
        <i className="ki-duotone ki-pencil fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </button>
      {isInEdit || <button type="button" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" onClick={onRemoveClick}>
        <i className="ki-duotone ki-trash fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
        </i>
      </button>}
    </td>
  };
  const rowRender = (row, props) => {
    return <GridInlineFormRow dataItem={props.dataItem}>{row}</GridInlineFormRow>;
  };

  /////////////////////////////////////

  const setFormState = useSetRecoilState(formStateAtom2);

  // Form data index is used as an alternative to ID for rows after data operations
  const [dataState, setDataState] = React.useState(tabeldata.map((dataItem, idx) => ({
    ...dataItem,
    [FORM_DATA_INDEX]: idx
  })));
  const [editIndex, setEditIndex] = React.useState(undefined);
  const onRowAction = React.useCallback(options => {
    let newDataState = [...dataState];
    switch (options.operation) {
      case 'remove':
        const itemToRemove = options.dataItem;
        newDataState = newDataState.filter(dataItem => dataItem[DATA_ITEM_KEY] !== itemToRemove[DATA_ITEM_KEY]);
        break;
      case 'save':
        let index = newDataState.findIndex(product => product[DATA_ITEM_KEY] === options.dataItem[DATA_ITEM_KEY]);
        console.log(index)
        newDataState[index] = options.dataItem;
        setEditIndex(undefined);
        break;
      case 'add':
        newDataState.unshift({
          [FORM_DATA_INDEX]: options.rowIndex,
          [DATA_ITEM_KEY]: guid()
        });
        setEditIndex(options.rowIndex);
        break;
      case 'discard':
        newDataState.splice(0, 1);
        setEditIndex(undefined);
        break;
      case 'cancel':
        setEditIndex(undefined);
        break;
      default:
    }
    setFormState(prevFormState => ({
      ...prevFormState,
      [formprop]: newDataState
    }));
    setDataState(newDataState);
  }, [dataState]);
  const onAddClick = React.useCallback(() => {
    onRowAction({
      rowIndex: dataState.length,
      operation: 'add'
    });
  }, [onRowAction, dataState]);

 
  return (
    <div className="card mb-5 mb-xl-8" style={{ marginTop: 12 }}>
      <div className='card-header border-0 pt-5'>
        <h4 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-4 mb-1'>{tabletitle}</span></h4>
        <div
          onClick={editIndex === undefined ? onAddClick : undefined}
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="추가하기 위해서 클릭하세요."
        >
          <a
            href="#"
            className={`btn btn-sm btn-light btn-active-primary ${editIndex !== undefined ? 'disabled' : ''}`}
          >
            <i className="ki-duotone ki-plus fs-2"></i>행추가
          </a>
        </div>

      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <GridEditContext.Provider value={{
            onRowAction,
            editIndex,
            setEditIndex
          }}>
            <Grid style={{ maxHeight: 480, border: 0 }} className='className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"'
              data={dataState} dataItemKey={DATA_ITEM_KEY} rowRender={rowRender}>

              {/* <GridColumn field="ID" title="No." type="numeric" width="50px" editable={false} /> */}
              {column_list.map((column) => (
                <GridColumn
                  key={column.field_name}
                  field={column.field_name}
                  title={column.title}
                  width={column.width}
                  cell={column.type === 'date' ? DateCell : NameCell}
                // editor={column.type === "numeric" ? "numeric" : column.type === "date" ? "date" : null}
                // format={column.type === "date" ? "{0:d}" : null}
                />
              ))}
              <GridColumn cell={CommandCell} width="100px" />
            </Grid>
          </GridEditContext.Provider>
        </div>
      </div>
    </div>
  );
};


