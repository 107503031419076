import React, { useState, useEffect } from 'react';
import axios from '../../common/axios';
import _ from 'lodash';
import { BasicInfo } from './steps/step1';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { RPInfo } from './steps/step2';
import { ContactInfo } from './steps/step3';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formStateAtom2 } from '../../atom/formState2';
import { businessType } from '../../atom/businessType';
import { checkList } from '../../atom/checklist';
import { CreditInfo } from './steps/step4';
import { ConstructionInfo } from './steps/step5';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';

const MakeEvaluation = () => {
  const [pointList, setPointList] = useState([]);
  const setContructionCheckList = useSetRecoilState(checkList)
  const setbusinesstype = useSetRecoilState(businessType)
  const [formState, setFormState] = useState(null);
  const navigate = useNavigate();


  //협력업체 가입 정보 로딩
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/getinitialValue');
        if (res.data.success) {
          const data = res.data.data.length > 0 && res.data.data.map((v) => ({
            법인명: v.uveVendorNameTB50, 업체유형: v.VendorEVAType2,
            본점소재지: v.ugenAddTXT250, 사업자등록번호: v.uveTaxIDTB16,
            참여공종: v.VendorDisciplineMS && v.VendorDisciplineMS.split(','),
            대표공종: v.VendorDisciplinePD && v.VendorDisciplinePD,
            record_no: v.record_no

          }))
          [0]

          setFormState(data);

          setbusinesstype(data.업체유형)
        }
      } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
      }
    };
    fetchData();
  }, []);

  //협력업체 평가표 로딩
  useEffect(() => {
    const fetchData = async () => {
      const body = {
        "bpname": "Vendors Evaluation Check List",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": "Terminated",
              "condition_type": "neq"
            },
          ]
        }
      }

      try {
        const res = await axios.post('/getbprecordsSecure?path=company', body);
        if (res.data.success) {
          if (formState) {
            if (formState.업체유형 === "외주") {
              // 처리 로직: 외주 업체일 경우
              setPointList(res.data.data.data.filter(v => v.VendorDisciplinePD === formState.대표공종)[0]._bp_lineitems);

              const data = {
                "시공능력 평가액": res.data.data.data.filter(v => v.VendorDisciplinePD === formState.대표공종)[0]._bp_lineitems.find(v => v.EvaDescription === "시공능력 평가액"),
                "하이테크 실적": res.data.data.data.filter(v => v.VendorDisciplinePD === formState.대표공종)[0]._bp_lineitems.find(v => v.EvaDescription === "하이테크 실적")
              }

              const result = {};
              for (let key in data) {
                result[key] = data[key].EvaDesc.split('\\n').map(s => s.split(':')[0].trim());
              }
              setContructionCheckList(result);
            } else {
              setPointList(res.data.data.data.filter(v => v.VendorEVAType2 === formState.업체유형)[0]._bp_lineitems);

            }
          }
        }
      } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
      }
    };
    fetchData();
  }, [formState]);

  //멀티스텝
  const formStateReal = useRecoilValue(formStateAtom2);
  const [stepPages, setStepPages] = useState([]);

  console.log(formStateReal)

  const [step, setStep] = useState(0);
  const [lastStepIndex, setlastStepIndex] = useState(0);
  const [steps, setSteps] = React.useState([]);

  useEffect(() => {
    if (formState) {



      const isSubcontract = formState.업체유형 === "외주";
      setStepPages(
        isSubcontract ?
          [<BasicInfo />, <RPInfo />, <ContactInfo />, <CreditInfo />, <ConstructionInfo />] :
          [<BasicInfo />, <RPInfo />, <ContactInfo />, <CreditInfo />]
      );



      setSteps((prevSteps) => {
        // 만약 이전 단계들이 모두 초기 상태라면 새로운 단계를 설정합니다.
        if (prevSteps.every(step => step.isValid === undefined && step.isCompleted === undefined && step.isStatus === undefined)) {
          return isSubcontract ?
            [
              {
                label: '기본 정보 체크', descrpition: "회원 가입 시 입력한 정보를 확인하세요",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '대표자 정보', descrpition: "삼성중공업에서의 경험 및 대표자의 경력을 입력하세요",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '업무 담당자 정보', descrpition: "업무 담당자 정보를 넣으세요.",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '신용평가 정보', descrpition: "신용정보를 입력하세요.",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '시공 관련 정보', descrpition: "시공 관련 실적을 입력하세요",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              }] :
            [
              {
                label: '기본 정보 체크', descrpition: "회원 가입 시 입력한 정보를 확인하세요",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '대표자 정보', descrpition: "삼성중공업에서의 경험 및 대표자의 경력을 입력하세요",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '업무 담당자 정보', descrpition: "업무 담당자 정보를 넣으세요.",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              },
              {
                label: '신용평가 정보', descrpition: "신용정보를 입력하세요.",
                isValid: undefined, isCompleted: undefined, isStatus: undefined
              }];
        }
        // 그렇지 않다면 이전 상태를 그대로 유지합니다.
        return prevSteps;
      });


      // const lastStepIndex = steps.length-1;
      const lastStepIndex = formState.업체유형 === "외주" ? 4 : 3;
      setlastStepIndex(lastStepIndex)
    }
  }, [formState, step]);

  const isLastStep = lastStepIndex === step;


  const onStepSubmit = React.useCallback(event => {
    const { isValid, values } = event;

    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));

    setSteps(currentSteps);
    if (!isValid) {
      return;
    }

    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      if (!isLastStep) { updatedSteps[step + 1].isStatus = true };
      updatedSteps[step].isCompleted = true;
      return updatedSteps;
    });
    setStep(() => Math.min(step + 1, lastStepIndex));
    setFormState(prevFormState => ({
      ...prevFormState,
      ...formStateReal, ...values
    }));

    if (isLastStep) {
      Swal.fire({
        title: 'Are you sure?',
        text: "입력한 정보를 정말 제출하시겠습니까?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
      }).then((result) => {
        if (result.isConfirmed) {
          sendInfo({ ...formStateReal, ...values });
        }
      });
    }

  }, [step, lastStepIndex, formStateReal]);


  const handlePrevious = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[step].isStatus = false;
      updatedSteps[step - 1].isCompleted = false;
      updatedSteps[step - 1].isStatus = true;
      return updatedSteps;
    });
  };


  const sendInfo = (formState) => {
    const contactArray = formState.contact && Object.entries(formState.contact).map(([type, data]) => ({
      type,
      ...data
    }));

    pointList.forEach(item => {
      const key = item.EvaDescription;
      if (formState[key]) {
        item.SelectedValue = formState[key];
        const desc = item.EvaDesc;
        const regExp = new RegExp(`${formState[key].replace(/\(/g, '\\(').replace(/\)/g, '\\)')}\\s*:\\s*(\\d+)`);
        const match = desc.match(regExp);
        if (match) {
          item.EvalutionPoint = parseInt(match[1], 10);
        }
      }
    });

    const shiworkhistory = formState.삼성중공업근무이력 && formState.삼성중공업근무이력.length > 0 ? formState.삼성중공업근무이력.map((v) => ({ uuu_tab_id: "근무이력", VESHIName: v.name, VESHIDoB: dayjs(new Date(v.date)).format("MM-DD-YYYY") })) : [];
    const Contact = contactArray.map((v) => ({ uuu_tab_id: "업무 담당자", uirCntctFstNmTB: v.name.slice(1, 10), uriCntctLstNmTB: v.name.slice(0, 1), JobPosition: v.type, BidderPersonMail: v.email, uuu_user_workphone: v.phone }))
    const shicontracthistory = formState.삼성중공업하도이력.map((v) => ({ uuu_tab_id: "하도급이력", ContractTitle: v.name, VESHIConStart: dayjs(new Date(v.date1)).format("MM-DD-YYYY"), VESHIConFinish: dayjs(new Date(v.date2)).format("MM-DD-YYYY") }))
    const leaderhistory = formState.대표자경력.map((v) => ({ uuu_tab_id: "대표자 경력", VendorRepCareer: v.name, VendorCareerStartDate: dayjs(new Date(v.date1)).format("MM-DD-YYYY"), VendorCareerFinishDate: dayjs(new Date(v.date2)).format("MM-DD-YYYY") }))
    const point = pointList.map((v => ({ ...v, uuu_tab_id: "평가항목" }))).map((v) => ({ ...v, EvaDesc: v.EvaDesc.replaceAll("\\n", "\n") }))


    const vendorDisciplines = formState?.["참여공종"] || [];
    let vendorMS = null;
    let vendorPD = null;
    let vendorDis = null;

    if (vendorDisciplines.length === 1) {
      vendorMS = vendorDisciplines[0];
      vendorPD = vendorDisciplines[0];
      vendorDis =
        vendorPD === "건축" || vendorPD === "금속" || vendorPD === "도장/방수" || vendorPD === "비계" || vendorPD === "코킹" ? "건축" :
          vendorPD === "설비" || vendorPD === "탱크" ? "설비" :
            vendorPD === "전기" || vendorPD === "정보통신" ? "전기" :
              vendorPD === "제어" || vendorPD === "자동제어" ? "제어" : ""

    } else if (vendorDisciplines.length > 1) {
      vendorMS = vendorDisciplines.join(", ");
      vendorPD = formState?.["대표공종"] || null;
      vendorDis =
        vendorPD === "건축" || vendorPD === "금속" || vendorPD === "도장/방수" || vendorPD === "비계" || vendorPD === "코킹" ? "건축" :
          vendorPD === "설비" || vendorPD === "탱크" ? "설비" :
            vendorPD === "전기" || vendorPD === "정보통신" ? "전기" :
              vendorPD === "제어" || vendorPD === "자동제어" ? "제어" : ""
    }

    const body = {
      "options": {
        "bpname": "Vendors Evaluation",
        "workflow_details": {
          "WFCurrentStepName": "업체 사전 평가 작성",
          "WFActionName": "사전평가 전송"
        }
      },
      "data": [{
        VendorCreditGrade: formState.신용평가등급1,
        VendorCashGrade: formState.현금흐름등급1,
        VendorDisciplineMS: vendorMS,
        VendorDisciplinePD: vendorPD,
        DisciplineSPD: vendorDis,
        VendorLicense: formState["보유면허"] ? formState["보유면허"].join(", ") : null,
        ConstructionAbility: formState["시공능력 평가액1"] ? formState["시공능력 평가액1"] : null,
        record_no: formState.record_no,
        uveVendorNameTB50: formState.법인명,
        VendorEVAType2: formState.업체유형,
        EvalutionDate: dayjs().format("MM-DD-YYYY"),
        ugenAddTXT250: formState.본점소재지,
        uveTaxIDTB16: formState.사업자등록번호,
        SHIWorkYN: formState.근무여부 === '예' ? "Yes" : 'No',
        SHISubconYN: formState.협력사여부 === '예' ? "Yes" : 'No',
        CEQCategoryPD: formState.종합전문구분,
        MainReference: formState.주요실적,
        uuu_contact_first_name: formState.contact.입찰.name.slice(1, 10),
        uuu_contact_last_name: formState.contact.입찰.name.slice(0, 1),
        uuu_user_id: formState.contact.입찰.email,
        uuu_contact_lang: "ko",
        uuu_user_dateformat: 109,
        uuu_user_timezone_pref: 340,
        _bp_lineitems: [...shiworkhistory, ...Contact, ...shicontracthistory, ...leaderhistory, ...point]
      }]
    }


    const files = [
      ...formStateReal.creditfile,
      ...formStateReal.준법,
      ...(formStateReal.consturctionfile || [])
    ];


    // Function to check for duplicate file names
    const hasDuplicates = (files) => {
      const fileNames = files.map(file => file.name);
      const set = new Set(fileNames);
      return set.size !== fileNames.length;
    };

    // Check for duplicate file names before sending data
    if (hasDuplicates(files)) {
      Swal.fire({
        title: 'Duplicate File Names Detected',
        text: '하나 이상의 파일 이름이 중복되었습니다. 파일 이름을 변경하고 다시 시도하십시오.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return; // Stop execution if there are duplicate file names
    }

    axios.post("/submitVE", { body, files })
      .then(response => {
        if (response.data.success) { // Assuming the API returns a `success` property in the data
          Swal.fire({
            title: 'Success',
            text: '성공적으로 제출되었습니다. 사전 평가 후 기본계약서 서명이 대표이메일이나 입찰자 이메일로 요쳥됩니다.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            navigate('/home');
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: response.data.data,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch(err => {
        const errorMessage = err.message || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });

  };


  if (!formState) {
    return <div className="loadingContainer">
      <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
    </div>;;
  }


  return (
    <>
      <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep" id="kt_create_account_stepper">
        <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
          <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center" style={{ backgroundImage: "url(/images/auth-bg.png)" }}>
            <div className="d-flex flex-center py-10 py-lg-20 mt-lg-20">
              <a href="http://www.samsungshi.com/kor/default.aspx">
                <img alt="Logo" src="/images/g1313.png" className="h-50px" />
              </a>
            </div>
            <div className="d-flex flex-row-fluid justify-content-center p-10">
              <div className="stepper-nav">
                {/* 나브 */}
                {steps.map((v, index) => (
                  <div key={index} className={v.isCompleted ? "stepper-item completed" : v.isStatus || index === 0 ? "stepper-item current" : "stepper-item pending"}  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon rounded-3">
                        {v.isCompleted && <i className="ki-duotone ki-check fs-2 stepper-check"></i>}
                        <span className="stepper-number">{index + 1}</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title fs-2">{v.label}</h3>
                        <div className="stepper-desc fw-normal">{v.descrpition}</div>
                      </div>
                    </div>
                    {index < steps.length - 1 && <div className="stepper-line h-40px"></div>}
                  </div>
                ))}
              </div>

            </div>
            <div className="d-flex flex-center flex-wrap px-5 py-10">
              <div className="d-flex fw-normal">
                <RouterLink to={{ pathname: "/home" }} className="text-success px-5">돌아가기</RouterLink>
              </div>
              <div className="d-flex fw-normal">
                <a href="mailto:dujin.kim@dtsolution.io" className="text-success px-5" target="_blank">Contact Us</a>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
              <Form className="my-auto pb-5" initialValues={formState} onSubmitClick={onStepSubmit} render={formRenderProps => <div>
                {/* 스텝 바디 */}
                <FormElement>
                  {stepPages[step]}
                  <div className="d-flex flex-stack pt-15">
                    <div className="mr-2">
                      {step !== 0 ? <Button type="button" onClick={handlePrevious} className="btn btn-lg btn-light-primary" >
                        <i className="ki-duotone ki-arrow-left fs-4 me-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>이전</Button> : undefined}
                    </div>
                    <div>

                      <Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> {!isLastStep ? "다음" : "제출"}
                        <i className="ki-duotone ki-arrow-right fs-4 ms-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i></Button>
                    </div>
                  </div>
                </FormElement>
              </div>} />
            </div>
          </div>
        </div>
      </div>


    </>
  )

}

export default MakeEvaluation;
