import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import {
	loadMessages,
  } from "@progress/kendo-react-intl";
  import krMessage from "./kr.json"
  loadMessages(krMessage, "ko-KR");



dayjs.extend(utc);
dayjs.extend(timezone);

const InvitationPage = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get('/getsitemeeting'); 
          if(res.data.success){
            setData(res.data.data);
			setLoading(false)
          }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  
  
	// const handleCloseModal = () => {
	// 	setVisibleDialog(false);
	//   };

	// const handleButtonClick =  (e) => {
	// setTableData({title:e.title, details:e.details});
	// setVisibleDialog(true);

	// }



	const handleButtonAPIClick = (v) => {
		const now = dayjs().unix();
		const SBMDueDate = dayjs(v.SBMDueDate, "MM-DD-YYYY HH:mm:ss").unix();

		    // 오늘 날짜가 v.SBMDueDate보다 지났을 경우
			if (now>SBMDueDate) {
				Swal.fire({
				  title: '요청 불가',
				  text: '마감 날짜가 지나서 요청을 보낼 수 없습니다.',
				  icon: 'error',
				  confirmButtonText: '확인'
				});
				return;
			  }

		Swal.fire({
		  title: '현설 참가 여부 요청',
		  text: v.SBMAttendIntCB ==="예"?"정말로 현설 참석을 하지 않는다고 요청을 보내시겠습니까?'": '정말로 현설을 참석한다고 요청을 보내시겠습니까?',
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: '네, 보내기',
		  cancelButtonText: '아니요, 취소'
		}).then((result) => {
		  if (result.isConfirmed) {
			axios.get(`/udpatesm1?record=${v.record_no}&project=${v.project_projectnumber}&vendor=${v.uveTaxIDTB16}`)
			.then(response => {
			  // 여기에서 서버에서 보낸 응답을 확인하여 성공 메시지를 표시합니다.
			  // 응답의 구조에 따라 아래의 코드를 적절히 수정해야 합니다.
			  if (response.data && response.data.success) {
				Swal.fire({
				  title: '성공',
				  text: '요청이 성공적으로 처리되었습니다.',
				  icon: 'success',
				  confirmButtonText: '확인'
				})
				.then(() => {
					window.location.reload();
				  });
			  } else {
				Swal.fire({
				  title: '오류',
				  text: '서버에서 오류가 발생했습니다.',
				  icon: 'error',
				  confirmButtonText: '확인'
				});
			  }
			})
			.catch(error => {
			  // 네트워크 오류 또는 기타 예상치 못한 오류가 발생하면 이 블록이 실행됩니다.
			  Swal.fire({
				title: '오류',
				text: '요청을 처리하는 도중 오류가 발생했습니다.',
				icon: 'error',
				confirmButtonText: '확인'
			  });
			});
		  }
		});
	  };

	
	  const handleDownload = (base64String) => {
		// base64 문자열을 Uint8Array로 변환
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
	  
		// Blob 객체 생성
		const blob = new Blob([byteArray], { type: 'application/octet-stream' });
	  
		// Blob 객체를 URL로 변환
		const url = window.URL.createObjectURL(blob);
	  
		// 'a' 태그를 생성하고 설정하여 파일을 다운로드
		const a = document.createElement('a');
		a.href = url;
		a.download = 'download.zip';  
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	  
		// URL 해제
		window.URL.revokeObjectURL(url);
	  }

	  if (loading) {
        return <div className="loadingContainer">
        <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
      </div>;;
      }

  return(
	<>
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
			<div className="card-header border-0 pt-5">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold fs-3 mb-1">현장설명회</span>
					<span className="text-muted mt-1 fw-semibold fs-7">{data.length}번의 현장설명회 요청이 있었습니다.</span>
				</h3>
				<div className="card-toolbar">
				</div>
			</div>
			<div className="card-body py-3">
				<div className="table-responsive">
					<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
						<thead>
							<tr className="fw-bold text-muted">

								<th className="min-w-120px">프로젝트 이름</th>
								<th className="min-w-120px">계약요청명</th>
								<th className="min-w-180px">현장설명회 일시/<br/>회신마감일</th>
								{/* <th className="min-w-140px">내역보기</th> */}
								<th className="min-w-140px">현설 참여여부</th>
								<th className="min-w-80px">자료 다운로드</th>
							</tr>
						</thead>
						<tbody>
							{data.length>0 &&  data.map((v, index)=>(
							<tr key={index}>
							<td className="text-dark fw-bold text-hover-primary fs-6">{v.project_projectname}</td>
								<td className="text-dark fw-bold text-hover-primary fs-6">{v.title}</td>
								<td>
									<span className="text-muted text-hover-primary fs-7 d-block">{dayjs(v.SBMDate).format("YYYY년 MM월 DD일")}</span>
									<span className="text-muted text-hover-primary fs-7 d-block">{dayjs(v.SBMDueDate).format("YYYY년 MM월 DD일")}</span>
								</td>
{/* 
								<td className="text-dark fw-bold text-hover-primary fs-6">
									<span
									onClick={(e) => {
										e.preventDefault();
										handleButtonClick(v);
									}}
									className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
									>
									<i className="ki-duotone  ki-book-square fs-3">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
									</i>
									</span>
								</td> */}
							<td className="text-muted fw-bold text-hover-primary fs-7">{v.SBMAttendIntCB}  &nbsp;&nbsp;
							<button className="btn btn-sm btn-light" style={{padding:5}} onClick={()=>handleButtonAPIClick(v)}>요청 보내기</button> </td>

							<td className="text-muted fw-bold text-hover-primary fs-7">
							<span className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"  onClick={() => handleDownload(v.file)}>
								<i className="ki-duotone ki-file-down  fs-2x">
								<span className="path1"></span>
								<span className="path2"></span>
								</i>
							</span>

							</td>


								<td className="text-end">
				
								</td>
							</tr>
					))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
    </div>
    </div>
	{/* {visibleDialog &&
        <Dialog title={tableData.title + " 내역"}  width={1200} onClose={handleCloseModal}> 
		       <LocalizationProvider language="ko-KR">
            <IntlProvider locale="kr">
		<ExcelExport data={tableData.details} ref={_export}>
			<Grid data={tableData.details.slice(page.skip, page.take + page.skip)} onPageChange={pageChange}
			skip={page.skip} take={page.take} total={tableData.details.length}
			pageable={{
				buttonCount: 4,
				pageSizes: [5, 10, 15, 'All'],
				pageSizeValue: pageSizeValue
			  }}
			style={{maxHeight:620}} >
			<GridToolbar>
            <button title="Export Excel" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={excelExport}>
              엑셀 다운로드
            </button>
          </GridToolbar>
				<Column field="li_num" title="Id" width="80px"  />
				<Column field="spocItemName" title="품명" width="250px" />
				<Column field="spocSpec" title="규격" width="300px" />
				<Column field="spocUoMTxt" title="단위" width="80px" />
				<Column field="urfbQuantityDA" title="수량" width="180px" />
			</Grid>
		</ExcelExport>
		</IntlProvider>
          </LocalizationProvider>
		</Dialog>
	} */}
</>
)};

export default InvitationPage;