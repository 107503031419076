import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormDropDownList, FormInput, FormMultiSelect, FormTextArea, FormUpload } from './forms';
import { requiredValidator } from '../../../login/multistep/validators';
import { useRecoilValue } from 'recoil';
import { formStateAtom2 } from '../../../atom/formState2';
import { checkList } from '../../../atom/checklist';

export const ConstructionInfo = ({ }) => {


     const formState = useRecoilValue(formStateAtom2);
     const checkListPD = useRecoilValue(checkList);

     const defaultFiles = formState.consturctionfile && formState.consturctionfile.map((v) => ({ name: v.name }))

     const types = checkListPD["시공능력 평가액"] && checkListPD["시공능력 평가액"].length > 0 ? checkListPD["시공능력 평가액"] : ["1000억원 이상", "500억원 이상", "300억원이상", "100억원 이상", "100억원 미만 및 시공능력 평가 없는 경우"];
     const types2 = ["설비", "전기", "정보통신", "건축", "금속", "도장/방수", "자동제어", "비계", "탱크", "에어컨냉매배관", "코킹"];
     const types3 = checkListPD["하이테크 실적"] && checkListPD["하이테크 실적"].length > 0 ? checkListPD["하이테크 실적"] : ["평택 PJT 1차社 3개이상 참여", "평택 2차 / 타지역 1차 참여사 ", "타지역 하이테크 2차社 참여 ", "신규 참여사"];
     const licenseTypes = ["토목공사",
          "건축공사",
          "토목건축공사",
          "산업환경설비공사",
          "조경공사",
          "가스난방공사",
          "구조물해체비계공사",
          "금속창호지붕건축물조립공사",
          "기계가스설비공사",
          "도장습식방수석공사",
          "상하수도설비공사",
          "수중준설공사",
          "승강기삭도공사",
          "실내건축공사",
          "조경식재시설물공사",
          "지반조성포장공사",
          "철강구조물공사",
          "철근콘크리트공사",
          "철도궤도공사",
          "전기공사",
          "통신공사"
     ]


     const [checkValue, setCheckValue] = React.useState(false)

     const handleValidChange = (newValid) => {
          setCheckValue(newValid);
     };

     React.useEffect(() => {
          if (formState.consturctionfile) {
               setCheckValue(formState.consturctionfile.length > 0 ? true : false)
          }
     }, [formState]);


     return (
          <div className="current" data-kt-stepper-element="content">
               <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                         <h2 className="fw-bold d-flex align-items-center text-dark">시공 관련 정보
                         </h2>
                    </div>

                    <div style={{ width: "100%" }} className='fv-row mb-10'>
                         <Field key={'참여공종'} id={'참여공종'} name={"참여공종"} label={'참여 공종'} component={FormMultiSelect} data={types2} validator={requiredValidator} />
                         <Field key={'보유면허'} id={'보유면허'} name={"보유면허"} label={'보유 면허'} component={FormMultiSelect} data={licenseTypes} validator={requiredValidator} />
                    </div>
                    <Field key={'하이테크 실적'} id={'하이테크 실적'} name={"하이테크 실적"} label={'하이테크 실적'} data={types3} component={FormDropDownList} validator={requiredValidator} />
                    <Field key={'시공능력 평가액'} id={'시공능력 평가액'} name={"시공능력 평가액"} data={types} label={'시공능력 평가액 범위'} component={FormDropDownList} validator={requiredValidator} />
                    <Field key={'시공능력 평가액1'} id={'시공능력 평가액1'} name={"시공능력 평가액1"} label={'시공능력 평가액'} component={FormInput} validator={requiredValidator} />

                    <Field key={'주요실적'} id={'주요실적'} name={"주요실적"} label={'주요 하이테크 실적(공사명, 시공사, 공사금액)'} component={FormTextArea} validator={requiredValidator} />

                    <Field
                         key={'consturctionfile'} className="attach"
                         id={'consturctionfile'}
                         name={'consturctionfile'}
                         label={'시공 관련 파일 첨부'}
                         //  defaultFiles = {defaultFiles}
                         multiple={true}

                         hint={'파일을 pdf나 이미지로 올리세요. 건설면허사본, 시공능력평가서, 실적증명원(3년치, 하이테크 필수 포함), 지명원을 첨부하세요.'}
                         component={FormUpload} onValidChange={handleValidChange} validator={requiredValidator}
                    />

               </div>

          </div>
     );
};
