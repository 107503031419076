import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const Layout = () => (
  <div className='app-default' style={{backgroundColor:"#fcfcfc", minHeight:"100vh"}}>
    <Header />
    <main>
      <Outlet/>
    </main>
    <Footer/>
  </div>
);

export default Layout;