import React, { useState,useEffect,useRef } from 'react';
import Nav from './Nav';
import logout from '../common/logout';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../common/axios';

 
const Header = () =>{

const [isMenuVisible, setMenuVisible] = useState(false);
const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
const [userData, setUserData] = useState({});

let leaveTimeout;

const handleMouseEnter = () => {
  clearTimeout(leaveTimeout); // 이전의 딜레이를 취소합니다.
  setMenuVisible(true);
};

const handleMouseLeave = () => {
  // 1초 딜레이 후 메뉴를 숨깁니다.
  leaveTimeout = setTimeout(() => {
    setMenuVisible(false);
  }, 200); // 1000 밀리초(1초) 지연
};


    useEffect(() => {
            axios.get(`/current_user`,  {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then(userResponse => {
                    setUserData(userResponse.data);
                })
                .catch(error => {
                  // 사용자에게 일반적인 오류 메시지 표시
                  alert('서비스 이용에 문제가 발생했습니다. 나중에 다시 시도해주세요.');
                });

    }, []);


const handleLogoutClick = () => {
    logout();
    };
    
    useEffect(() => {
      const timer = setTimeout(() => {
        logout();  // 위에서 정의한 로그아웃 함수 호출
      }, 1800000000);  // maxAge와 동일한 시간 설정
    
      return () => clearTimeout(timer);  // 컴포넌트 언마운트 시 타이머 해제
    }, []);
    
 // 상태 변수를 사용하여 배경색 상태를 관리합니다.
 const [backgroundColor, setBackgroundColor] = useState('transparent');


 // 스크롤 이벤트 핸들러를 정의합니다.
 const handleScroll = () => {
   // 현재 스크롤 위치를 가져옵니다.
   const scrollY = window.scrollY;

   // 스크롤 위치에 따라 배경색을 변경합니다.
   if (scrollY > 0) {
     setBackgroundColor('#ffffff'); // 스크롤이 아래로 내려갈 때 배경색을 흰색으로 변경
   } else {
     setBackgroundColor('transparent'); // 스크롤이 위로 올라갈 때 배경색을 투명으로 변경
   }
 };

 // 컴포넌트가 마운트될 때와 언마운트될 때 스크롤 이벤트 핸들러를 추가/제거합니다.
 useEffect(() => {
   window.addEventListener('scroll', handleScroll);

   return () => {
     window.removeEventListener('scroll', handleScroll);
   };
 }, []);

 const [moblestatus, setmoblestatus] = useState(window.innerWidth <= 768); 

 useEffect(() => {
  const handleResize = () => {
    setmoblestatus(window.innerWidth <= 768); // 모바일 뷰의 너비 임계값을 설정하세요
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



const handlePageClick = (e) => {
  if (isMobileMenuVisible) {
    e.stopPropagation(); // 이벤트 버블링 막기
    setMobileMenuVisible(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  document.addEventListener('click', handlePageClick);

  return () => {
    window.removeEventListener('scroll', handleScroll);
    document.removeEventListener('click', handlePageClick);
  };
}, [isMobileMenuVisible]); 

const [countData, setCountData] = useState({});


useEffect(() => {
  const fetchData = async () => {
    try {
      const res = await axios.get('/getcount'); 
      if(res.data.success){
      setCountData(res.data.data);
      }
     
    } catch (error) {
    console.error('데이터 가져오기 중 오류 발생:', error);
    }
  };
  fetchData();
  }, []);

const [transformValue, setTransformValue] = useState('-330px, 74px');
const calculateTransformValue = () => {
  const width = window.innerWidth;
  
  // 여기에서 해상도에 따라 적절한 translate 값을 계산합니다.
  // 예를 들어, FHD (1920x1080)일 때 -330px, 다른 해상도일 때는 비례식을 사용하여 계산할 수 있습니다.
  const translateX = width < 1920 ? `${-330 * (width / 1920)}px` : '-330px';
  setTransformValue(`${translateX}, 74px`);
};

useEffect(() => {
  calculateTransformValue();
  window.addEventListener('resize', calculateTransformValue);
  return () => {
    window.removeEventListener('resize', calculateTransformValue);
  };
}, []);

return (
    <>
    {moblestatus ?    
    <> 
    <div className='d-flex flex-column flex-root app-root'>
      <div className='app-page flex-column flex-column-fluid'>

        </div> 
    </div>
      <div className="app-header"  style={{position:"sticky", top:0, zIndex:107, backgroundColor }}>
      <div className="app-container container-xxl d-flex align-items-stretch justify-content-between">
  
          {/* 로고 */}
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
              <a href="http://www.samsungshi.com/kor/default.aspx">
                  <img alt="Logo" src="/images/g1313.png" className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show" />
              </a>
          </div>
  
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div   onClick={(e) => {e.stopPropagation();}} className={`app-header-menu app-header-mobile-drawer align-items-stretch drawer drawer-end ${isMobileMenuVisible ? 'drawer-on' : ''}`} style={isMobileMenuVisible?{width:250}:{}}>
                  <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" >
                      <Nav/>
                  </div>
              </div>
              <div className="app-navbar flex-shrink-0">
                  <div className="app-navbar-item ms-1 ms-md-4" >
                      <div className={ `cursor-pointer symbol symbol-35px show ${isMenuVisible? 'menu-dropdown':''}`}  onClick={() => setMenuVisible(!isMenuVisible)}>
                          <div className="symbol-label fs-2 fw-semibold bg-primary text-inverse-primary">{ userData.username &&userData.username.slice(0,1)}</div>
                      </div>
                      <div 
                          className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${isMenuVisible ?`show` :``}`}
                          style={isMenuVisible?{zIndex:107, position:"fixed",inset:"0px 0px auto auto", margin:0, transform:"translate(-50px,59px)", backgroundColor:"#ffffff"} :{}}
        
                          >
                               <div className="menu-item px-3">
                                 <div className="menu-content d-flex align-items-center px-3">
                                      <div className="symbol symbol-50px me-5">
                                              <div className="symbol-label fs-2 fw-semibold bg-primary text-inverse-primary">{ userData.username &&userData.username.slice(0,1)}</div>
                                      </div>
                              
                                      <div className="d-flex flex-column">
                                          <div className="fw-bold d-flex align-items-center fs-5">{userData.companyName}
                                          {/* <span className="fw-bold fs-8 px-2 py-1 ms-2">({userData.IRTaxID})</span> */}
                                          </div>
                                          <span className="fw-semibold text-muted text-hover-primary fs-7">{userData.email}</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="separator my-2"></div>
                              <div className="menu-item px-5">
                                <RouterLink  className="menu-link px-5"to= "/profile">나의 프로필</RouterLink>
                </div>
  

                              <div className="menu-item px-5">
                              <RouterLink to="/confirmContract" className="menu-link px-5">
                              <span className="menu-text">신규 계약 검토</span>
                              {countData && countData.check &&countData.check !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.check}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                              <div className="menu-item px-5">
                              <RouterLink to="/log-signing"  className="menu-link px-5">
                              <span className="menu-text">계약 서명</span>
                              {countData && countData.signing &&countData.signing !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.signing}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                              <div className="menu-item px-5">
                              <RouterLink to= "/log-bond" className="menu-link px-5">
                              <span className="menu-text">보증 서류</span>
                              {countData && countData.bond &&countData.bond !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.bond}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                              <div className="menu-item px-5">
                              <RouterLink to= "/log-signing" className="menu-link px-5">
                              <span className="menu-text">인지세 납부</span>
                              {countData && countData.paperTax &&countData.paperTax !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.paperTax}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                              <div className="separator my-2"></div>
                              <div className="menu-item px-5">
                  <span className="menu-link px-5" onClick={handleLogoutClick}>로그아웃</span>
                </div>
  
                      </div>
                  </div>
                  <div className="app-navbar-item d-lg-none ms-2 me-n2" title="Show header menu" onClick={(e) => { e.stopPropagation(); setMobileMenuVisible(!isMobileMenuVisible);}}>
                  <div className="btn btn-flex btn-icon btn-active-color-primary w-30px h-30px" id="kt_app_header_menu_toggle">
                  <i className="ki-outline ki-element-4 fs-1"></i>
                  </div>
              </div>
              </div>
    
          </div>
  
      </div>
  </div>
    </>
    :
    <>
    <div className='d-flex flex-column flex-root app-root' style={{marginBottom:75}}>
      <div className='app-page flex-column flex-column-fluid'>
      </div> 
        <div className="app-header" style={{position:"fixed", left:0, right:0, zIndex:100, backgroundColor:backgroundColor }}>
        <div className="app-container container-xxl d-flex align-items-stretch justify-content-between">

            {/* 로고 */}
            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
                <a href="http://www.samsungshi.com/kor/default.aspx">
                    <img alt="Logo" src="/images/g1313.png" className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show" />
                </a>
            </div>

            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                <div className="app-header-menu app-header-mobile-drawer align-items-stretch">
                    <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" >
                        <Nav/>
                    </div>
                </div>
                <div className="app-navbar flex-shrink-0">
                    <div className="app-navbar-item ms-1 ms-md-4" >
                        <div className={ `cursor-pointer symbol symbol-35px show ${isMenuVisible? 'menu-dropdown':''}`}    
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                            <div className="symbol-label fs-2 fw-semibold bg-primary text-inverse-primary">{ userData.username &&userData.username.slice(0,1)}</div>
                        </div>
                        <div 
                            className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${isMenuVisible ?`show` :``}`}
                            style={isMenuVisible ? { zIndex: 107, position: "fixed", inset: "0px 0px auto auto", margin: 0, transform: `translate(${transformValue})`, backgroundColor: "#ffffff" } : {}}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            >
                                  <div className="menu-item px-3">
                                    <div className="menu-content d-flex align-items-center px-3">
                                        <div className="symbol symbol-50px me-5">
                                                <div className="symbol-label fs-2 fw-semibold bg-primary text-inverse-primary">{ userData.username &&userData.username.slice(0,1)}</div>
                                        </div>
                                
                                        <div className="d-flex flex-column">
                                            <div className="fw-bold d-flex align-items-center fs-5">{userData.companyName}
                                            {/* <span className="fw-bold fs-8 px-2 py-1 ms-2">({userData.IRTaxID})</span> */}
                                            </div>
                                            <span className="fw-semibold text-muted text-hover-primary fs-7">{userData.email}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator my-2"></div>
                                <div className="menu-item px-5">
                                  <RouterLink  className="menu-link px-5"to= "/profile" >나의 프로필</RouterLink>
                  </div>

                          <div className="menu-item px-5">
                              <RouterLink to= "/confirmContract"  className="menu-link px-5">
                              <span className="menu-text">신규 계약 검토</span>
                              {countData && countData.check &&countData.check !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.check}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                          <div className="menu-item px-5">
                              <RouterLink to= "/log-signing"  className="menu-link px-5">
                              <span className="menu-text">계약 서명</span>
                              {countData && countData.signing &&countData.signing !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.signing}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                              <div className="menu-item px-5">
                              <RouterLink to="/log-bond"  className="menu-link px-5">
                              <span className="menu-text">보증 서류</span>
                              {countData && countData.bond &&countData.bond !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.bond}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>
                              <div className="menu-item px-5">
                              <RouterLink to= "/log-stamp" className="menu-link px-5">
                              <span className="menu-text">인지세 납부</span>
                              {countData && countData.paperTax &&countData.paperTax !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.paperTax}</span>
																		</span>:<span></span>}
                              </RouterLink>
                              </div>


                                <div className="separator my-2"></div>
                                <div className="menu-item px-5">
                    <span className="menu-link px-5" onClick={handleLogoutClick}>로그아웃</span>
                  </div>

                        </div>
                    </div>

                </div>


      
            </div>

        </div>
        </div>
      </div>
    </>
    }




    </>
)};

export default Header;
