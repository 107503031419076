import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import _ from "lodash";
import { useNavigate } from 'react-router-dom'; // 변경된 부분


const TaxPage = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // 변경된 부분

  useEffect(() => {
	const fetchData = async () => {
	  try {
		  const res = await axios.get('/gettaxinvoice'); 
          if(res.data.success){
            setData(res.data.data);
          }
	   
	  } catch (error) {
		console.error('데이터 가져오기 중 오류 발생:', error);
	  }
	};
	fetchData();
  }, []);


  const handleShowModal = (e) => {
	console.log(e)
  };

  return(
  <Layout>
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
			<div className="card-header border-0 pt-5">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold fs-3 mb-1">세금계산서</span>
					<span className="text-muted mt-1 fw-semibold fs-7">{data.length}건의 세금계산서가 발행가능합니다.</span>
				</h3>
				<div className="card-toolbar">
				</div>
			</div>
			<div className="card-body py-3">
				<div className="table-responsive">
					<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
						<thead>
							<tr className="fw-bold text-muted">
								<th className="min-w-100px">프로젝트명</th>
								<th className="min-w-250px">제목</th>
								{/* <th className="min-w-80px">공급자 사업자번호</th>
								<th className="min-w-80px">공급자 상호</th>
								<th className="min-w-80px">공급자 대표자 성명</th> */}
								<th className="min-w-80px">발행형태</th>
								<th className="min-w-50px">과세형태</th>
								<th className="min-w-50px">영수/청구</th>
								<th className="min-w-105px">공급가액 합계</th>
								<th className="min-w-100px">세액 합계</th>
								<th className="min-w-150px">합계금액</th>
								<th className="min-w-50px">세금계산서 발행하기</th>

							</tr>
						</thead>
						<tbody>
							{data.length>0 && data.map((v, index)=>(
							<tr key={index}>
								<td className="text-dark fw-bold text-hover-primary fs-6">{v["projectName"]}</td>
								<td className="text-dark fw-bold text-hover-primary fs-6">{v["title"]}</td>

								{/* <td className="text-muted fw-semibold fs-7">{v["invoiceeCorpNum"]}</td>
								<td className="text-muted fw-semibold fs-7">{v["invoiceeCorpName"]}</td>
								<td className="text-muted fw-semibold fs-7">{v["invoiceeCEOName"]}</td> */}
								<td className="text-dark fw-bold text-hover-primary fs-7">{v["issueType"]}</td>
								<td className="text-dark fw-bold text-hover-primary fs-7">{v["taxType"]}</td>
								<td className="text-dark fw-bold text-hover-primary fs-7">{v["purposeType"]}</td>
								<td className="text-dark fw-bold text-hover-primary fs-7">{v["supplyCostTotal"].toLocaleString()}원</td>
								<td className="text-dark fw-bold text-hover-primary fs-7">{v["taxTotal"].toLocaleString()}원</td>
								<td className="text-dark fw-bold text-hover-primary fs-7">{v["totaltotalAmount"].toLocaleString()}원</td>

								<td className="text-muted fw-semibold fs-7">
								<a className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'onClick={() => handleShowModal(v)}>
								<i className="ki-duotone ki-send">
								<span className="path1"></span>
								<span className="path2"></span>
								</i>
								</a>												
								</td>
							</tr>
							))}
					
						</tbody>
					</table>
				</div>
			</div>
		</div>
    </div>
    </div>

  </Layout>
)};

export default TaxPage;
