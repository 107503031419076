import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormInput ,FormDropDownList,FormMultiSelect} from './form-components';
import { CompanyNameValidator, CompanyNumberValidator, TaxNumberValidator, phoneValidator,requiredValidator} from './validators';
import { useRecoilValue } from 'recoil';
import { businessType2 } from '../../atom/businessType2';


export const CompanyDetails = () => {
     const types = ["구매", "외주", "용역"];
     const types2 = ["설비", "전기", "정보통신", "건축", "금속","도장/방수","자동제어","비계", "탱크", "에어컨냉매배관","코킹"];
     const businessType = useRecoilValue(businessType2);

     const [selectedValue, setselectedValue]=React.useState("구매")
     const onChange = (event)=>{setselectedValue(event.target.value)}
     
     const [selectedValue2, setselectedValue2]=React.useState([])
     const onChange2 = (event)=>{setselectedValue2(event.target.value)}


     return (
<div>
     <div style={{display: 'flex',justifyContent: 'space-between'}}>
    <Field key={'법인명'} id={'법인명'} name={"법인명"} label={'법인명'} component={FormInput} validator={requiredValidator} wrapperStyle={{width: "49%" ,marginRight: '5px'}} />
    <Field key={'법인명영문'} id={'법인명영문'} name={"법인명영문"} label={'법인명(영문)'} component={FormInput} wrapperStyle={{width:'49%'}} />
    </div>
    <div style={{display: 'flex',justifyContent: 'space-between'}}>
    <Field key={'등록번호'} id={'등록번호'} name={"등록번호"} label={'납세번호'} mask={'000-00-00000'} component={FormInput} validator={TaxNumberValidator} wrapperStyle={{width: "49%" ,marginRight: '5px'}} />
    {businessType !== 'individual' && (
    <Field key={'법인등록번호'} id={'법인등록번호'} name={"법인등록번호"} label={'법인등록번호'} mask={'000000-0000000'} component={FormInput}  validator={CompanyNumberValidator} wrapperStyle={{width: "49%"}} />
    )}
    </div>
    <Field key={'본점소재지'} id={'본점소재지'} name={"본점소재지"} label={'본점소재지'} component={FormInput} />
    <div style={{display: 'flex',justifyContent: 'space-between'}}>
    <Field key={'대표번호'} id={'대표번호'} name={"대표번호"} label={'대표번호'} component={FormInput} validator={phoneValidator}  wrapperStyle={{width: "49%" ,marginRight: '5px'}} />
    <Field key={'FAX'} id={'FAX'} name={"FAX"} label={'FAX'} component={FormInput}  wrapperStyle={{width: "49%" }} />
    </div>
    <Field key={'이메일'} id={'이메일'} name={"이메일"} label={'이메일'}type={'email'} component={FormInput} />
    <Field key={'등록구분'} id={'등록구분'} name={"등록구분"} label={'등록구분'} component={FormDropDownList} data={types} onChange={onChange} validator={requiredValidator} />
    {selectedValue === '외주'&&
    <Field key={'참여공종'} id={'참여공종'} name={"참여공종"} label={'참여 공종'} component={FormMultiSelect} data={types2} onChange={onChange2} validator={requiredValidator} />
}
     {selectedValue2.length>1 && 
      <Field key={'대표공종'} id={'대표공종'} name={"대표공종"} label={'대표 공종'} component={FormDropDownList} data={selectedValue2} validator={requiredValidator} />
     }


  </div>
     );
};
   