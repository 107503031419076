import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import Swal from 'sweetalert2';
import Layout from '../layout/Layout';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


export const UpdateContact = ({  }) => {

    const [data, setData] = useState({});
    const navigate = useNavigate(); // 변경된 부분


    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get('/getvendor'); 
            if(res.data.success){
              setData( {record_no:res.data.data.record_no,contact:res.data.data.contact});
            }
           
          } catch (error) {
            console.error('데이터 가져오기 중 오류 발생:', error);
          }
        };
        fetchData();
      }, []);

   

      const tableColumns = [
        {
          label: '영업',
          inputs: [
            { name: 'name', placeholder: '', maxWidth: 100 },
            { name: 'phone', placeholder: '', maxWidth: 200 },
            { name: 'email', placeholder: '', maxWidth: 250 },
          ],
        },
        {
          label: '납기',
          inputs: [
            { name: 'name', placeholder: '', maxWidth: 100 },
            { name: 'phone', placeholder: '', maxWidth: 200 },
            { name: 'email', placeholder: '', maxWidth: 250 },
          ],
        },
        {
             label: '품질',
             inputs: [
               { name: 'name', placeholder: '', maxWidth: 100 },
               { name: 'phone', placeholder: '', maxWidth: 200 },
               { name: 'email', placeholder: '', maxWidth: 250 },
             ],
           },
           {
             label: '세금계산서',
             inputs: [
               { name: 'name', placeholder: '', maxWidth: 100 },
               { name: 'phone', placeholder: '', maxWidth: 200 },
               { name: 'email', placeholder: '', maxWidth: 250 },
             ],
           },
           {
             label: '입찰',
             inputs: [
               { name: 'name', placeholder: '', maxWidth: 100 },
               { name: 'phone', placeholder: '', maxWidth: 200 },
               { name: 'email', placeholder: '', maxWidth: 250 },
             ],
           },
      ];

      useEffect(() => {
        if (data && data.contact) {
            const formattedData = data.contact.reduce((acc, curr) => {
                acc[curr.JobPosition] = {
                    name: curr.uriCntctLstNmTB + curr.uirCntctFstNmTB,
                    phone: curr.uuu_user_workphone,
                    email: curr.BidderPersonMail,
                    LineAutoSeq: curr.LineAutoSeq,
                };
                return acc;
            }, {});
            setTableData(formattedData);
        }
    }, [data]);

      const [tableData, setTableData] = useState({});

      const tabalarray = tableData && Object.entries(tableData).map(([type, data]) => ({
        type,
        ...data
      }));
    
      const dataSend = 
        {
            record_no:data.record_no, _bp_lineitems:
          tabalarray.map((v)=>({
                LineAutoSeq:v.LineAutoSeq, uuu_tab_id:"List of Contacts", 
                uirCntctFstNmTB:v.name.slice(1,10),uriCntctLstNmTB:v.name.slice(0,1),JobPosition:v.type, 
                BidderPersonMail:v.email, uuu_user_workphone:v.phone
            }))
        }

      

        // input 값이 변경될 때 호출되는 핸들러 함수
        const handleInputChange = (e, columnName, inputName) => {
          const { value } = e.target;
      
          // 현재 tableData를 복제한 뒤, 변경된 값만 업데이트합니다.
          setTableData((prevTableData) => ({
            ...prevTableData,
            [columnName]: {
              ...prevTableData[columnName],
              [inputName]: value,
            },
          }));


        };

        const handleSubmit = () => {
            Swal.fire({
                title: '제출하시겠습니까?',
                text: "이 작업은 되돌릴 수 없습니다!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '네! 제출합니다',
                cancelButtonText: '취소'
            }).then((result) => {
                if (result.isConfirmed) {
                    sendInfo(dataSend);
                    // 여기에 제출 로직을 추가하세요.
                }
            })
        }



        const sendInfo = (dataSend) => {    
            console.log(dataSend)
            axios.post('/updatevendor2', { dataSend })
            .then(response => {
                if (response.data.success) { // Assuming the API returns a `success` property in the data
                    Swal.fire({
                        title: '성공',
                        text: '성공적으로 제출되었습니다.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                      navigate('/home');
                  });
                } else {
                  Swal.fire({
                    title: 'Error',
                    text: response.data.data,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                }
            })
            .catch(err => {
                const errorMessage = err.message || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
                Swal.fire({
                    title: 'Error',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
      }; 


    return (
        <div className='app-content flex-column-fluid'>
            <div className='app-container container-xxl'>
                <div className="card mb-5 mb-xl-8">

                <div className="card-header border-0 pt-5">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold fs-3 mb-1">담당자 정보 업데이트</span>
                                                <span className="text-muted mt-1 fw-semibold fs-7">담당자가 나누어있지 않다면, 같은 정보를 넣으셔도 됩니다. <br/><span className='badge badge-light-danger fw-semibold me-1'>입찰 담당자의 이메일로 입찰 초청이 되니 유의바랍니다.</span></span>
											</h3>
											<div className="card-toolbar">
                                            <button className='btn btn-sm fw-bold btn-primary' type='button'  onClick={handleSubmit}>
                                                제출 &nbsp;&nbsp;
                                                <i className="ki-duotone ki-send fs-3">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>
                                            </button>
											</div>
										</div>


                    <div className="card-body py-3">
                    <div className="table-responsive design">
                         <table className="table align-middle gs-0 gy-4">
                         <thead>
                              <tr className="fw-bold text-muted bg-light">
                                   <th className="min-w-50px rounded-start">항목</th>
                                   <th className="min-w-80px">성명</th>
                                   <th className="min-w-100px">휴대폰 번호</th>
                                   <th className="min-w-150px rounded-end">Email</th>
                              </tr>
                         </thead>
                         <tbody>
                         {tableColumns.map((column, columnIndex) => (
                              <tr key={columnIndex}>
                              <td className="text-dark fw-bold text-hover-primary fs-6">{column.label}</td>
                           
                              {column.inputs.map((input, inputIndex) => (
                                   <td key={inputIndex} className="text-dark fw-bold text-hover-primary fs-6">
                                   <input
                                        type="text"
                                        className="form-control form-control-solid"
                                        placeholder={input.placeholder}
                                        name={input.name}
                                        style={{ maxWidth: input.maxWidth }}
                                        value={tableData[column.label]?.[input.name] || ''}
                                        onChange={(e) => handleInputChange(e, column.label, input.name)}
                                   />
                                   </td>
                              ))}
                            
                              </tr>
                         ))}
                         </tbody>
                         </table>
                    </div> 
                    </div>
                </div>
            </div>
        </div>
        ); 
};

