import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from '../common/axios';
import { Circles } from 'react-loader-spinner';

const checkSession = async () => {
  try {
    const response = await axios.post(
      `/is_logged_in`,
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.isLoggedIn;
  } catch (error) {
    console.error("Error verifying session:", error);
    return false;
  }
};

const PrivateRoute = () => {
  const [isValidSession, setIsValidSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const validity = await checkSession();
      setIsValidSession(validity);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="loadingContainer">
        <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  return isValidSession ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;