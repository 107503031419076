import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import dayjs from 'dayjs';
import { Dialog} from '@progress/kendo-react-dialogs';
import Swal from 'sweetalert2';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
  } from "@progress/kendo-react-intl";
  import krMessage from "./kr.json"
  loadMessages(krMessage, "ko-KR");

const initialDataState = {
	skip: 0,
	take: 10
  };

const ContractCheckPage = () => {
  const [data, setData] = useState({});
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [tableData, setTableData] = useState({});
  const [tableData2, setTableData2] = useState({});
  const [page, setPage] = React.useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState();

  const pageChange = event => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === 'All' ? tableData.details.length : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take
    });
  };



  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get('/contractconfirm'); 
          if(res.data.success){
            setData(res.data.data);
          }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);
  
  
	const handleCloseModal = () => {
		setVisibleDialog(false);
	  };

	    


	const handleButtonClick =  (e) => {
		console.log(e)
	setTableData({title:e.계약명, details:e.계약내역});
	setVisibleDialog(true);

	}



	const handleButtonAPIClick = (v) => {
		Swal.fire({
			title: '계약 수정 요청',
			text: "이 요청을 보내면 삼성중공업 담당자분에게 수정 요청이 접수됩니다.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '네, 보내기',
			cancelButtonText: '아니요, 취소'
		}).then((result) => {
			if (result.isConfirmed) {
				axios.get(`/udpatecontract1?record=${v.record}&project=${v.projectCode}`)
				.then(response => {
					if(response.data.success){
					Swal.fire({
						title: '성공',
						text: "요청이 성공적으로 접수되었습니다. 다시 검토 요청을 받기 전까지는 해당 계약 검토는 보이지 않습니다.",
						icon: 'success',
						confirmButtonText: '확인'
					}).then((result) => {
						if (result.isConfirmed) {
							// Navigate to /confirmContract
							window.location.href = "/confirmContract";
						}
					});}else {
						Swal.fire({
							title: '오류',
							text: "요청 처리 중 오류가 발생했습니다.",
							icon: 'error',
							confirmButtonText: '확인'
						});
					}
				})
				.catch(error => {
					// Handle any errors from the axios request
					Swal.fire({
						title: '오류',
						text: "요청 처리 중 오류가 발생했습니다.",
						icon: 'error',
						confirmButtonText: '확인'
					});
				});
			}
		});
	};
	
	
	
	const handleButtonAPIClick2 = (v) => {
	Swal.fire({
		title: '계약 진행 요청',
		text: "이 요청을 보내면 계약서 사인 요청이 시작됩니다.",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: '네, 보내기',
		cancelButtonText: '아니요, 취소'
	}).then((result) => {
		if (result.isConfirmed) {
		axios.get(`/udpatecontract2?record=${v.record}&project=${v.projectCode}`)
		.then(response => {
			if(response.data.success){
			Swal.fire({
				title: '성공',
				text: "요청이 성공적으로 접수되었습니다. 해당 계약은 전자서명으로 넘어갔으며 이 페이지에서는 더이상 요청된 레코드가 보이지 않습니다.",
				icon: 'success',
				confirmButtonText: '확인'
			}).then((result) => {
				if (result.isConfirmed) {
					// Navigate to /confirmContract
					window.location.href = "/confirmContract";
				}
			});}else {
				Swal.fire({
					title: '오류',
					text: "요청 처리 중 오류가 발생했습니다.",
					icon: 'error',
					confirmButtonText: '확인'
				});
			}
		})
		.catch(error => {
			// Handle any errors from the axios request
			Swal.fire({
				title: '오류',
				text: "요청 처리 중 오류가 발생했습니다.",
				icon: 'error',
				confirmButtonText: '확인'
			});
		});
		}
	});
	};

	
	  const handleDownload = (base64String, name) => {
		// base64 문자열을 Uint8Array로 변환
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
	  
		// Blob 객체 생성
		const blob = new Blob([byteArray], { type: 'application/octet-stream' });
	  
		// Blob 객체를 URL로 변환
		const url = window.URL.createObjectURL(blob);
	  
		// 'a' 태그를 생성하고 설정하여 파일을 다운로드
		const a = document.createElement('a');
		a.href = url;
		a.download = `${name}.zip`;  
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	  
		// URL 해제
		window.URL.revokeObjectURL(url);
	  }
  

  return(
	<>
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className="card mb-5 mb-xl-8">
			<div className="card-header border-0 pt-5">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold fs-3 mb-1">신규 계약 검토 요청</span>
					<span className="text-muted mt-1 fw-semibold fs-7">{data.length}번의 계약 검토 요청이 있습니다.</span>
				</h3>
				<div className="card-toolbar">
				</div>
			</div>
			<div className="card-body py-3">
				<div className="table-responsive">
					<StickyTable className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" borderWidth={0} leftStickyColumnCount={2}>
						<thead>
							<Row className="fw-bold text-muted" >

								<Cell className="min-w-120px">프로젝트 이름</Cell>
								<Cell className="min-w-120px">계약명</Cell>
								<Cell className="min-w-120px"></Cell>
								<Cell className="min-w-120px">하도급대금 연동여부</Cell>
								<Cell className="min-w-120px">계약금액(공급원가)</Cell>
								<Cell className="min-w-140px">선급금(%)</Cell>
								<Cell className="min-w-140px">부가세율</Cell>
								<Cell className="min-w-140px">월 지급횟수</Cell>
								<Cell className="min-w-140px">현금 비율 <br/>기업구매카드 비율</Cell>
								<Cell className="min-w-140px">기성지급기한</Cell>
								<Cell className="min-w-140px">하도급대금 지급보증금</Cell>
								<Cell className="min-w-140px">계약내역 보기</Cell>
								<Cell className="min-w-140px">계약 관련 파일</Cell>
								<Cell className="min-w-140px">산재 보험 및 지급보증 파일</Cell>
								
							</Row>
						</thead>
						<tbody>
							{data.length>0 &&  data.map((v, index)=>(
							<Row key={index}>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v.프로젝트명}</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v.계약명}</Cell>
								<Cell>
								<button className="btn btn-sm btn-light" style={{padding:5}} onClick={()=>handleButtonAPIClick(v)}>수정 요청</button> <br/>
								<button className="btn btn-sm btn-light" style={{padding:5}} onClick={()=>handleButtonAPIClick2(v)}>계약 진행 요청</button> 
								</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v["하도급대금 연동여부"]}</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v["계약금액(공급원가)"].toLocaleString()}원</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v["선급금(%)"]}%</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v.부가세율}%</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v["월 지급횟수"]}</Cell>
								<Cell>
								<span className="text-dark text-hover-primary fs-7 d-block">{(v["현금비율"])}%</span>
								<span className="text-muted text-hover-primary fs-7 d-block">{v["기업구매카드 비율"]}%</span>
								</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v["기성지급기한"]}</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">{v["하도급대금 지급보증금"].toLocaleString()}원</Cell>
								
								<Cell className="text-dark fw-bold text-hover-primary fs-6">
									<span
									onClick={(e) => {
										e.preventDefault();
										handleButtonClick(v);
									}}
									className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
									>
									<i className="ki-duotone  ki-book-square fs-3">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
									</i>
									</span>
								</Cell>
								<Cell className="text-dark fw-bold text-hover-primary fs-6">
									{v.계약관련첨부파일 !== undefined &&
									 	v.계약관련첨부파일.map((v)=>(
											<><span className="text-dark fw-bold text-hover-primary fs-6" style={{cursor:"pointer"}} onClick={() => handleDownload(v.file, v.filename)}>{v.filename}</span><br/></>
											))

									}
								</Cell>
								<Cell>
									{v.첨부파일 !== undefined &&
									v.첨부파일.map((v)=>(
									<><span className="text-dark fw-bold text-hover-primary fs-6" style={{cursor:"pointer"}} onClick={() => handleDownload(v.file, v.fileNickName)}>{v.fileNickName}</span><br/></>
									))
			
								}
								</Cell>

								{/* <Cell className="text-end">
								</Cell> */}


							</Row>
					))}
						</tbody>
					</StickyTable>
				</div>
			</div>
		</div>
    </div>
    </div>
	{visibleDialog &&
        <Dialog title={tableData.title + " 내역"}  width={1200} onClose={handleCloseModal}> 
		       <LocalizationProvider language="ko-KR">
            <IntlProvider locale="kr">
		<ExcelExport data={tableData.details} ref={_export}>
			<Grid data={tableData.details.slice(page.skip, page.take + page.skip)} onPageChange={pageChange}
			skip={page.skip} take={page.take} total={tableData.details.length}
			pageable={{
				buttonCount: 4,
				pageSizes: [5, 10, 15, 'All'],
				pageSizeValue: pageSizeValue
			  }}
			style={{maxHeight:620}} >
			<GridToolbar>
            <button title="Export Excel" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={excelExport}>
              엑셀 다운로드
            </button>
          </GridToolbar>
				<Column field="li_num" title="Id" width="80px"  />
				<Column field="SecCostCodeLv7" title="중공종" width="200px" />
				<Column field="SecCostCodeLv8" title="소공종" width="200px" />
				<Column field="spocItemName" title="품명" width="200px" />
				<Column field="spocSpec" title="규격" width="250px" />
				<Column field="ItemSpecDesc" title="품명/규격 상세" width="250px" />
				<Column field="spocUoMTxt" title="단위" width="80px" />
				<Column field="ItemQuantity" title="수량" width="100px" />
				<Column field="MainItem_srb" title="주요자재" width="100px" />
				<Column field="MatSupplyPD" title="사급자재/bulk" width="100px" />
				<Column field="ItemQuantity" title="Net수량" width="100px" />
				<Column field="spocMarginPerc" title="할증율(%)" width="100px" />
				<Column field="uuu_quantity" title="총수량" width="150px" />
				<Column field="MaterialCost" title="재료단가" width="150px" />
				<Column field="MaterialCostTotal" title="재료금액" width="150px" />
				<Column field="LaborCost" title="노무단가" width="150px" />
				<Column field="LaborCostTotal" title="노무금액" width="150px" />
				<Column field="Expense" title="경비단가" width="150px" />
				<Column field="ExpenseTotal" title="경비금액" width="150px" />
				<Column field="uuu_unit_price" title="합계단가" width="150px" />
				<Column field="amount" title="합계금액" width="180px" />
			</Grid>
		</ExcelExport>
		</IntlProvider>
          </LocalizationProvider>
		</Dialog>
	}
</>
)
};

export default ContractCheckPage;