import React, { useState, useEffect } from 'react';
import { Field } from '@progress/kendo-react-form';
import axios from '../common/axios';
import Swal from 'sweetalert2';
import { Button } from '@progress/kendo-react-buttons';
import Layout from '../layout/Layout';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FormCheckbox,FormCheckbox2, FormDropDownList, FormInput ,FormUpload, FormMultiSelect} from '../pages/newEvaluation/steps/forms';
import { CompanyNameValidator, TaxNumberValidator, fileValidator, infoValidator, requiredValidator} from '../login/multistep/validators';
import { Circles } from 'react-loader-spinner';
import dayjs from 'dayjs';

export const UpdateInfo = ({  }) => {

    const [data, setData] = useState({});

    const types = ["구매", "외주", "용역"];
    const types2 = ["설비", "전기", "정보통신", "건축", "금속","도장/방수","자동제어","비계", "탱크", "에어컨냉매배관","코킹"];
    const licenseTypes = ["토목공사",
                            "건축공사",
                            "토목건축공사",
                            "산업환경설비공사",
                            "조경공사",
                            "가스난방공사",
                            "구조물해체비계공사",
                            "금속창호지붕건축물조립공사",
                            "기계가스설비공사",
                            "도장습식방수석공사",
                            "상하수도설비공사",
                            "수중준설공사",
                            "승강기삭도공사",
                            "실내건축공사",
                            "조경식재시설물공사",
                            "지반조성포장공사",
                            "철강구조물공사",
                            "철근콘크리트공사",
                            "철도궤도공사",
                            "전기공사",
                            "통신공사"]
    const types3 = ["종합건설업", "전문건설업", "N/A"];
    const navigate = useNavigate(); // 변경된 부분

    const [selectedValue, setselectedValue]=React.useState(data.업체유형)
    const onChange = (event)=>{setselectedValue(event.target.value)}

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get('/getvendor'); 
            if(res.data.success){
              setData(res.data.data);
              setLoading(false);
              setselectedValue(res.data.data.업체유형)
            }
           
          } catch (error) {
            console.error('데이터 가져오기 중 오류 발생:', error);
            setLoading(false);
          }
        };
        fetchData();
      }, []);

    const onStepSubmit = React.useCallback(event => {
        const { values} = event;

        const dataSend = {
            record_no: data.record_no,
            uveVendorNameTB50:values.법인명,
            ugenAddTXT250:values.본점소재지,
            VendorEVAType2:values.업체유형,
            VendorCreditGrade:values.신용평가등급,
            VendorCashGrade:values.현금흐름등급,
            ConstructionAbility:values.시공능력평가액,
            VendorDisciplinePD:values.업체유형 === "외주"?(values.참여공종 && values.참여공종.length === 1 ? values.참여공종[0]:values.대표공종 && values.대표공종):null,
            VendorDisciplineMS:values.업체유형 === "외주"?values.참여공종 && values.참여공종.map(v=>v).join(","):null,
            VendorLicense:values.업체유형 === "외주"?values.보유면허 && values.보유면허.map(v=>v).join(","):null,
            CEQCategoryPD:values.종합전문구분,
            _bp_lineitems: [{uuu_tab_id:"업데이트 이력", UpdateTime:dayjs().format("YYYY년 MM월 DD일 HH:mm:ss 에 업데이트") ,UpdateDetail:"업체 정보 업데이트"}]
        } 

            Swal.fire({
                title: '제출하시겠습니까?',
                text: "이 작업은 되돌릴 수 없습니다!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '예',
                cancelButtonText: '아니오'
            }).then((result) => {
                if (result.isConfirmed) {
                    sendInfo(dataSend); 
                }
            });
      })

      const sendInfo = (dataSend) => {    
            axios.post("/updatevendor1", { dataSend })
            .then(response => {
                if (response.data.success) { // Assuming the API returns a `success` property in the data
                    Swal.fire({
                        title: '성공',
                        text: '성공적으로 제출되었습니다.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    navigate('/home');
                  });
                } else {
                  Swal.fire({
                    title: 'Error',
                    text: response.data.data,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                }
            })
            .catch(err => {
                const errorMessage = err.message || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
                Swal.fire({
                    title: 'Error',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
      }; 

      const [selectedValue2, setselectedValue2]=React.useState([])
      const onChange2 = (event)=>{setselectedValue2(event.target.value)}

      if (loading) {
        return <div className="loadingContainer">
        <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
      </div>;;
      }
    return (
        <div className='app-content flex-column-fluid'>
            <div className='app-container container-xxl'>
                <div className="card mb-5 mb-xl-8">
                    <div className="card-header border-0 pt-5">
                    <h2 className="fw-bold d-flex align-items-center text-dark">정보 업데이트
                        <span className="ms-1" data-bs-toggle="tooltip" title="회원 가입 시에 넣은 정보를 보여줍니다.">
                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                            </i>
                        </span></h2>
                    </div>
                    <div className="card-body py-3">
                    <Form className="my-auto pb-5" initialValues={data} onSubmitClick={onStepSubmit} render={formRenderProps => <div>
                
                    <FormElement>
                    <Field key={'법인명'} id={'법인명'} name={"법인명"} label={'법인명'} component={FormInput} validator={CompanyNameValidator} />
                    <Field key={'사업자등록번호'} id={'사업자등록번호'} name={"사업자등록번호"}  label={'사업자등록번호'} valid={false} disabled={true} component={FormInput}  />
                    <Field key={'본점소재지'} id={'본점소재지'} name={"본점소재지"} label={'본점소재지'} component={FormInput} validator={requiredValidator} />
                    <Field key={'업체유형'} id={'업체유형'} name={"업체유형"} data={types}label={'업체 유형'} component={FormDropDownList} validator={requiredValidator}  onChange={onChange}/>
                    <Field key={'신용평가등급'} id={'신용평가등급'} name={"신용평가등급"} data={types}label={'신용평가등급'} component={FormInput} validator={requiredValidator}  />
                    <Field key={'현금흐름등급'} id={'현금흐름등급'} name={"현금흐름등급"} data={types}label={'현금흐름등급'} component={FormInput} validator={requiredValidator} />
                    <Field key={'시공능력평가액'} id={'시공능력평가액'} name={"시공능력평가액"} data={types}label={'시공능력 평가액'} component={FormInput} validator={requiredValidator} />
                    
                    {selectedValue === '외주'&&
                    <>
                    <Field key={'참여공종'} id={'참여공종'} name={"참여공종"} label={'참여 공종'} component={FormMultiSelect} data={types2} validator={requiredValidator} onChange={onChange2}/>
                    <Field key={'보유면허'} id={'보유면허'} name={"보유면허"} label={'보유 면허'} component={FormMultiSelect} data={licenseTypes} validator={requiredValidator}/>
                    </>
                    }
                    {selectedValue2.length>1 && 
                        <Field key={'대표공종'} id={'대표공종'} name={"대표공종"} label={'대표 공종'} component={FormDropDownList} data={selectedValue2} validator={requiredValidator} />
                        }


                    <Field key={'종합전문구분'} id={'종합전문구분'} name={"종합전문구분"} label={'종합/전문건설업 구분'} component={FormDropDownList} data={types3} validator={requiredValidator} />
                    <div className="d-flex flex-stack pt-15">
                                <div className="mr-2">
                                </div>
                                <div className="d-flex flex-stack pt-15" style={{ justifyContent: 'center' }}>
                                    <Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> {"제출"}</Button>
                                </div>
                            </div>
                    </FormElement>
                    </div>} />
                    </div>
                </div>
            </div>
        </div>
        ); 
};

