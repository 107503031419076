import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, FormElement, Field, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload';
import dayjs from 'dayjs';
import { Button } from '@progress/kendo-react-buttons';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
} from "@progress/kendo-react-intl";
import krMessage2 from '../login/multistep/kr.json';
import Swal from 'sweetalert2';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import _ from 'lodash';

loadMessages(krMessage2, "ko-KR");


const uploadRef = React.createRef();

const FormUpload = fieldRenderProps => {
	const {
		value,
		id,
		optional,
		label,
		hint,
		validationMessage,
		touched,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';
	const onChangeHandler = event => {
		fieldRenderProps.onChange({
			value: event.newState
		});
	};
	const onRemoveHandler = event => {
		fieldRenderProps.onChange({
			value: event.newState
		});
	};

	const display = fieldRenderProps.value && fieldRenderProps.value.length > 0 ? "none" : 'block';

	return <FieldWrapper>
		<Label id={labelId} editorId={id} optional={optional} className='k-form-label'>
			{label}
		</Label>
		<LocalizationProvider language="ko-KR">
			<IntlProvider locale="kr">
				<div className={'k-form-field-wrap'}>
					<Upload ref={uploadRef}
						// restrictions={{
						//     allowedExtensions: [".pdf"],
						//   }} 
						id={id} autoUpload={false} showActionButtons={false} multiple={true} files={value} onAdd={onChangeHandler} onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />
					{showHint && <Hint id={hintId}>{hint}</Hint>}
					{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
				</div>
				<div
					style={{
						height: "10px",
					}}></div>
				<div style={{ display: display }}>
					<ExternalDropZone uploadRef={uploadRef} />
				</div>
			</IntlProvider>
		</LocalizationProvider>
	</FieldWrapper>;
};


const SafetyPage = () => {
	const [data, setData] = useState([]);
	const [visibleDialog, setVisibleDialog] = useState(false);
	const [visibleDialog2, setVisibleDialog2] = useState(false);
	const [currentBond, setCurrentBond] = useState(null);
	const [current, setCurrent] = useState(null);


	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get('/getsafetyList');
				if (res.data.success) {
					setData(res.data.data);
				}

			} catch (error) {
				console.error('데이터 가져오기 중 오류 발생:', error);
			}
		};
		fetchData();
	}, []);

	const handleButtonClick = (e) => {
		setVisibleDialog(true);
		
		setCurrentBond(e)

	};

	const handleCloseModal = () => {
		setVisibleDialog(false);
		setCurrentBond(null)
	};

	const handleButtonClick2 = (e) => {
		setVisibleDialog2(true);
		setCurrent(e)

	};

	const handleCloseModal2 = () => {
		setVisibleDialog2(false);
		setCurrent(null)
	};


	const onStepSubmit = React.useCallback(event => {

		const affectedFiles = event.values[currentBond.record]
		const updatedState = [];

		affectedFiles.forEach((file) => {
			const cc = file.getRawFile();
			const filetype = file.extension;

			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};
				updatedState.push(updatedFile);

				if (updatedState.length === affectedFiles.length) {
					handleCloseModal();
					Swal.fire({
						title: '확인 요청?',
						text: "입력한 정보를 정말 제출하시겠습니까?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: '예',
						cancelButtonText: '아니오'
					}).then((result) => {
						if (result.isConfirmed) {
							sendInfo(updatedState);
						}
					});
				}
			};
			reader.readAsDataURL(cc);

		});

	})


	const sendInfo = (e) => {
		axios.post("/updatewithattach",
			{
				bpname: "Safety Eligibility Review",
				project: currentBond.projectCode,
				record: currentBond.record,
				workflow_details: {
					"WFCurrentStepName": "협력업체 자료 제출",
					"WFActionName": "자료 제출",
				},
				files: e
			})
			.then(response => {
				if (response.data.success) {
					Swal.fire({
						title: 'Success',
						text: '성공적으로 제출되었습니다.',
						icon: 'success',
						confirmButtonText: 'OK'
					})
						.then(() => {
							window.location.reload();
						});
				} else {
					let errorMessage = response.data.error || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
					Swal.fire({
						title: 'Error',
						text: errorMessage,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}
			})
			.catch(err => {
				let errorMessage = '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
				if (err.response && err.response.data && err.response.data.error) {
					errorMessage += ' ' + err.response.data.error;
				}
				Swal.fire({
					title: 'Error',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	}

	const CustomCell = props => {
		// 줄바꿈 문자를 <br /> 태그로 변환
		const point = "(" + props.dataItem.SEREvalPoint + ")";

		const content = props.dataItem[props.field].split('\n').map((item, key) => {
			return <span style={{ fontWeight: 500, color: item.includes(point) ? "red" : "black" }} key={key}>{item}<br /></span>
		});

		return (
			<td style={{ borderBottom: "1px solid #F1F1F2" }}>
				{content}
			</td>
		);
	};

	const Cell = props => {
		const field = props.field || "";
		const value = props.dataItem[field]
		return (
			<td style={{ borderBottom: "1px solid #F1F1F2" }}>
				{value}
			</td>
		);
	};

	const CreditCell = (props) => {
		const field = props.field || "";
		const sum = _.sumBy(current.lineitems, field);
		return (
			<td colSpan={props.colSpan}>
				{sum}
			</td>
		);
	};


	return (
		<>
			<div className='app-content flex-column-fluid'>
				<div className='app-container container-xxl'>

					<div className="card mb-5 mb-xl-8">
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label fw-bold fs-3 mb-1">안전 적격성 평가 자료 업로드</span>
								<span className="text-muted mt-1 fw-semibold fs-7">{data.filter(v => v.status !== "평가 완료").length}건의 입찰을 위해 안전 적격성 평가 자료를 업로드해야합니다.최신 적격성 평가 양식은 Home 공지사항에 있으니 다운로드 받으시기 바랍니다.</span>
							</h3>
							<div className="card-toolbar">
							</div>
						</div>
						<div className="card-body py-3">
							<div className="table-responsive">
								<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
									<thead>
										<tr className="fw-bold text-muted">

											<th className="min-w-100px">프로젝트 이름</th>
											<th className="min-w-250px">입찰명</th>
											<th className="min-w-150px">상태</th>
											<th className="min-w-180px">계약예정일</th>
											<th className="min-w-80px">평가결과</th>
											<th className="min-w-80px">평가점수</th>
											<th className="min-w-80px">평가결과 세부</th>
											<th className="min-w-250px">안전팀 코멘트</th>

										</tr>
									</thead>
									<tbody>
										{data && data.map((v, index) => (
											<tr key={index}>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.projectName}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.title}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6"><span style={{ color: v.status === "자료 제출 필요" ? "#CD212A" : v.status === "자료 보완 필요" ? "#FF6F61" : "black" }}>{v.status}</span></td>
												<td className="text-dark text-hover-primary fs-8">{v.ContractForecastDate && dayjs(v.ContractForecastDate).format("YYYY년 MM월 DD일")}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.SEREvalTotal}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.SEREvalResult}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 0 }}>

													<span onClick={(e) => { e.preventDefault(); handleButtonClick2(v); }} className="btn-active-color-primary" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
														<i className="ki-duotone ki-magnifier fs-3">
															<span className="path1"></span>
															<span className="path2"></span>
														</i>
													</span>

												</td>

												<td className="text-dark fw-bold text-hover-primary fs-6">{v.SERComment}</td>
												{v.status === "평가 완료" ? "" :
													<td className="text-end">
														<span onClick={(e) => { e.preventDefault(); handleButtonClick(v); }} className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
															<i className="ki-duotone ki-folder-up ">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
													</td>
												}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			{visibleDialog &&
				<Dialog title={currentBond.title + " 안전 적격성 평가 자료 업로드"} width={600} onClose={handleCloseModal}>
					<Form className="my-auto pb-5" onSubmitClick={onStepSubmit} render={formRenderProps => <div>
						{/* 스텝 바디 */}
						<FormElement>
							<Field
								key={currentBond.record} className="attach"
								id={currentBond.record}
								name={currentBond.record}
								label={'안전적격성 평가 파일 첨부'}
								// hint={`파일을 pdf로 올리세요.${currentBond.보증필요리스트2.join(";")}을 한번에 첨부하세요.`} 
								component={FormUpload}
							/>
							<div className="d-flex flex-stack pt-15">
								<div>
									<Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> 업로드
										<i className="ki-duotone ki-arrow-right fs-4 ms-1">
											<span className="path1"></span>
											<span className="path2"></span>
										</i></Button>
								</div>
							</div>
						</FormElement>
					</div>} />

				</Dialog>
			}
			{visibleDialog2 &&
				<Dialog title={current.title + " 평가결과 세부항목"} width={1000} onClose={handleCloseModal2}>
					<Grid className='detailTable' data={current.lineitems} style={{ height: '600px' }} >
						<GridColumn field="SERItemPD" title="항목" width={150} cell={Cell} />
						<GridColumn field="SERItemDetailPD" title="세부항목" cell={Cell} />
						<GridColumn field="SERCriteriaMT" title="기준 상세설명" width={400} cell={CustomCell} />
						<GridColumn field="SEREvalPoint" title="평가점수" footerCell={CreditCell} cell={Cell} />
						<GridColumn field="SERRemark" title="비고" cell={Cell} />

					</Grid>

				</Dialog>
			}
		</>
	)
};

export default SafetyPage;