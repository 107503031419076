import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../style.bundle.css';
import '../../plugins.bundle.css';

export default function ConfirmPW() {

  return (
    <>
      <div style={{ backgroundImage: 'url(/images/bg6.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }} data-bs-theme="light">
        <div className="d-flex flex-column flex-root" data-bs-theme="light" style={{ minHeight: "100vh" }}>
          <div className="d-flex flex-column flex-center flex-column-fluid">
            <div className="d-flex flex-column flex-center text-center p-10">
              <div className="card card-flush w-lg-650px py-5">
                <div className="card-body py-15 py-lg-20">
                  <div className="mb-14">
                    <a href="http://www.samsungshi.com/kor/default.aspx">
                      <img alt="Logo" src='/images/g1313.png' className="h-40px" />
                    </a>
                  </div>
                  <h1 className="fw-bolder text-gray-900 mb-5">비밀번호가 변경되었습니다.</h1>
                  <div className="fs-6 fw-semibold text-gray-500 mb-10">
                  </div>
                  <div className="mb-11">
                    <RouterLink className="btn btn-sm btn-primary" to="/login">로그인</RouterLink>
                  </div>
                  <div className="mb-0">
                    <img src="/images/ok.png" className="mw-100 mh-300px theme-light-show" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}