import { atom } from 'recoil';

export const formStateAtom2 = atom({
  key: 'formStateAtom2',
  default: {
    삼성중공업근무이력: [],
    삼성중공업하도이력: [],
    대표자경력: [],
    contact: [], 
  },
});
