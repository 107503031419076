import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import { Grid, GridColumn as Column, GridToolbar ,GRID_COL_INDEX_ATTRIBUTE} from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import _ from "lodash";
import { Link as RouterLink} from 'react-router-dom';
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import krMessage from "./kr.json"
loadMessages(krMessage, "ko-KR");

const HomePage = () => {
  const [data, setData] = useState({});
  const [homeData, setHomeData] = useState([]);
	const [countData, setCountData] = useState({});
  const initialDataState = {
    skip: 0,
    take: 10
    };


  const [page, setPage] = React.useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState();

  const pageChange = event => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === 'All' ? homeData.length : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take
    });
  };
  useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get('/checkPE'); 
          if(res.data.success){
            setData(res.data);
          }
         
        } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
        }
      };
      fetchData();
    }, []);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/gethome'); 
        if(res.data.success){
          setHomeData(res.data.data);
        }
        
      } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/getcount'); 
        if(res.data.success){
          setCountData(res.data.data);
        }
        
      } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
      }
    };
    fetchData();
  }, []);
  
    const CustomCell = (props) => {
      const field = props.field || "";
      const value = props.dataItem[field];
      const navigationAttributes = useTableKeyboardNavigation(props.id);
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
         
        >
          	<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"  onClick={() => handleDownload(value)}>
            <i className="ki-duotone ki-file-down  fs-2x">
            <span className="path1"></span>
            <span className="path2"></span>
            </i>
            </div>
        </td>
      );
    };

    const handleDownload = (base64String) => {
      // base64 문자열을 Uint8Array로 변환
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
    
      // Blob 객체 생성
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    
      // Blob 객체를 URL로 변환
      const url = window.URL.createObjectURL(blob);
    
      // 'a' 태그를 생성하고 설정하여 파일을 다운로드
      const a = document.createElement('a');
      a.href = url;
      a.download = 'download.zip';  
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    
      // URL 해제
      window.URL.revokeObjectURL(url);
    }


    const DetailComponent = props => {
      const data = props.dataItem;
      const htmlString = data.description;
      return <section>
       <p dangerouslySetInnerHTML={{ __html: htmlString }} />
      
    </section>;
    };

    const expandChange = event => {
      let newData = homeData.map(item => {
        if (item.record === event.dataItem.record) {
          item.expanded = !event.dataItem.expanded;
        }
        return item;
      });
      setHomeData(newData);
    };

    const sum = Object.values(countData).reduce((total, value) => total + value, 0);


  return(
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>

        <div className= {data.status === -1 ?"notice d-flex bg-light-danger rounded border-danger border border-dashed p-6":data.status === -1?"notice d-flex bg-light-warning rounded border-warning border border-dashed p-6":"notice d-flex bg-light-success rounded border-success border border-dashed p-6"}>
            <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
            </i>
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-semibold">
                    <h4 className="text-gray-900 fw-bold">신규 평가 상태</h4>
                    <div className="fs-6 text-gray-700">{data.message}
                </div>
                </div>
            </div>
        </div>

        {sum >0 &&
        <div className= "notice d-flex bg-light-danger rounded border-danger border border-dashed p-6" style={{marginTop:"0.8rem"}}>
            <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
            </i>
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-semibold">
                    {/* <h4 className="text-gray-900 fw-bold">신규 평가 상태</h4> */}
                    {countData && countData.signing &&countData.signing !==0?<div className="fs-6 text-gray-700">전자 서명이 {countData.signing}건 있습니다. 이메일을 확인하세요.
                    </div>:""}
                    {countData && countData.bond &&countData.bond !==0?<div className="fs-6 text-gray-700">보증보험을 {countData.bond}건의 계약으로 업로드해야합니다.
                    <RouterLink  to= "/log-bond"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}

                    {countData && countData.sitemeeting &&countData.sitemeetidng !==0?<div className="fs-6 text-gray-700">현장설명회 초청을 {countData.sitemeeting}회 받았습니다. 참석 여부를 알려주세요.
                    <RouterLink  to= "/log-sitemeeting"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}

                    {countData && countData.check &&countData.check !==0?<div className="fs-6 text-gray-700">신규 계약 {countData.check}건을 검토해야합니다.
                    <RouterLink  to= "/confirmContract"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}
                    {countData && countData.check2 &&countData.check2 !==0?<div className="fs-6 text-gray-700">변경 계약 {countData.check2}건을 검토해야합니다.
                    <RouterLink  to= "/confirmCO"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}
                    {countData && countData.agreement &&countData.agreement !==0?<div className="fs-6 text-gray-700">작업합의서 {countData.agreement}건을 검토해야합니다.
                    <RouterLink  to= "/log-agreement"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}
                    {countData && countData.paperTax &&countData.paperTax !==0? <div className="fs-6 text-gray-700">인지세 {countData.paperTax}건을 납부해야합니다.
                    <RouterLink  to= "/log-stamp"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}
                    {countData && countData.safety &&countData.safety !==0? <div className="fs-6 text-gray-700">안전 적격성 평가 {countData.safety}건을 제출해야합니다.
                    <RouterLink  to= "/log-safety"  type="button" className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
																	<i className="ki-duotone ki-exit-right-corner fs-2">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i> 
										</RouterLink>
                    </div>:""}
                </div>
            </div>
        </div>}

       <div className='app-toolbar py-3 py-lg-6'>
       <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>공지사항</h1>
        </div> 
       </div>
       <LocalizationProvider language="ko-KR">
            <IntlProvider locale="kr">
        <Grid detail={DetailComponent} expandField="expanded" onExpandChange={expandChange} data={homeData.slice(page.skip, page.take + page.skip)} onPageChange={pageChange}
			skip={page.skip} take={page.take} total={homeData.length}
			pageable={{
				buttonCount: 4,
				pageSizes: [5, 10, 15, 'All'],
				pageSizeValue: pageSizeValue
			  }}
			style={{maxHeight:620}} >

				<Column field="title" title="제목" width="250px"  />
				<Column field="date" title="공지일" width="200px"  />
				<Column field="creator" title="작성자" width="200px"  />
				<Column field="attachmentList" title="첨부파일 리스트" width="350px"  />
				<Column field="zipFile" title="다운로드" width="200px"  cell={CustomCell} />

			</Grid>
      </IntlProvider>
          </LocalizationProvider>
    </div>
    </div>

 
)};

export default HomePage;