import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style.bundle.css';
import './plugins.bundle.css';
import './custom.css';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import SignIn from './login/login/login';
import { Signup } from './login/signup';
import '@progress/kendo-theme-default/dist/default-ocean-blue-a11y.scss';
import { RecoilRoot } from 'recoil';
import { BusinessRegister } from './login/businessRegister';
import ResetPW from './login/login/resetpw';
import NewPW from './login/login/newpw';
import ConfirmPW from './login/login/pwconfirm';
import HomePage from './pages/home';
import PrivateRoute from './common/privateRoute';
import Profile from './pages/profile';
import newEvaluation from './pages/newEvaluation/newEvaluation';
import MakeEvaluation from './pages/newEvaluation/makeEvaluation';
import BiddingPage from './pages/biddingLog';
import ContractPage from './pages/contractLog';
import SigningPage from './pages/signingLog';
import BondPage from './pages/bondLog';
import StampTaxPage from './pages/StampTaxLog';
import PaymentPage from './pages/paymentLog';
import InvitationPage from './pages/InvitationLog';
import { Navigate } from 'react-router-dom';
import ContractCheckPage from './pages/checkContract';
import NewPaymentPage from './pages/newPA';
import AgreemntPage from './pages/Agreement';
import COPage from './pages/ChangeOrderLog';
import { UpdateInfo } from './pages/updateInfo';
import { UpdateContact } from './pages/updateInfo2';
import { UpdateFile } from './pages/updateInfo3';
import SafetyPage from './pages/safeyLog';
import SafetyPage2 from './pages/safeyLog2';
import TaxPage from './pages/TaxLog';
import Material from './material/material';
import { LicenseInfo } from '@mui/x-license-pro';
import Layout from './layout/Layout';
import ModifyPaymentPage from './pages/modiPA';

document.title = "삼성중공업 - 협력업체포탈";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<RecoilRoot>
  <BrowserRouter>
    <Routes>
      <Route path="/register" element={<BusinessRegister />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPW />} />
      <Route path="/password-confirmation" element={<ConfirmPW />} />
      <Route path="/new-password" element={<NewPW />} />
      <Route path="/login" element={<SignIn />} />

      <Route path="/" element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/material" element={<Material />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/new-evaluation" element={<newEvaluation />} />
          <Route path="/log-bidding" element={<BiddingPage />} />
          <Route path="/log-contract" element={<ContractPage />} />
          <Route path="/log-signing" element={<SigningPage />} />
          <Route path="/log-bond" element={<BondPage />} />
          <Route path="/log-stamp" element={<StampTaxPage />} />
          <Route path="/log-payment" element={<PaymentPage />} />
          <Route path="/log-sitemeeting" element={<InvitationPage />} />
          <Route path="/make-evaluation" element={<MakeEvaluation />} />
          <Route path="/log-agreement" element={<AgreemntPage />} />
          <Route path="/confirmCO" element={<COPage />} />
          <Route path="/createNewPA/:project/:record/:title/:number" element={<NewPaymentPage />} />
          <Route path="/modifiyPA/:project/:record/:title/:number" element={<ModifyPaymentPage />} />
          <Route path="/confirmContract" element={<ContractCheckPage />} />
          <Route path="/updateinfo" element={<UpdateInfo />} />
          <Route path="/updatecontact" element={<UpdateContact />} />
          <Route path="/updatefile" element={<UpdateFile />} />
          <Route path="/log-safety" element={<SafetyPage />} />
          <Route path="/log-safety2" element={<SafetyPage2 />} />
          <Route path="/log-tax" element={<TaxPage />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
</RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();