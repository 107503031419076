import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormCheckbox, FormDropDownList, FormInput, FormUpload } from './forms';
import { CompanyNameValidator, TaxNumberValidator, infoValidator, requiredValidator,fileValidator2 } from '../../../login/multistep/validators';
import { useRecoilValue } from 'recoil';
import { businessType } from '../../../atom/businessType';
import { formStateAtom2 } from '../../../atom/formState2';

export const BasicInfo = ({ }) => {
     const types = ["구매", "외주", "용역"];
     const types2 = ["설비", "전기", "정보통신", "건축", "금속", "도장/방수", "자동제어", "비계", "탱크", "에어컨냉매배관", "코킹"];
     const types3 = ["종합건설업", "전문건설업", "N/A"];

     const formState = useRecoilValue(formStateAtom2);

     const business = useRecoilValue(businessType)

     const [selectedValue, setselectedValue] = React.useState(business)
     const onChange = (event) => { setselectedValue(event.target.value) }

     const [valid, setValid] = React.useState(false);
     const [valid2, setValid2] = React.useState(false);
     const [valid3, setValid3] = React.useState(false);
     const [valid4, setValid4] = React.useState(false);


     const businessTypeValidator = (value) => {
          if (!value) {
            return "업체 유형은 필수 선택 항목입니다.";
          }
          if (value !== business) {
            return "선택한 업체 유형이 기존에 등록된 유형과 일치하지 않습니다.";
          }
          return undefined;
        };


     React.useEffect(() => {
          if (formState.준법) {
               setValid3(formState.준법.length ===1 ? true : false)
          }

          if (formState.개인정보동의) {
               setValid(true)
          }

          if (formState.개인정보동의2) {
               setValid2(true)
          }
     }, [formState]);

     const defaultFiles = formState.준법 && formState.준법.map((v) => ({ name: v.name }))

     const handleValidChange = (newValid) => {
          setValid(newValid);
     };

     const handleValidChange2 = (newValid) => {
          setValid2(newValid);
     };

     const handleValidChange3 = (newValid) => {
          setValid3(newValid);
     };

     const handleValidChange4 = (newValid) => {
          setValid4(newValid);
     };


     return (
          <div className="current" data-kt-stepper-element="content">
               <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                         <h2 className="fw-bold d-flex align-items-center text-dark">기본 정보 확인
                              <span className="ms-1" data-bs-toggle="tooltip" title="회원 가입 시에 넣은 정보를 보여줍니다.">
                                   <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                   </i>
                              </span></h2>
                    </div>
                    <Field key={'법인명'} id={'법인명'} name={"법인명"} label={'법인명'} component={FormInput} validator={CompanyNameValidator} />
                    <Field key={'사업자등록번호'} id={'사업자등록번호'} name={"사업자등록번호"} label={'사업자등록번호'} component={FormInput} validator={TaxNumberValidator} />
                    <Field key={'본점소재지'} id={'본점소재지'} name={"본점소재지"} label={'본점소재지'} component={FormInput} validator={requiredValidator} />
                    <Field key={'업체유형'} id={'업체유형'} name={"업체유형"} data={types} label={'업체 유형'} component={FormDropDownList} validator={businessTypeValidator} onChange={onChange} />

                    {selectedValue === '외주' &&
                         <>
                              <Field key={'대표공종'} id={'대표공종'} name={"대표공종"} label={'대표 공종'} component={FormDropDownList} data={types2} validator={requiredValidator} />
                              <Field key={'종합전문구분'} id={'종합전문구분'} name={"종합전문구분"} label={'종합/전문건설업 구분'} component={FormDropDownList} data={types3} validator={requiredValidator} />
                         </>
                    }

                    <Field
                         key={'준법'} className="attach"
                         id={'준법'}
                         name={'준법'}
                         label={'준법 서약 및 점검'}
                         multipe={false}
                         
                         //  defaultFiles = {defaultFiles}
                         hint={'아래 링크를 통해 파일을 다운로드 후 설문하시고 파일을 다시 첨부하시기 바랍니다.'}
                         component={FormUpload} onValidChange={handleValidChange4} validator={(value) => fileValidator2(valid3)} infotype={"대표자"}
                    />
                    <a href="/download/Compliance Pledge and Compliance Questionnaire.docx" download="준법 서약서 및 준법설문.docx">준법 서약서 및 준법 설문 다운로드</a>


                    <Field key={'개인정보동의'} id={'개인정보동의'} name={"개인정보동의"} label={'대표자 개인 정보 수집 및 이용 동의'} onValidChange={handleValidChange} component={FormCheckbox}
                         validator={(value) => infoValidator(value, valid)} infotype={"대표자"} />
                    <Field key={'개인정보동의2'} id={'개인정보동의2'} name={"개인정보동의2"} label={'임직원 정보 수집 및 이용 동의'} onValidChange={handleValidChange2} component={FormCheckbox}
                         validator={(value) => infoValidator(value, valid2)} infotype={"임직원"}
                    />


               </div>
          </div>
     );
};
