import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormDropDownList } from './forms';
import { workHistoryValidator2, workHistoryValidator,representativeHistoryValidator } from '../../../login/multistep/validators';
import { formStateAtom2 } from '../../../atom/formState2';
import { selectedValue1, selectedValue2 } from '../../../atom/selectedvalue';
import { useRecoilState } from 'recoil';
import { FormTable } from './formtable/formtable';
import dayjs from 'dayjs';


export const RPInfo = ({ }) => {
     const [selectedValue11, setSelectedValue] = useRecoilState(selectedValue1);
     const [selectedValue22, setSelectedValue2] = useRecoilState(selectedValue2);


     const [workhistroyvalid, setworkhistroyvalid] = React.useState(false)
     const [workhistroyvalid2, setworkhistroyvalid2] = React.useState(false)
     const onChange = (event) => { setSelectedValue(event.target.value) }
     const onChange2 = (event) => { setSelectedValue2(event.target.value) }

     const [formState, setFormState] = useRecoilState(formStateAtom2);

     const isValidDate = (dateValue) => {
          console.log(dateValue)
          // dateValue가 Date 객체인지 확인
          if (dateValue instanceof Date) {
            // Date 객체를 YYYY-MM-DD 형식의 문자열로 변환
            const dateString = dayjs(dateValue).format("YYYY-MM-DD");
                   
            const regex = /^(19|20)\d{2}-\d{2}-\d{2}$/;
            if (!regex.test(dateString)) return false;
        
            // 날짜가 유효한지 확인 (이미 Date 객체이므로 항상 true)
            if (isNaN(dateValue.getTime())) return false;
        
            // 년, 월, 일이 입력된 값과 일치하는지 확인
            const [year, month, day] = dateString.split('-').map(Number);
            return (
              dateValue.getFullYear() === year &&
              dateValue.getMonth() + 1 === month &&
              dateValue.getDate() === day
            );
          } else if (typeof dateValue === 'string') {
            // 기존의 문자열 처리 로직
            const regex = /^(19|20)\d{2}-\d{2}-\d{2}$/;
            if (!regex.test(dayjs(dateValue).format("YYYY-MM-DD"))) return false;
        
            const date = new Date(dateValue);
            if (!(date instanceof Date) || isNaN(date)) return false;
        
            const [year, month, day] = dayjs(dateValue).format("YYYY-MM-DD").split('-').map(Number);
            return (
              date.getFullYear() === year &&
              date.getMonth() + 1 === month &&
              date.getDate() === day
            );
          }
        
          // dateValue가 Date 객체도 아니고 문자열도 아닌 경우
          return false;
        };

     const validateTableData = (data, requiredFields) => {
      
          return data.every(row =>
               requiredFields.every(field =>
                    row[field] && (field.includes('date') ? isValidDate(row[field]) : true)
               )
          );
     };

     const validateTableData2 = (data, requiredFields) => {
          if (data.length === 0) return true;
          return data.every(row =>
            requiredFields.every(field =>
              row[field] && (field.includes('date') ? isValidDate(row[field]) : true)
            )
          );
        };

     const [isValidMansInfo, setIsValidMansInfo] = React.useState(true);
     const [isValidHadoHistory, setIsValidHadoHistory] = React.useState(true);
     const [isValidRepresentativeHistory, setIsValidRepresentativeHistory] = React.useState(true);

     console.log(isValidMansInfo)

     React.useEffect(() => {
          setIsValidMansInfo(validateTableData(formState.삼성중공업근무이력, ['name', 'date']));
     }, [formState.삼성중공업근무이력]);


     React.useEffect(() => {
          setIsValidHadoHistory(validateTableData(formState.삼성중공업하도이력, ['name', 'date1', 'date2']));
     }, [formState.삼성중공업하도이력]);

     React.useEffect(() => {
          setIsValidRepresentativeHistory(validateTableData2(formState.대표자경력, ['name', 'date1', 'date2']));
     }, [formState.대표자경력]);

     React.useEffect(() => {
          if (formState.삼성중공업근무이력.length > 0) {
               setSelectedValue("예");
          }
     }, []);



     React.useEffect(() => {
          if (formState) {
               setworkhistroyvalid(selectedValue11 === "예" && formState.삼성중공업근무이력.length > 0 && (formState.삼성중공업근무이력[0].name !== undefined) ? true
                    : selectedValue11 === "아니오" && formState.삼성중공업근무이력.length === 0 ?
                         true : false)
          }
     }, [formState, selectedValue11]);

     React.useEffect(() => {
          if (formState.삼성중공업하도이력.length > 0) {
               setSelectedValue2("예");
          }
     }, []);

     React.useEffect(() => {
          if (formState.삼성중공업하도이력.length !== 0 && selectedValue22 === "아니오") {
               setFormState(prevState => ({
                    ...prevState,
                    삼성중공업하도이력: []
               }));
          }
     }, [selectedValue22, setFormState, formState.삼성중공업하도이력]);


     React.useEffect(() => {
          if (formState.삼성중공업근무이력.length !== 0 && selectedValue11 === "아니오") {
               console.log("effect check")
               setFormState(prevState => ({
                    ...prevState,
                    삼성중공업근무이력: []
               }));
          }
     }, [selectedValue11, setFormState, formState.삼성중공업근무이력]);



     React.useEffect(() => {
          if (formState) {
               setworkhistroyvalid2(selectedValue22 === "예" && formState.삼성중공업하도이력.length > 0 && (formState.삼성중공업하도이력[0].name !== undefined) ? true
                    : selectedValue22 === "아니오" && formState.삼성중공업하도이력.length === 0 ?
                         true : false)
          }
     }, [formState, selectedValue22]);

     const types2 = ["예", "아니오"]

     return (
          <div className="current" data-kt-stepper-element="content">
               <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                         <h2 className="fw-bold d-flex align-items-center text-dark">대표자 및 임원 정보
                              <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                   <span className="path1"></span>
                                   <span className="path2"></span>
                                   <span className="path3"></span>
                              </i>
                         </h2>
                    </div>
                    <Field key={'근무여부'} id={'근무여부'} name={"근무여부"} label={'대표자 및 임원의 삼성중공업 근무여부'} data={types2} component={FormDropDownList} onChange={onChange}
                         validator={(value) => workHistoryValidator(value, workhistroyvalid, isValidMansInfo)}
                    />
                    {selectedValue11 === "예" &&
                         <>
                         <FormTable tabeldata={formState.삼성중공업근무이력} isValid={isValidMansInfo} tabletitle={"삼성중공업 근무이력"} formprop={"삼성중공업근무이력"} newkey={'성명'} column_list={[
                              {
                                   title: "성명",
                                   field_name: "name",
                                   type: "text",
                                   width: "150px",
                              },
                              {
                                   title: "생년월일",
                                   field_name: "date",
                                   type: "date",
                                   // width: "100px",
                              },
                         ]} />
                    </>
                    }

                    <Field key={'협력사여부'} id={'협력사여부'} name={"협력사여부"} label={'삼성중공업의 사내협력사 또는 재하도사 여부'} data={types2} component={FormDropDownList} 
                    validator={(value) => workHistoryValidator2(value, workhistroyvalid2, isValidHadoHistory)} onChange={onChange2} />

                    {selectedValue22 === "예" &&
                         <>
                              <FormTable tabeldata={formState.삼성중공업하도이력} tabletitle={"사내 협력 이력"} formprop={"삼성중공업하도이력"} isValid={isValidHadoHistory} newkey={'계약명'} column_list={[
                                   {
                                        title: "계약명",
                                        field_name: "name",
                                        type: "text",
                                        width: "190px",
                                   },
                                   {
                                        title: "계약시작일",
                                        field_name: "date1",
                                        type: "date",
                                        width: "120px",
                                   },
                                   ,
                                   {
                                        title: "계약종료일",
                                        field_name: "date2",
                                        type: "date",
                                        width: "120px",
                                   },
                              ]} />
                              {!isValidHadoHistory && <div className="text-danger">모든 필드를 올바르게 입력해주세요.</div>}

                         </>
                    }
                    <>
                    <Field
                         name="대표자경력유효성"
                         validator={() => representativeHistoryValidator(isValidRepresentativeHistory, formState.대표자경력)}
                         component={({ errorMessage }) => (
                         errorMessage ? <div className="text-danger">{errorMessage}</div> : null
                         )}
                         />
                    <FormTable tabeldata={formState.대표자경력} tabletitle={"대표자 경력"} formprop={"대표자경력"} isValid={isValidRepresentativeHistory} newkey={'계약명'} column_list={[
                         {
                              title: "대표자 이력내용",
                              field_name: "name",
                              type: "text",
                              width: "190px",
                         },
                         {
                              title: "시작일",
                              field_name: "date1",
                              type: "date",
                              width: "120px",
                         }, {
                              title: "종료일",
                              field_name: "date2",
                              type: "date",
                              width: "120px",
                         },
                    ]} />
                    </>
               </div>
          </div>
     );
};
