import { getter } from '@progress/kendo-react-common';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const taxNumberRegex = new RegExp(/^\d{3}-\d{2}-\d{5}$/);
const companyNumberRegex = /^\d{6}-\d{7}$/;
export const termsValidator = value => value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = value => !value ? "회사 이메일은 필수값입니다." : emailRegex.test(value) ? "" : "이메일 형태가 안 맞습니다.";
export const nameValidator = value => !value ? "이름은 필수값입니다." : value.length > 12 ? "너무 긴 이름을 넣었습니다." : "";
export const userNameValidator = (value, isDuplicate, disable) => {
  if (!value) {
    return "회사 아이디는 필수값입니다.";
  } else if (value.length < 3) {
    return "아이디로는 너무 짧습니다. 2글자 이상 넣으세요.";
  } else if (isDuplicate) {
    return "이 아이디는 이미 사용 중입니다.";
  } else if ( !disable) {
    return "중복검사를 하지 않았습니다.";

  }
  else {
    return ""; // 중복이 아니라면 빈 문자열 반환
  }
};
export const phoneValidator = value => !value ? "전화번호는 필수값입니다." : phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const cardValidator = value => !value ? "Credit card number is required. " : ccardRegex.test(value) ? "" : "Not a valid credit card number format.";
export const cvcValidator = value => !value ? "CVC code is required," : cvcRegex.test(value) || value.length !== 3 ? "" : "Not a valid CVC code format.";
export const guestsValidator = value => !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = value => value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = value => value ? "" : "Arrival Date is required.";
export const colorValidator = value => value ? "" : "Color is required.";
export const requiredValidator = value =>
{ 
  return value ? "" : "필수값입니다."};
export const passwordValidator = (value, username) => {
  if (!value) return "비밀번호를 입력하셔야합니다.";

  // 제약 조건을 검사하는 Helper 함수들
  const hasSequentialCharacters = str => /(012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|qwe|wer|ert|rty|tyu|yui|uio|iop|asd|sdf|dfg|fgh|ghj|hjk|jkl|zxc|xcv|cvb|vbn|bnm)/i.test(str);
  const hasRepeatedCharacters = str => /(.)\1\1/.test(str);
  const isOnlyNumericOrAlpha = str => /^[0-9]+$|^[a-zA-Z]+$/.test(str);
  const isValidPassword = (str) => {
    const requiredTypes = [
      /[A-Z]/.test(str),     // 대문자 확인
      /[a-z]/.test(str),     // 소문자 확인
      /[0-9]/.test(str),     // 숫자 확인
      /[^a-zA-Z0-9]/.test(str)  // 특수문자 확인
    ].filter(Boolean).length;

    return (requiredTypes >= 2 && str.length >= 10) || (requiredTypes >= 3 && str.length >= 8) ;
  };

  if (value === username) return "패스워드가 사용자 계정과 동일합니다.";
  if (hasRepeatedCharacters(value)) return "동일한 문자 또는 숫자가 3자 이상 사용되었습니다.";
  if (hasSequentialCharacters(value)) return "주기성 문자나 키보드상의 연속된 배열이 3개 이상 사용되었습니다.";
  if (isOnlyNumericOrAlpha(value)) return "문자 또는 숫자만으로 구성된 패스워드는 허용되지 않습니다.";
  if (value === '') return "Null 패스워드는 허용되지 않습니다.";

  if (!isValidPassword(value)) {
    return "패스워드는 다음의 문자 종류 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 작성하여야 합니다. * 영문 대문자(26개), 영문 소문자(26개), 숫자(10개), 특수문자(32개)";
  }

  return null;  // 유효한 패스워드
};


export const addressValidator = value => value ? "" : "주소는 필수값입니다.";
export const CompanyNameValidator = value => value ? "" : "법인명은 필수값입니다.";
export const TaxNumberValidator = value => {
  if (!value) return "납세번호는 필수값입니다.";
  return taxNumberRegex.test(value) ? "" : "납세번호 형식이 올바르지 않습니다.";
}
export const CompanyNumberValidator = value => {
  if (!value) return "법인등록번호는 필수값입니다.";
  return companyNumberRegex.test(value) ? "" : "법인등록번호 형식이 올바르지 않습니다.";
}
const userNameGetter = getter('username');
const emailGetter = getter('email');
export const formValidator = values => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);
  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    ['username']: !userName ? 'User Name is required.' : '',
    ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
  };
};

export const workHistoryValidator = (value, workhistroyvalid, isValidMansInfo) => {
  if (!value) {
    return "근무여부는 필수값입니다.";
  } else if (value === "예" &&(!workhistroyvalid || !isValidMansInfo)) {
    return "근무여부를 '예'로 선택했다면 경력 정보를 올바르게 입력하세요";
  }
};

export const workHistoryValidator2 = (value, workhistroyvalid2, isValidHadoHistory) => {
  if (!value) {
    return "삼성중공업의 사내협력사 또는 재하도사 여부는 필수값입니다.";
  } else if (value === "예" && (!workhistroyvalid2 || !isValidHadoHistory) ) {
    return "사내협력사 또는 재하도사 여부를 '예'로 선택했다면 리스트를 올바르게 입력하세요";
  }
};

export const representativeHistoryValidator = (isValidRepresentativeHistory, data) => {
  if (data.length === 0) return undefined;
  if (!isValidRepresentativeHistory) {
    return "대표자 경력 정보를 올바르게 입력해주세요. 이력내용, 시작일, 종료일을 모두 입력해야 합니다.";
  }
};

// validators.js
export const ContactValidator = (tableData, selectedValue, status) => {
  // 입찰자 정보(selectedValue)가 필수인 경우
  if (!selectedValue) {
    return "입찰자 정보는 꼭 넣어야 합니다.";
  }

  // 사용자가 정보를 저장해야 하는 경우(status 확인)
  if (status) {
    return "입력 정보를 저장하시기 바랍니다.";
  }

  // 테이블 데이터의 각 행에 대해 이름과 이메일이 있는지 확인
  for (const key in tableData) {
    const row = tableData[key];
    if (!row.name || !row.email) {
      return "이름이나 이메일을 입력했다면 두 정보 모두 기입해야합니다.";
    }
  }

  // 모든 검사를 통과했을 때
  return null; // 유효성 검사 통과
};


export const fileValidator = (checkValue,) => {
  if (!checkValue) {
    
    return "파일은 꼭 첨부해야합니다.";
  }  else {return ""}
};

export const fileValidator2 = (checkValue,) => {
  if (!checkValue) {
    
    return "파일은 하나만 첨부해야합니다.";
  }  else {return ""}
};


export const infoValidator = (value, checkValue,) => {
  if (!checkValue) {
    return "동의를 하시지않으면 다음으로 넘어갈 수 없습니다.";
  }  
};