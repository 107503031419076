import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from "../common/axios";
import Layout from "../layout/Layout";
import { Circles } from 'react-loader-spinner';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation } from 'react-router-dom';
import DetailPanelAutoHeight from './materialTable';

const Material = () => {
    const [tableData, setTableData] = useState([]);
    const [destinationData, setdestinationData] = useState([]);
    const [projectCodeList, setProjectCodeList] = useState([]);
    const [projectCode, setProjectCode] = useState({ projectnumber: "", projectname: "" });
    const [loading, setLoading] = useState(true);
    const location = useLocation();


    // 프로젝트 코드 상태 초기화
    useEffect(() => {
        const projectnumber = localStorage.getItem('projectnumber');
        const projectname = localStorage.getItem('projectname');
        if (projectname && projectnumber) {
            setProjectCode({ projectnumber: projectnumber, projectname: projectname });
        }
    }, []);


    // 프로젝트 코드 변경 시 로컬 스토리지에 저장
    useEffect(() => {
        if (projectCode && projectCode.projectname !== "") {
            localStorage.setItem('projectnumber', (projectCode.projectnumber));
            localStorage.setItem('projectname', (projectCode.projectname));
        }
    }, [projectCode]);


    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`/getprojectlist`)
            if (res.data.success) {
                const data = res.data.data
                setProjectCodeList(data)
            }
        }
        fetchData()
    }, [location])


    useEffect(() => {
        const fetchData = async () => {
            if (projectCode !== null && projectCode.projectnumber) {
                setLoading(true);

                const res = await axios.get(`/getMdata?path=${projectCode.projectnumber}`);
                const res2 = await axios.get(`/getDestinationdata?path=${projectCode.projectnumber}`);
                if (res.data.success && res2.data.success) {
                    let data = res.data.data.map((v) => ({
                        ...v,
                        SHIMatOrderDate: v.SHIMatOrderDate && new Date(v.SHIMatOrderDate),
                        SHIMatDeliveryDateReq: v.SHIMatDeliveryDateReq && new Date(v.SHIMatDeliveryDateReq),
                        SHIMatDeliveryDateExp: v.SHIMatDeliveryDateExp && new Date(v.SHIMatDeliveryDateExp),
                        "_bp_lineitems": v["_bp_lineitems"] && v["_bp_lineitems"].map((vf) => ({ ...vf, id: vf.li_num })) || [],
                    }));

                    setTableData(data);
                    setdestinationData(res2.data.data);
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [projectCode]);



    return (<>
            <div className='app-main flex-column flex-row-fluid '>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className='app-toolbar  py-3 py-lg-6 '>
                        <div className='app-container  container-xxl d-flex flex-stack '>
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                    {projectCode && projectCode.projectname}
                                </h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <span className="text-muted text-hover-primary">자재 입고</span>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">
                                        계획 수립 입력
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center gap-2 gap-lg-3">
                                <Autocomplete
                                    value={projectCode}
                                    onChange={(event, newValue) => {
                                        setProjectCode(newValue);
                                    }}
                                    options={projectCodeList || []}
                                    getOptionLabel={(option) => option ? option.projectname : ""}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Project" />}
                                />

                            </div>
                        </div>
                    </div>
                    <div className='app-content  flex-column-fluid ' style={{ paddingTop: 0 }}>
                        <div className='app-container container-xxl '>
                            {loading && projectCode !== null && projectCode.projectnumber !== "" && tableData && tableData.length > 0 ?
                                <div className="loadingContainer">
                                    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
                                </div> :
                                <DetailPanelAutoHeight tabledata={tableData} destinationData={destinationData} project={projectCode && projectCode.projectnumber} />
                            }
                        </div>
                    </div>
                </div>
            </div>
    </>);
};
export default Material;
