import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DataGridPro,
  useGridApiContext,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { randomId } from '@mui/x-data-grid-generator';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; // 변경된 부분
import axios from "../common/axios";
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';

export default function DetailPanelAutoHeight({ tabledata, project, destinationData }) {
  const [rows, setRows] = React.useState(tabledata ? tabledata.map((v, index) => ({
    ...v,
    id: index + 1,
  })) : []);


  useEffect(() => {
    if (tabledata) {
      setRows(tabledata.map((v, index) => ({
        ...v,
        id: index + 1,
      })));
    }
  }, [tabledata]);


  function DetailPanelContent({ row: rowProp, destinationData }) {
    const apiRef = useGridApiContext();
    const [unplannedQuantity, setUnplannedQuantity] = React.useState(rowProp.SHIskuorderresDA);

    React.useEffect(() => {
      const totalPlannedQty = rowProp._bp_lineitems.reduce((acc, item) => acc + (item.SHIMatplanQtyDA || 0), 0);
      const newUnplannedQty = rowProp.SHIskuOrderedDA - totalPlannedQty;
      setUnplannedQuantity(newUnplannedQty);
    }, [rowProp._bp_lineitems, rowProp.SHIskuorderresDA]);

    const processRowUpdate = React.useCallback((newRow) => {
      setRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.id === rowProp.id) {  // 상위 행의 ID를 사용해 비교
            return {
              ...row,
              _bp_lineitems: row._bp_lineitems.map((item) =>
                item.id === newRow.id ? newRow : item
              ),
            };
          }
          return row;
        });
      });
      return newRow;
    }, [rowProp.id]); // rowProp.id를 의존성 배열에 추가


    const addProduct = React.useCallback(() => {
      const newProduct = { id: randomId(), SHIMatplanQtyDA: 0, SHIMatDestinationBPK: "", DestinationAddress: "" ,SHIMatDeliveryDateExp:""};
      // 여기서는 rowProp을 변경하지 않고, 새로운 _bp_lineitems 배열만 생성합니다.
      const updatedLineItems = [...rowProp._bp_lineitems, newProduct];
      // updateRows를 사용하여 상위 컴포넌트의 상태를 업데이트합니다.
      updateRows({ ...rowProp, _bp_lineitems: updatedLineItems });
    }, [updateRows, rowProp]);


    const deleteProduct = React.useCallback(
      (productId) => () => {
        const newProducts = rowProp._bp_lineitems.filter(
          (product) => product.id !== productId,
        );
        updateRows({ ...rowProp, _bp_lineitems: newProducts });
      },
      [updateRows, rowProp],
    );


    const columns = React.useMemo(
      () => [
        {
          field: 'SHIMatDestinationBPK',
          headerName: '도착지명',
          flex: 1,
          editable: true,
          type: 'singleSelect',
          width: 100,
          valueOptions: destinationData.map((data) => data.DestinationName),
          // valueSetter: setAddress,
        },
        {
          field: 'DestinationAddress',
          headerName: '도착지 주소',
          flex: 1,
          editable: false,
          width: 200,
          valueGetter: (params) => {
            const selectedDestination = destinationData.find(data => data.DestinationName === params.row.SHIMatDestinationBPK);
            return selectedDestination ? selectedDestination.DestinationAddress : "";
          }
        },
        {
          field: 'SHIMatplanQtyDA',
          headerName: '입고계획수량',
          align: 'center',
          headerAlign: 'center',
          type: 'number',
          editable: true, width: 100
        },
        {
          field: 'SHIMatDeliveryDateExp',
          headerName: "예상 납기일",
          type: 'date',
          width: 100,
          editable: true,
          valueGetter: (params) => params.row.SHIMatDeliveryDateExp ? new Date(params.row.SHIMatDeliveryDateExp) : null,
        }
        ,

        { field: 'MatheatNo', headerName: 'Heat No.', flex: 1, editable: true, width: 100 },
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          width: 50,
          getActions: ({ row }) => [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="delete"
              onClick={deleteProduct(row.id)}
            />,
          ],
        },
      ],
      [deleteProduct, destinationData, apiRef],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);
  

    return (
      <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box' }} direction="column">
        <Paper sx={{ flex: 1, ml: 8, width: '40%', p: 1 }}>

          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography variant="h6">{`${rowProp.SHIMatPONoTX} ${rowProp.shimatskuDP} 미계획 수량: ${unplannedQuantity} `}</Typography>
            <div>
              <Button variant="outlined" size="small" onClick={addProduct}>
                새 계획 등록
              </Button>
            </div>
            <DataGridPro
              density="compact"
              autoHeight
              columns={columns}
              rows={rowProp._bp_lineitems}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              sx={{ flex: 1 }}
              hideFooter
            />
          </Stack>
        </Paper>
      </Stack>
    );
  }


  const columns = [
    { field: 'record', headerName: '레코드 번호' },
    { field: 'SHIMatPONoTX', headerName: '발주 번호' },
    { field: 'SHIMatPOItmNo', headerName: 'PO 항번' },
    { field: 'SHIMatPRNo', headerName: 'PR No.' },
    { field: 'SHIMatOrderNo', headerName: '신청번호' },
    { field: 'shimatskuDP', headerName: '자재번호' },
    { field: 'umatSHICategoryPD', headerName: '대분류' },
    { field: 'SHIMatClass', headerName: 'Class' },
    { field: 'SHIMatSize', headerName: 'Size' },
    { field: 'umatSHISubCatPD', headerName: '자재내역' },
    { field: 'umatSHISKUMtrDesc', headerName: '자재내역 상세', width: 350 },
    { field: 'SHIMatAreaPD', headerName: 'Area' },
    { field: 'SHImatVendorNameTX50', headerName: '납품회사' },
    { field: 'SHIskuOrderedDA', type: 'number', headerName: '발주량 (이관수량)' },
    { field: 'SHImatalreadyinDA', type: 'number', headerName: '계획수립수량' },
    { field: 'SHIskuorderresDA', type: 'number', headerName: '미계획수립수량' },
    { field: 'SHIMatUoM', headerName: '단위' },
    { field: 'SHIMatOrderDate', type: 'date', headerName: '발주일자(이관 요청 일자)' },
    { field: 'SHIMatDeliveryDateReq', type: 'date', headerName: '희망 납기일' },
    { field: 'SHIMatDeliveryDateExp', type: 'date', headerName: '예상 납기일' },
  ];

  const updateRows = (newRow) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === newRow.id) {
          return { ...row, ...newRow };
        }
        return row;
      });
    });
  };

  // getDetailPanelContent에서 이 함수를 prop으로 전달
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} updateRows={updateRows} destinationData={destinationData} />,
    [destinationData, updateRows],
  );

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const navigate = useNavigate(); // 변경된 부분

  const findChangedItems = () => {
    const changedRecords = [];
  
    rows.forEach((row) => {
      const originalRow = tabledata.find((item) => item.record === row.record);
  
      if (originalRow) {
        const changedItems = [];
  
        // LineAutoSeq 값들 중 최대값 찾기
        const maxSeq = Math.max(...row._bp_lineitems.map(item => parseInt(item.LineAutoSeq || '0', 10)), 0);
  
        row._bp_lineitems.forEach((item, index) => {
          const originalItem = originalRow._bp_lineitems.find(originalItem => originalItem.id === item.id);
  
          if (!originalItem || JSON.stringify(item) !== JSON.stringify(originalItem)) {
            let newItem;
            if (originalItem) {
              newItem = { ...item, uuu_tab_id: "Delivery Plan", LineAutoSeq: originalItem.LineAutoSeq };
            } else {
              // 새 항목의 경우, maxSeq를 기반으로 LineAutoSeq 설정
              const newSeq = (maxSeq + index + 1).toString().padStart(5, '0');
              newItem = { ...item, uuu_tab_id: "Delivery Plan", LineAutoSeq: newSeq , SHIMatDeliveryDateExp:dayjs(item.SHIMatDeliveryDateExp).format("MM-DD-YYYY")};
            }
  
            changedItems.push(newItem);
          }
        });
  
        if (changedItems.length > 0) {
          changedRecords.push({ record_no: row.record, _bp_lineitems: changedItems });
        }
      }
    });
  
    return changedRecords;
  };
  


  const handlerequest = async () => {
    try {
      const sendData = await findChangedItems();
      console.log(sendData);

      Swal.fire({
        title: '정말 자재 입고 계획을 유니파이어로 보내겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '네',
        cancelButtonText: '아니오'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post("/submitMplan", { sendData, project })
            .then(response => {
              if (response.data.success) {
                Swal.fire({
                  title: '성공적으로 제출되었습니다',
                  icon: 'success',
                  confirmButtonText: '확인'
                }).then(() => {
                  navigate('/material'); // 변경된 부분
                });
              } else {
                Swal.fire({
                  title: '제출 실패',
                  icon: 'error',
                  text: response.data.message || '알 수 없는 오류가 발생했습니다.',
                  confirmButtonText: '확인'
                });
              }
            })
            .catch(err => {
              Swal.fire({
                title: '에러 발생',
                icon: 'error',
                text: err.response?.data?.message || err.message || '알 수 없는 오류가 발생했습니다.',
                confirmButtonText: '확인'
              });
            });
        }
      });
    } catch (error) {
      console.error('Error fetching changed items:', error);
      // 적절한 오류 처리 로직을 추가하세요.
    }
  }



  return (
    <>
        <div>
          <button className='btn btn-sm btn-light btn-color-muted btn-active-light-danger' type='button'
            onClick={handlerequest}
            // style={{ float: "right" }}
          >
            <i className="ki-duotone ki-exit-right fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>계획 확정
          </button>
        </div>
      <Box sx={{ width: 1, height: 550 }}>

        <DataGridPro
          rows={rows}
          density='compact'
          columns={columns}
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </>

  );
}
