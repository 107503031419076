import React, { useState, useEffect, useRef, useCallback } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import _, { set } from "lodash"
import Swal from 'sweetalert2';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { CellRender, RowRender } from './renderers';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages, load
} from "@progress/kendo-react-intl";
import debounce from 'lodash/debounce';
import krMessage from './kr.json'
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { read, utils, writeFile } from 'xlsx';
import { useNavigate } from 'react-router-dom'; // 변경된 부분
import { Upload } from '@progress/kendo-react-upload';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import BigNumber from 'bignumber.js';
load(
	likelySubtags,
	currencyData,
	weekData,
	numbers,
	currencies,
	caGregorian,
	dateFields,
	timeZoneNames
);
loadMessages(krMessage, "ko-KR");

axios.defaults.timeout = 300000;

const initialDataState = {
	skip: 0,
	take: 10
};

const PaymentTable = ({ tabledata , originalPayment, paymentRecordNo}) => {
	const navigate = useNavigate(); // 변경된 부분
	const exportData = tabledata && tabledata.sov.map(v => {
		const matchingPayment = originalPayment && originalPayment.find(payment => payment._refnum === Number(v.li_num));
	
		return {
		  id: v.li_num,
		  "계약 유형": v.uuu_cost_li_type === "Unit Cost" ? "단가" : v.uuu_cost_li_type === "Lump Sum" ? "총액" : "",
		  "품명": v.spocItemName,
		  "규격": v.spocSpec,
		  "단위": v.spocUoMTxt,
		  "계약 수량": parseFloat(v.ItemQuantity).toFixed(4),
		  "계약 단가": Math.round(v.uuu_unit_price),
		  "계약 금액": Math.round(v.ItemQuantity * v.uuu_unit_price),
		  "할증률": v.spocMarginPerc,
		  "누적 기성지급액": v["누적 금액"] ? Math.round(v["누적 금액"]) : 0,
		  "금회 수량/금액": matchingPayment ? ( v.uuu_cost_li_type === "Unit Cost" ? matchingPayment.uuu_spa_qty_tp : matchingPayment.uuu_spa_amt_tp ): 0,
		  "possbileAmount": v.possbileAmount,
		  Description: v.Description,
		  bItemID: v["bItemID"],
		  LineAutoSeq:matchingPayment && matchingPayment.LineAutoSeq
		};
	  });
	
	  console.log(exportData)

	const EDIT_FIELD = 'inEdit';
	const [data, setData] = React.useState(exportData);
	const [Advpayment, setAdvpayment] = React.useState({});
	const [page, setPage] = React.useState(initialDataState);
	const [pageSizeValue, setPageSizeValue] = React.useState();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [showNoAdvancePaymentAlert, setShowNoAdvancePaymentAlert] = useState(false);

	console.log(data)

	const calculateValidity = (dataItem) => {
		const contractType = dataItem["계약 유형"];
		const surchargeRate = new BigNumber(dataItem["할증률"]);
		const contractQuantity = new BigNumber(dataItem["계약 수량"]);
		const thisAmount = new BigNumber(dataItem["금회 수량/금액"]);
		const possbileAmount = new BigNumber(dataItem.possbileAmount);

		let payment;
		if (contractType === "총액") {
			payment = surchargeRate.isZero()
				? contractQuantity.times(thisAmount)
				: contractQuantity.times(thisAmount).times(surchargeRate.div(100).plus(1));
		} else {
			const contractUnitPrice = new BigNumber(dataItem["계약 단가"]);
			payment = surchargeRate.isZero()
				? contractUnitPrice.times(thisAmount.toFixed(4))
				: contractUnitPrice.times(thisAmount.times(surchargeRate.div(100).plus(1)).toFixed(4));
		}

		payment = payment.integerValue(BigNumber.ROUND_FLOOR);

		const contractAmount = new BigNumber(dataItem["계약 금액"]);
		const cumulativeAmountPaid = new BigNumber(dataItem["누적 기성지급액"]);
		let value;


		if (possbileAmount.isNegative()) {
			// 음수일 때의 계산 로직
			value = payment.minus(possbileAmount);
		} else {
			// 기존의 계산 로직
			value = possbileAmount.minus(payment)
		}


		return value.isGreaterThanOrEqualTo(0);
	};

	// 모든 항목이 유효한 경우에만 true를 반환
	const areAllItemsValid = data.every(calculateValidity);

	// 하나라도 유효하지 않은 항목이 있으면 버튼 활성화
	const disabled = !areAllItemsValid;




	const sum = _.sum(data.map((v) => {
		const type = v["계약 유형"];
		const rate = new BigNumber(v["할증률"]);
		const quantity = new BigNumber(v["계약 수량"]);
		const thisAmount = new BigNumber(v["금회 수량/금액"]);
		const unitPrice = new BigNumber(v["계약 단가"]);

		let total;
		if (type === "총액") {
			if (rate.isZero()) {
				total = quantity.times(thisAmount);
			} else {
				total = quantity.times(thisAmount).times(rate.div(100).plus(1));
			}
		} else {
			if (rate.isZero()) {
				total = unitPrice.times(thisAmount);
			} else {
				total = unitPrice.times(thisAmount.times(rate.div(100).plus(1)));
			}
		}
		return total.integerValue(BigNumber.ROUND_FLOOR).toNumber(); // 정수값으로 변환하고, JavaScript 숫자로 다시 변환
	}));


	useEffect(() => {
		const calculatePaymentDetails = (data, sum, tabledata) => {
			const thisTimePayment = Math.round((sum / data.amount) * data.AdvPayNetAmount);
			const inThisTime = _.sumBy(data.data.filter(v => v.NumberingPD.split("차")[0] < tabledata.기성차수), "AdvReturnAmount");
			const remaining = data.AdvPayNetAmount - inThisTime;
			const isLastPayment = (tabledata.originalAmount - sum - _.sumBy(tabledata.sov, "누적 기성지급액")) <= 0;

			return {
				remain: remaining,
				thisTime: (remaining < thisTimePayment || sum === tabledata.originalAmount || isLastPayment) ? remaining : thisTimePayment,
				adv: data.AdvPayNetAmount,
				record_no: data.record
			};
		};

		const fetchData = async () => {
			try {
				const response = await axios.get(`/getadvpayment?record=${tabledata.record}&project=${tabledata.project}&number=${tabledata.기성차수}`);
				if (response.data.success) {
					const paymentDetails = calculatePaymentDetails(response.data, sum, tabledata);
					setAdvpayment(paymentDetails);
				} else {
					setAdvpayment({
						remain: 0,
						thisTime: 0,
						adv: 0,
						record_no: ""
					});
					if (!showNoAdvancePaymentAlert) {
						Swal.fire('정보', '선급금이 없는 계약입니다', 'info');
						setShowNoAdvancePaymentAlert(true);
					  }
								}
			} catch (error) {
				console.error('Error fetching data:', error);
				Swal.fire('Error', 'An error occurred while fetching data', 'error');
			}
		};

		fetchData();
	}, [tabledata, sum]);

	const fileInputRef = useRef(null);

	const downloadExcel = () => {
		const worksheet = utils.json_to_sheet(
			data.map((v) => { delete v.bItemID; delete v["금회 금액"]; delete v.inEdit; delete v.Description; delete v.LineAutoSeq; delete v.possbileAmount; return v; })
		);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		writeFile(workbook, '기성신청데이터.xlsx');
	};


	const pageChange = event => {
		const targetEvent = event.targetEvent;
		const take = targetEvent.value === 'All' ? data.length : event.page.take;
		if (targetEvent.value) {
			setPageSizeValue(targetEvent.value);
		}
		setPage({
			...event.page,
			take
		});
	};

	const [changes, setChanges] = React.useState(false);
	const enterEdit = (dataItem, field) => {
		const newData = data.map(item => ({
			...item,
			[EDIT_FIELD]: item.id === dataItem.id ? field : undefined
		}));
		setData(newData);
	};
	const exitEdit = () => {
		const newData = data.map(item => ({
			...item,
			[EDIT_FIELD]: undefined
		}));
		setData(newData);
	};
	const saveChanges = () => {
		exportData.splice(0, exportData.length, ...data);
		setChanges(false);

		Swal.fire({
			icon: 'success',
			title: '저장되었습니다',
			showConfirmButton: false,
			timer: 1500
		});
	};

	const cancelChanges = () => {
		setData(exportData);
		setChanges(false);
	};

	const itemChange = event => {
		let field = event.field || '';
		let newData = data.map(item => {
		  if (item.id === event.dataItem.id) {
			const rate = new BigNumber(item["할증률"]);
			const quantity = new BigNumber(item["계약 수량"]);
			const thisAmount = new BigNumber(event.value || 0);  // 값이 없으면 0으로 설정 
			let calculatedAmount;
			if (item["계약 유형"] === "총액") {
			  calculatedAmount = rate.isZero()
				? quantity.times(thisAmount)
				: quantity.times(thisAmount).times(rate.div(100).plus(1));
			} else {
			  const unitPrice = new BigNumber(item["계약 단가"]);
			  calculatedAmount = rate.isZero()
				? unitPrice.times(thisAmount)
				: unitPrice.times(thisAmount.times(rate.div(100).plus(1)));
			}
		  
			const isCalculatedAmountNegative = calculatedAmount.isNegative();
			calculatedAmount = calculatedAmount.abs().integerValue(BigNumber.ROUND_FLOOR);
			if (isCalculatedAmountNegative) {
			  calculatedAmount = calculatedAmount.times(-1);
			}
	  
			return {
			  ...item,
			  [field]: event.value,
			  "금회 금액": calculatedAmount.isNaN() ? 0 : calculatedAmount.toNumber()
			};
		  }
		  return item;
		});
	  
		setData(newData);
		setChanges(true);
	  };
	const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
	const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;

	const ItemCell = (props) => {
		const dataItem = props.dataItem;
		const contractType = dataItem["계약 유형"];
		const surchargeRate = new BigNumber(dataItem["할증률"]);
		const contractQuantity = new BigNumber(dataItem["계약 수량"]);
		const thisAmount = new BigNumber(dataItem["금회 수량/금액"]);
	  
		let value;
		if (contractType === "총액") {
		  value = surchargeRate.isZero()
			? contractQuantity.times(thisAmount)
			: contractQuantity.times(thisAmount).times(surchargeRate.div(100).plus(1));
		} else {
		  const contractUnitPrice = new BigNumber(dataItem["계약 단가"]);
		  value = surchargeRate.isZero()
			? contractUnitPrice.times(thisAmount.toFixed(4))
			: contractUnitPrice.times(thisAmount.times(surchargeRate.div(100).plus(1)).toFixed(4));
		}
	  
		const isNegative = value.isNegative();
		value = value.abs().integerValue(BigNumber.ROUND_FLOOR);
		if (isNegative) {
		  value = value.times(-1);
		}
	  
		const displayValue = value.isNaN() ? "0" : value.toFormat();
	  
		return (
		  <td colSpan={props.colSpan} role={"gridcell"} className='k-table-td'>
			{displayValue}
		  </td>
		);
	  };
	  const ItemCell2 = (props) => {
		const dataItem = props.dataItem;
		const contractType = dataItem["계약 유형"];
		const surchargeRate = new BigNumber(dataItem["할증률"]);
		const contractQuantity = new BigNumber(dataItem["계약 수량"]);
		const thisAmount = new BigNumber(dataItem["금회 수량/금액"]);
		const possbileAmount = new BigNumber(dataItem.possbileAmount);
	  
		let payment;
		if (contractType === "총액") {
		  payment = surchargeRate.isZero()
			? contractQuantity.times(thisAmount)
			: contractQuantity.times(thisAmount).times(surchargeRate.div(100).plus(1));
		} else {
		  const contractUnitPrice = new BigNumber(dataItem["계약 단가"]);
		  payment = surchargeRate.isZero()
			? contractUnitPrice.times(thisAmount.toFixed(4))
			: contractUnitPrice.times(thisAmount.times(surchargeRate.div(100).plus(1)).toFixed(4));
		}
	  
		const isPaymentNegative = payment.isNegative();
		payment = payment.abs().integerValue(BigNumber.ROUND_FLOOR);
		if (isPaymentNegative) {
		  payment = payment.times(-1);
		}
	  
		let value;
		if (possbileAmount.isNegative()) {
		  value = payment.minus(possbileAmount);
		} else {
		  value = possbileAmount.minus(payment);
		}
	  
	  
		return (
		  <td colSpan={props.colSpan} role={"gridcell"} className='k-table-td'>
			{value.isGreaterThanOrEqualTo(0) ? 
			  <span className="badge badge-light-success fs-base fs-10">ok</span> :
			  <span className="badge badge-light-danger fs-base fs-9">error</span>
			}
		  </td>
		);
	  };

	const MCell = (props) => {
		const field = props.field || "";
		const value = props.dataItem[field];
		return (
			<td
				colSpan={props.colSpan}
				role={"gridcell"}
				className='k-table-td'
			>
				{value.toLocaleString()}
			</td>
		);
	};

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};


	const [files, setFiles] = React.useState([]);
	const [fileData, setFileData] = React.useState([]);
	const [uploadfiles, setUploadFiles] = React.useState([]);


	const onAdd = (event) => {
		if (!event.target.files) {
			return;
		}
		const file = event.target.files[0];

		setFiles(file['name']);

		const reader = new FileReader();
		reader.onloadend = (ev) => {
			if (!ev?.target?.result) {
				return;
			}

			const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
			const sheets = wb.SheetNames;

			const header = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })[0];

			if (sheets.length) {
				const expectedHeaders = [
					"id",
					"계약 유형",
					"품명",
					"규격",
					"단위",
					"계약 수량",
					"계약 단가",
					"계약 금액",
					"할증률",
					"누적 기성지급액",
					"금회 수량/금액"
				];

				if (header.length === expectedHeaders.length &&
					header.every((value, index) => value === expectedHeaders[index])) {

					const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
					const add_row = _.differenceBy(rows, data, 'id');
					const exist = _.differenceBy(rows, add_row, 'id');

					setData(data.map(item => {
						const existItem = exist.find(e => e.id === item.id);

						if (existItem) {
							const rate = new BigNumber(existItem["할증률"]);
							const quantity = new BigNumber(existItem["계약 수량"]);
							const thisAmount = new BigNumber(existItem["금회 수량/금액"]);
							const unitPrice = new BigNumber(existItem["계약 단가"]);

							let calculatedAmount;
							if (existItem["계약 유형"] === "총액") {
								calculatedAmount = rate.isZero()
									? quantity.times(thisAmount.toFixed(0))
									: quantity.times(thisAmount.toFixed(0)).times(rate.div(100).plus(1));
							} else {
								calculatedAmount = rate.isZero()
									? unitPrice.times(thisAmount.toFixed(4))
									: unitPrice.times(thisAmount.times(rate.div(100).plus(1)).toFixed(4));
							}

							calculatedAmount = calculatedAmount.integerValue(BigNumber.ROUND_FLOOR);

							return {
								...item,
								"금회 수량/금액": existItem["금회 수량/금액"],
								"금회 금액": calculatedAmount.toString()
							};
						}
						return item;  // existItem이 없는 경우 원래 item을 그대로 반환
					}));

					Swal.fire({
						icon: 'success',
						title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 기성 신청 버튼을 클릭하세요',
						timer: 2000
					});

				} else {
					Swal.fire({
						icon: 'error',
						title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
						timer: 2000
					});
				}
			}
		};
		reader.readAsArrayBuffer(file);
	};

	const onAddHandler = (e) => {
		setUploadFiles(e.newState);

		const affectedFiles = e.affectedFiles;
		const updatedState = [];

		let filesProcessed = 0; // 처리된 파일 수를 추적합니다.

		affectedFiles.forEach((file) => {
			const cc = file.getRawFile();
			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};

				updatedState.push(updatedFile);
				filesProcessed++;

				// 모든 파일이 처리되었는지 확인합니다.
				if (filesProcessed === affectedFiles.length) {
					setFileData(updatedState); // 여기로 이동
				}
			};

			reader.readAsDataURL(cc);
		});
	};


	const onRemoveHandler = (event) => {
		setUploadFiles(event.newState);
	};



	const PriceCell = (props) => {
		let formattedSum;
		if (sum < 0) {
		  formattedSum = Math.floor(Math.abs(sum)).toLocaleString();
		  formattedSum = `-${formattedSum}`;
		} else {
		  formattedSum = Math.floor(sum).toLocaleString();
		}
	  
		return (
		  <td colSpan={props.colSpan} style={props.style}>
			{formattedSum}
		  </td>
		);
	  };


	const handlerequest = () => {
		// 파일 데이터가 빈 배열인지 확인
		if (fileData.length === 0) {
			Swal.fire({
				title: '오류',
				text: '파일을 첨부해주세요',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}

		// 파일 데이터가 빈 배열인지 확인
		if (sum === 0) {
			Swal.fire({
				title: '오류',
				text: '금회 금액이 없습니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}

		if (tabledata.possiblePayment - sum < Advpayment.remain) {
			Swal.fire({
				title: '오류',
				text: '선급금 반제 금액이 기성잔여금 보다 큽니다. 담당자에게 연락하여 선급금 반제 계획 수정 요청을 하시기 바랍니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}
		// 파일 데이터가 빈 배열인지 확인
		if (Advpayment && Advpayment.thisTime && Advpayment.thisTime >= sum) {
			Swal.fire({
				title: '오류',
				text: '금회 신청 금액이 선급 반제 금액보다 적습니다. 선급 반제 금액을 줄이시기 바랍니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}

		// 잔여보다 큰지 확인
		if (sum > tabledata.possiblePayment) {
			Swal.fire({
				title: '오류',
				text: '금회 신청 금액이 남은 기성 잔여금보다 큽니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}
		Swal.fire({
			title: '정말 기성 신청을 보내겠습니까?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '네',
			cancelButtonText: '아니오'
		}).then((result) => {
			if (result.isConfirmed) {

				setIsSubmitting(true);

				      // 진행 상황을 표시할 Toast 알림
					  const toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000,
						timerProgressBar: true,
						didOpen: (toast) => {
						  toast.addEventListener('mouseenter', Swal.stopTimer)
						  toast.addEventListener('mouseleave', Swal.resumeTimer)
						}
					  });
				
					  toast.fire({
						icon: 'info',
						title: '기성 신청 처리 중...'
					  });
					  
				      const apiUrl = window.location.pathname.includes('createNewPA') ? '/submitNPA' : '/submitNPA2';


				// console.log(data)
				// API 요청을 보낸다. 예시로 sendRequest 함수를 사용함.
				axios.post(apiUrl, {
					paymentRecordNo:paymentRecordNo,
					lineData: data,
					files: fileData,
					recordData: { project: tabledata.project, title: tabledata.title.slice(0, 50), record: tabledata.record, advRecord: Advpayment.record_no, advAmount: Advpayment.thisTime, PrevAdvPayRemain: Advpayment.remain }
				})
					.then((response) => {
						setIsSubmitting(false);
						if (response.data.success) {
							Swal.fire({
								title: '성공적으로 제출되었습니다',
								icon: 'success',
								confirmButtonText: '확인'
							}).then(() => {
								navigate('/log-payment'); // 변경된 부분
							});
						} else {
							Swal.fire({
								title: '에러 발생',
								icon: 'error',
								text: response.data.data,
								confirmButtonText: '확인'
							})
						}
					})
					.catch(() => {
						setIsSubmitting(false);
						Swal.fire({
							title: '문제가 발생했습니다',
							icon: 'error'
						});
					});
			}
		});
	}


	const CustomNumericCell = props => {
		const { dataItem, field, onChange } = props;
		const value = dataItem[field];
		const inEdit = dataItem[EDIT_FIELD] === field;
		const isUnitPrice = dataItem["계약 유형"] === "단가";

		const [localValue, setLocalValue] = useState(value);

		useEffect(() => {
			setLocalValue(value);
		}, [value]);

		const debouncedOnChange = useCallback(
			debounce((newValue) => {
				onChange({
					dataItem: dataItem,
					field: field,
					syntheticEvent: { target: { value: newValue } },
					value: newValue
				});
			}, 900),
			[dataItem, field, onChange]
		);

		const handleChange = (event) => {
			const newValue = event.value;
			setLocalValue(newValue);
			debouncedOnChange(newValue);
		};

		const cellStyle = {
			fontSize: '9px !important',
			padding: '0px',
			height: '100%',
		};

		if (inEdit) {
			return (
				<td style={cellStyle}>
					<NumericTextBox
						value={localValue}
						format={isUnitPrice ? "n4" : "n0"}
						onChange={handleChange}
						width="100%"
						height="100%"
					/>
				</td>
			);
		}

		return (
			<td
				onClick={() => props.enterEdit(dataItem, field)}
				style={cellStyle}
			>
				{localValue !== undefined && localValue !== null
					? Number(localValue).toFixed(isUnitPrice ? 4 : 0)
					: (isUnitPrice ? '0.0000' : '0')}
			</td>
		);
	};

	return <><div><label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
		<span className='fw-semibold text-gray-400 '>선급금:{Advpayment && Advpayment.adv && Advpayment.adv.toLocaleString()}원</span> &nbsp; &nbsp;
		<span className='fw-semibold text-gray-400'> 선급금 잔여:{Advpayment && Advpayment.remain && Advpayment.remain.toLocaleString()}원</span> &nbsp; &nbsp;
		<span> {Advpayment && Advpayment.remain && ((Advpayment.remain / Advpayment.adv) * 100).toFixed(1)}%</span>&nbsp; &nbsp;
		<span> {tabledata.기성차수}차 선급금 반제금액: {Advpayment && Advpayment.thisTime && Advpayment.thisTime.toLocaleString()}원</span>&nbsp; &nbsp;

	</label>
	</div>
		<LocalizationProvider language="ko-KR">
			<IntlProvider locale="ko-KR">
				<Grid style={{
					height: '600px'
				}} data={data.slice(page.skip, page.take + page.skip)} onPageChange={pageChange} className='payment'
					skip={page.skip} take={page.take} total={data.length}
					// cells={{
					// 	edit:{numeric:MyNumericCustomCell}
					// }}
					pageable={{
						buttonCount: 4,
						pageSizes: [5, 10, 15, 'All'],
						pageSizeValue: pageSizeValue
					}} dataItemKey={'id'} rowHeight={28} onItemChange={itemChange} cellRender={customCellRender} rowRender={customRowRender} editField={EDIT_FIELD}>
					<GridToolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', gap: '10px' }}>
							<button className='btn btn-sm fw-bold btn-primary' type='button' onClick={downloadExcel}>
								<i className="ki-duotone ki-file-down fs-2">
									<span className="path1"></span>
									<span className="path2"></span>
								</i>다운로드
							</button>
							<button className='btn btn-sm fw-bold btn-secondary' type='button' onClick={handleButtonClick}>
								<i className="ki-duotone ki-file-up fs-2">
									<span className="path1"></span>
									<span className="path2"></span>
								</i>임포트
								<input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputRef} onChange={onAdd} />
							</button>
						</div>
						<span>{files}</span>
						<div style={{ display: 'flex', gap: '10px', position: "absolute", right: 8 }}>

							<button
								className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
								type='button'
								disabled={disabled || isSubmitting}
								onClick={handlerequest}
							>
								{isSubmitting ? (
									<>
										<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										처리 중...
									</>
								) : (
									<>
										<i className="ki-duotone ki-exit-right fs-2">
											<span className="path1"></span>
											<span className="path2"></span>
										</i>
										기성 신청
									</>
								)}
							</button>
						</div>

					</GridToolbar>
					<Column field="id" title="Id" width="50px" editable={false} />
					<Column field="계약 유형" title="유형" width="50px" editable={false} />
					<Column field="품명" title="품명" width="140px" editable={false} />
					<Column field="규격" title="규격" width="200px" editable={false} />
					<Column field="단위" title="단위" width="50px" editable={false} />
					<Column field="계약 수량" title="계약 수량" width="90px" editable={false} cell={MCell} />
					<Column field="계약 단가" title="계약 단가" width="90px" editable={false} cell={MCell} />
					<Column field="계약 금액" title="계약 금액" width="110px" editable={false} cell={MCell} />
					<Column field="할증률" title="할증률(%)" width="90px" editable={false} />
					<Column field="누적 기성지급액" title="누적 기성지급액" width="150px" editable={false} cell={MCell} />
					{/* <Column field="possbileAmount" title="기성가능금액" width="150px" editable={false} show="false"/> */}
					<Column
						field="금회 수량/금액"
						title="금회 수량/금액"
						width="100px"
						cell={props => <CustomNumericCell {...props} enterEdit={enterEdit} />}
					/>
					<Column field="금회 금액" title="금회 금액" width="100px" editable={false} cell={ItemCell} footerCell={PriceCell} />
					<Column title="검증" width="60px" editable={false} cell={ItemCell2} />
				</Grid>
				<Upload multiple={true} onAdd={onAddHandler} files={uploadfiles}
					onRemove={onRemoveHandler} autoUpload={false} batch={false} showActionButtons={false}></Upload>
			</IntlProvider>
		</LocalizationProvider>
	</>;
}

export default PaymentTable;