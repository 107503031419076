import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import _ from 'lodash';
import { Form, FormElement, Field, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import krMessage2 from '../login/multistep/kr.json';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
} from "@progress/kendo-react-intl";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
loadMessages(krMessage2, "ko-KR");

const uploadRef = React.createRef();

const COPage = () => {
	const [data, setData] = useState([]);
	const [visibleDialog, setVisibleDialog] = useState(false);
	const [visibleDialogDetail, setVisibleDialogDetail] = useState(false);
	const [tableData, setTableData] = useState({});
	const [pageSizeValue, setPageSizeValue] = React.useState();
	const initialDataState = {
		skip: 0,
		take: 10
	  };
	  const [page, setPage] = React.useState(initialDataState);
	
	const pageChange = event => {
	  const targetEvent = event.targetEvent;
	  const take = targetEvent.value === 'All' ? tableData.details.length : event.page.take;
	  if (targetEvent.value) {
		setPageSizeValue(targetEvent.value);
	  }
	  setPage({
		...event.page,
		take
	  });
	};
	const [currentBond, setCurrentBond] = useState(null);

	const handleButtonClickDetail = (e) => {
		setTableData({ title: e.계약명, details: e.계약내역.map((v)=>({...v, type:v.uuu_cost_li_type === "Lump Sum" ?"총액":"단가"})) });
		setVisibleDialogDetail(true);

	}

	const _export = React.useRef(null);
	const excelExport = () => {
	  if (_export.current !== null) {
		_export.current.save();
	  }
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get('/getco');
				if (res.data.success) {
					setData(res.data.data);
				}

			} catch (error) {
				console.error('데이터 가져오기 중 오류 발생:', error);
			}
		};
		fetchData();
	}, []);

	const handleButtonAPIClick = (v) => {
		Swal.fire({
			title: '변경계약 수정 요청',
			text: "이 요청을 보내면 삼성중공업 담당자분에게 수정 요청이 접수됩니다.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '네, 보내기',
			cancelButtonText: '아니요, 취소'
		}).then((result) => {
			if (result.isConfirmed) {
				axios.get(`/udpatecontract5?record=${v.record}&project=${v.projectCode}`)
					.then(response => {
						if (response.data.success) {
							Swal.fire({
								title: '성공',
								text: "요청이 성공적으로 접수되었습니다. 다시 검토 요청을 받기 전까지는 해당 계약 검토는 보이지 않습니다.",
								icon: 'success',
								confirmButtonText: '확인'
							}).then((result) => {
								if (result.isConfirmed) {
									// Navigate to /confirmContract
									window.location.href = "/confirmCO";
								}
							});
						} else {
							Swal.fire({
								title: '오류',
								text: "요청 처리 중 오류가 발생했습니다.",
								icon: 'error',
								confirmButtonText: '확인'
							});
						}
					})
					.catch(error => {
						// Handle any errors from the axios request
						Swal.fire({
							title: '오류',
							text: "요청 처리 중 오류가 발생했습니다.",
							icon: 'error',
							confirmButtonText: '확인'
						});
					});
			}
		});
	};


	const handleButtonAPIClick2 = (v) => {
		Swal.fire({
			title: '변경계약 진행 요청',
			text: "이 요청을 보내면 계약서 사인 요청이 시작됩니다.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '네, 보내기',
			cancelButtonText: '아니요, 취소'
		}).then((result) => {
			if (result.isConfirmed) {
				axios.get(`/udpatecontract6?record=${v.record}&project=${v.projectCode}`)
					.then(response => {
						if (response.data.success) {
							Swal.fire({
								title: '성공',
								text: "요청이 성공적으로 접수되었습니다. 해당 계약은 전자서명으로 넘어갔습니다.",
								icon: 'success',
								confirmButtonText: '확인'
							}).then((result) => {
								if (result.isConfirmed) {
									// Navigate to /confirmContract
									window.location.href = "/confirmCO";
								}
							});
						} else {
							Swal.fire({
								title: '오류',
								text: "요청 처리 중 오류가 발생했습니다.",
								icon: 'error',
								confirmButtonText: '확인'
							});
						}
					})
					.catch(error => {
						// Handle any errors from the axios request
						Swal.fire({
							title: '오류',
							text: "요청 처리 중 오류가 발생했습니다.",
							icon: 'error',
							confirmButtonText: '확인'
						});
					});
			}
		});
	};

	const handleButtonClick = (e) => {
		setVisibleDialog(true);
		setCurrentBond(e)

	};

	const handleCloseModal = () => {
		setVisibleDialog(false);
		setCurrentBond(null)
	};


	const handleCloseModalDetail = () => {
		setVisibleDialogDetail(false);
		setTableData(null)
	};

	const FormUpload2 = fieldRenderProps => {
		const {
			value,
			id,
			optional,
			label,
			hint,
			validationMessage,
			touched,
			...others
		} = fieldRenderProps;
		const showValidationMessage = touched && validationMessage;
		const showHint = !showValidationMessage && hint;
		const hintId = showHint ? `${id}_hint` : '';
		const errorId = showValidationMessage ? `${id}_error` : '';
		const labelId = label ? `${id}_label` : '';
		const onChangeHandler = event => {
			fieldRenderProps.onChange({
				value: event.newState
			});
		};
		const onRemoveHandler = event => {
			fieldRenderProps.onChange({
				value: event.newState
			});
		};

		const display = fieldRenderProps.value && fieldRenderProps.value.length > 0 ? "none" : 'block';

		return <FieldWrapper>
			<Label id={labelId} editorId={id} optional={optional} className='k-form-label'>
				{label}
			</Label>
			<LocalizationProvider language="ko-KR">
				<IntlProvider locale="kr">
					<div className={'k-form-field-wrap'}>
						<Upload ref={uploadRef}
							// restrictions={{
							//     allowedExtensions: [".pdf"],
							//   }} 
							id={id} autoUpload={false} showActionButtons={false} multiple={true} files={value} onAdd={onChangeHandler} onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />
						{showHint && <Hint id={hintId}>{hint}</Hint>}
						{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
					</div>
					<div
						style={{
							height: "10px",
						}}></div>
					<div style={{ display: display }}>
						<ExternalDropZone uploadRef={uploadRef} />
					</div>
				</IntlProvider>
			</LocalizationProvider>
		</FieldWrapper>;
	};


	const onStepSubmit = React.useCallback(event => {

		const affectedFiles = event.values[currentBond.record_no]
		const updatedState = [];

		affectedFiles.forEach((file) => {
			const cc = file.getRawFile();
			const filetype = file.extension;

			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};
				updatedState.push(updatedFile);

				if (updatedState.length === affectedFiles.length) {
					handleCloseModal();
					Swal.fire({
						title: '확인 요청?',
						text: "입력한 정보를 정말 제출하시겠습니까?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: '예',
						cancelButtonText: '아니오'
					}).then((result) => {
						if (result.isConfirmed) {
							sendInfo(updatedState);
						}
					});
				}
			};
			reader.readAsDataURL(cc);

		});

	})

	const sendInfo = (e) => {
		axios.post("/updatewithattach",
			{
				bpname: "SHI Change Order",
				project: currentBond.projectCode,
				record: currentBond.record_no,
				files: e
			})
			.then(response => {
				if (response.data.success) {
					Swal.fire({
						title: 'Success',
						text: '성공적으로 제출되었습니다.',
						icon: 'success',
						confirmButtonText: 'OK'
					})
						.then(() => {
							window.location.reload();
						});
				} else {
					let errorMessage = response.data.error || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
					Swal.fire({
						title: 'Error',
						text: errorMessage,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}
			})
			.catch(err => {
				let errorMessage = '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
				if (err.response && err.response.data && err.response.data.error) {
					errorMessage += ' ' + err.response.data.error;
				}
				Swal.fire({
					title: 'Error',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	}

	console.log(currentBond)

	return (

		<>

			{visibleDialogDetail &&
				<Dialog title={tableData.title + " 내역"} width={1200} onClose={handleCloseModalDetail}>
					<LocalizationProvider language="ko-KR">
						<IntlProvider locale="kr">
							<ExcelExport data={tableData.details} ref={_export}>
								<Grid data={tableData.details.slice(page.skip, page.take + page.skip)} onPageChange={pageChange}
									skip={page.skip} take={page.take} total={tableData.details.length}
									pageable={{
										buttonCount: 4,
										pageSizes: [5, 10, 15, 'All'],
										pageSizeValue: pageSizeValue
									}}
									style={{ maxHeight: 620 }} >
									<GridToolbar>
										<button title="Export Excel" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={excelExport}>
											엑셀 다운로드
										</button>
									</GridToolbar>
									<Column field="li_num" title="Id" width="80px" />
									<Column field="type" title="유형" width="80px" />
									<Column field="SecCostCodeLv7" title="중공종" width="200px" />
									<Column field="SecCostCodeLv8" title="소공종" width="200px" />
									<Column field="spocItemName" title="품명" width="200px" />
									<Column field="spocSpec" title="규격" width="250px" />
									<Column field="ItemSpecDesc" title="품명/규격 상세" width="250px" />
									<Column field="spocUoMTxt" title="단위" width="80px" />
									<Column field="OriginTotalQuantity" title="최초 계약 수량" width="100px" />
									<Column field="CumulChQuantity" title="누적 증감 수량" width="100px" />
									<Column field="CurrentTotalQuantity" title="현 계약 수량" width="100px" />
									<Column field="ItemQuantity" title="금회 증감 수량" width="100px" />
									<Column field="MainItem_srb" title="주요자재" width="100px" />
									<Column field="MatSupplyPD" title="사급자재/bulk" width="100px" />
									<Column field="spocMarginPerc" title="할증율(%)" width="100px" />
									<Column field="TotalQuantity" title="총수량 (변경분)" width="150px" />
									<Column field="COMaterialCost" title="자재단가" width="150px" />
									<Column field="MaterialCostTotalCO" title="자재 금액" width="150px" />
									<Column field="COLaborCost" title="노무단가" width="150px" />
									<Column field="LaborCostTotalCO" title="노무 금액" width="150px" />
									<Column field="COExpense" title="경비단가" width="150px" />
									<Column field="ExpenseTotalCO" title="경비 금액" width="150px" />
						
							
								</Grid>
							</ExcelExport>
						</IntlProvider>
					</LocalizationProvider>
				</Dialog>
			}
			{visibleDialog &&
				<Dialog title={(currentBond?.["계약명"] ?? "") + " 변경 계약 보증보험 파일 업로드"} width={600} onClose={handleCloseModal}>
					<Form className="my-auto pb-5" onSubmitClick={onStepSubmit} render={formRenderProps => <div>
						{/* 스텝 바디 */}
						<FormElement>
							<Field
								key={currentBond.record_no} className="attach"
								id={currentBond.record_no}
								name={currentBond.record_no}
								label={'변경 계약 보증보험 파일 첨부'}
								// hint={`파일을 pdf로 올리세요.${currentBond.보증필요리스트2.join(";")}을 한번에 첨부하세요.`} 
								component={FormUpload2}
							/>
							<div className="d-flex flex-stack pt-15" style={{ justifyContent: 'center' }}>
								<Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> 업로드</Button>
							</div>
						</FormElement>
					</div>} />

				</Dialog>
			}
			<div className='app-content flex-column-fluid'>
				<div className='app-container container-xxl'>

					<div className="card mb-5 mb-xl-8">
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label fw-bold fs-3 mb-1">변경 계약 검토</span>
								<span className="text-muted mt-1 fw-semibold fs-7">{data.filter(v=>v.status ==="Contract_Transferred").length}번의 계약 검토 요청이 있습니다.</span>
							</h3>
							<div className="card-toolbar">
							</div>
						</div>
						<div className="card-body py-3">
							<div className="table-responsive">
								<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
									<thead>
										<tr className="fw-bold text-muted">

											<th className="min-w-100px">프로젝트 이름</th>
											<th className="min-w-150px">계약명</th>
											<th className="min-w-100px"></th>
											<th className="min-w-50px">변경계약 차수</th>
											<th className="min-w-150px">변경계약 내용</th>
											<th className="min-w-100px">변경계약 내역 보기</th>
											<th className="min-w-100px">계약 기간</th>
											<th className="min-w-100px">변경계약 기간</th>
											<th className="min-w-100px">변경계약 변경분(공급원가)</th>
											<th className="min-w-100px">변경계약 후 총 금액(공급원가)</th>
											<th className="min-w-100px">보증보험 업로드</th>
										</tr>
									</thead>
									<tbody>
										{data.length > 0 && data.map((v, index) => (
											<tr key={index}>
												<td className="text-dark fw-bold text-hover-primary fs-7">{v.projectName}</td>
												<td className="text-dark fw-bold text-hover-primary fs-7">{v.계약명}</td>
												<td>
													<>
														<button className="btn btn-sm btn-light" style={{ padding: 5, display: v.status === "Contract_Transferred" ? "block" : "none" }} onClick={() => handleButtonAPIClick(v)}>수정 요청</button> <br />
														<button className="btn btn-sm btn-light" style={{ padding: 5, display: v.status === "Contract_Transferred" ? "block" : "none" }} onClick={() => handleButtonAPIClick2(v)}>서명 진행</button>
													</>

												</td>
												<td className="text-dark fw-bold text-hover-primary fs-7">{v["변경계약 차수"]}</td>
												<td className="text-dark fw-bold text-hover-primary fs-7">{v["변경계약 내용"]}</td>
												<td className="text-dark fw-bold text-hover-primary fs-7">
													<span
														onClick={(e) => {
															e.preventDefault();
															handleButtonClickDetail(v);
														}}
														className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
													>
														<i className="ki-duotone  ki-book-square fs-3">
															<span className="path1"></span>
															<span className="path2"></span>
															<span className="path3"></span>
														</i>
													</span>
												</td>
												<td>
													<span className="text-dark text-hover-primary fs-7 d-block">{dayjs((v["계약 시작일"])).format("YYYY년 MM월 DD일")}</span>
													<span className="text-dark text-hover-primary fs-7 d-block">{dayjs(v["계약 종료일"]).format("YYYY년 MM월 DD일")}</span>
												</td>
												<td>
													<span className="text-dark text-hover-primary fs-7 d-block">{dayjs(v["계약 시작일"]).format("YYYY년 MM월 DD일")}</span>
													<span className="text-dark text-hover-primary fs-7 d-block">{dayjs(v["변경계약 종료일"]).format("YYYY년 MM월 DD일")}</span>
												</td>

												<td className="text-dark fw-bold text-hover-primary fs-7">{v["변경계약 변경분"].toLocaleString()}원</td>
												<td className="text-dark fw-bold text-hover-primary fs-7">{v["변경계약 후 총금액"].toLocaleString()}원</td>
												<td className="text-dark fw-bold text-hover-primary fs-6" >

													<span onClick={(e) => { e.preventDefault(); handleButtonClick(v); }} className="btn-active-color-primary" style={{ cursor: "pointer", display: "flex", alignItems: "center", display: v.status === "Sign_Completed" ? "block" : "none" }}>
														<i className="ki-duotone ki-folder-up fs-3">
															<span className="path1"></span>
															<span className="path2"></span>
														</i>
													</span>
												</td>

												<td className="text-end">

												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
};

export default COPage;