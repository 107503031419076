import React, { useState, useEffect } from 'react';

import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useRecoilState} from 'recoil';
import { validState } from '../atom/validState';
import { FormUpload } from './multistep/form-components';
import { useNavigate } from 'react-router-dom'; // 변경: useHistory 대신 useNavigate 사용
import { Field } from '@progress/kendo-react-form';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { businessType2 } from '../atom/businessType2';

export const BusinessRegister = () => {

  const navigate = useNavigate(); // 변경: useHistory 대신 useNavigate 사용

  

  const [valid, setValid] = useRecoilState(validState);
  const [businessType, setBusinessType] = useRecoilState(businessType2); // 기본값을 법인사업자로 설정


  const onStepSubmit = React.useCallback(event => {
    navigate("/signup", { state: { businessType: businessType } }); // 변경: history.push 대신 navigate 사용
  }, [navigate, businessType]); //

  const handleValidChange = (newValid) => {
    setValid(newValid);
  };

  const typeData = [
    {
      label: "법인사업자",
      value: "corporate",
    },
    {
      label: "일반과세자",
      value: "individual",
    },

  ];


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerStyle = isMobile 
    ? {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width:window.innerWidth,
      position:"absolute",
      top:"50%",
      left:"50%",
      transform:"translate(-50%, -50%)",
      borderRadius: 10,
      boxShadow:"0 5px 10px 0px rgba(0, 0, 0, 0.1)",
      padding:"33px 33px 33px 33px",
      background:"#fff"

    }
    : {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width:600,
      position:"absolute",
      top:"50%",
      left:"50%",
      transform:"translate(-50%, -50%)",
      borderRadius: 10,
      boxShadow:"0 5px 10px 0px rgba(0, 0, 0, 0.1)",
      padding:"33px 33px 33px 33px",
      background:"#fff"

    };




    return <div style={{width:"100%", height:"100vh",background:"#f2f2f2"}}><div style={containerStyle}>
          <Form onSubmitClick={onStepSubmit} render={formRenderProps => <div style={{alignSelf: 'center'}}>
          <FormElement style={{width:isMobile?window.innerWidth-60: 480}}>

      {/* 사업자 유형 선택 */}
      <div>
          <label>사업자 유형 선택:</label>
          <RadioGroup
            data={typeData}
            value={businessType}
            onChange={(e) => setBusinessType(e.value)}
          >

          </RadioGroup>
        </div>

        {/* 파일 업로드 필드 */}
        <div>
          <Field 
            key={'business'} 
            id={'business'} 
            name={'business'} 
            label={'사업자 등록증 첨부'} 
            hint={'사업자 등록증 파일을 pdf나 이미지로 올리세요'} 
            component={FormUpload} 
            businessType={businessType} // FormUpload 컴포넌트로 사업자 유형 전달
            onValidChange={handleValidChange} 
            isMobile={isMobile}
          />
        </div>


                  <span style={{marginTop: '40px'}} className={'k-form-separator'} />
                  <div style={{justifyContent: 'space-between', alignContent: 'center', float:'right'}} className={'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>

                    <div>
                      <Button themeColor={'primary'} disabled={!(formRenderProps.allowSubmit && valid)} onClick={formRenderProps.onSubmit}>
                        제출
                      </Button>
                    </div>
                  </div>
                </FormElement>
              </div>} />
        </div>
        </div>;
  };