import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';

const Profile = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get('/profile'); 
            setData(res.data.data);
          } catch (error) {
            console.error('데이터 가져오기 중 오류 발생:', error);
          }
        };
        fetchData();
      }, []);

return (
    <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>
            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                    <div className="card-header cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">Profile Details</h3>
                        </div>
                        {/* <a href="../../demo1/dist/account/settings.html" className="btn btn-sm btn-primary align-self-center">Edit Profile</a> */}
                    </div>
                    <div className="card-body p-9">
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">법인명</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data.법인명}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">본점소재지</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data.본점소재지}</span>
                            </div>
                        </div> 
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">대표자 성명</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["대표자 성명"]}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">사업자등록번호</label>
                            <div className="col-lg-8 fv-row">
                                <span className="fw-bold text-gray-800 fs-6">{data["사업자등록번호"]}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">대표 번호
                            {/* <span className="ms-1" data-bs-toggle="tooltip" title="Phone number must be active">
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span> */}
                            </label>
                            <div className="col-lg-8 d-flex align-items-center">
                                <span className="fw-bold fs-6 text-gray-800 me-2">{data["대표 번호"]}</span>
                                {/* <span className="badge badge-success">Verified</span> */}
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">대표 이메일</label>
                            <div className="col-lg-8">
                                <a href="#" className="fw-semibold fs-6 text-gray-800 text-hover-primary">{data["대표 이메일"]}</a>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted"><span  style={{color:data["입찰 이메일"] === null?"#cd212a":''}} >입찰 이메일</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="이 이메일로 입찰 초정이 되니 항상 확인바랍니다.">
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                            </label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["입찰 이메일"] === null?"입찰 이메일이 없으므로 입찰에 참여할 수 없습니다.":data["입찰 이메일"] }</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">업체 유형</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["업체 유형"]}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">참여 공종</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["참여 공종"]}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">대표 공종</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["대표 공종"]}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">신용등급</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["신용등급"]}</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">현금흐름등급</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">{data["현금흐름등급"]}</span>
                            </div>
                        </div>
                        
                        <div className="row mb-10">
                            <label className="col-lg-4 fw-semibold text-muted">보유 면허</label>
                            <div className="col-lg-8">
                                <span className="fw-semibold fs-6 text-gray-800">{data["보유 면허"]}</span>
                            </div>
                        </div>
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                            <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                            <div className="d-flex flex-stack flex-grow-1">
                                <div className="fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">정보의 최신성 유지!</h4>
                                    <div className="fs-6 text-gray-700">위 정보는 항상 업데이트하시기 바랍니다. 관련하여 문의가 있으시면 다음을 클릭하세요.
                                    <a className="fw-bold" href="../../demo1/dist/account/billing.html">관리자에게 연락하기</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
			</div>
    </div>
    </div>
)};

export default Profile;