import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, FormElement, Field, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload';
import dayjs from 'dayjs';
import { Button } from '@progress/kendo-react-buttons';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
} from "@progress/kendo-react-intl";
import krMessage2 from '../login/multistep/kr.json';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { FormDatePicker, FormInput } from '../pages/newEvaluation/steps/forms';
import { emailValidator, requiredValidator } from '../login/multistep/validators';
import { useNavigate } from 'react-router-dom'; // 변경된 부분
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';

loadMessages(krMessage2, "ko-KR");


const uploadRef = React.createRef();

const FormUpload2 = fieldRenderProps => {
	const {
		value,
		id,
		optional,
		label,
		hint,
		validationMessage,
		touched,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';
	const onChangeHandler = event => {
		fieldRenderProps.onChange({
			value: event.newState
		});
	};
	const onRemoveHandler = event => {
		fieldRenderProps.onChange({
			value: event.newState
		});
	};

	const display = fieldRenderProps.value && fieldRenderProps.value.length > 0 ? "none" : 'block';

	return <FieldWrapper>
		<Label id={labelId} editorId={id} optional={optional} className='k-form-label'>
			{label}
		</Label>
		<LocalizationProvider language="ko-KR">
			<IntlProvider locale="kr">
				<div className={'k-form-field-wrap'}>
					<Upload ref={uploadRef}
						// restrictions={{
						//     allowedExtensions: [".pdf"],
						//   }} 
						id={id} autoUpload={false} showActionButtons={false} multiple={true} files={value} onAdd={onChangeHandler} onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />
					{showHint && <Hint id={hintId}>{hint}</Hint>}
					{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
				</div>
				<div
					style={{
						height: "10px",
					}}></div>
				<div style={{ display: display }}>
					<ExternalDropZone uploadRef={uploadRef} />
				</div>
			</IntlProvider>
		</LocalizationProvider>
	</FieldWrapper>;
};


const SafetyPage2 = () => {
	const [data, setData] = useState([]);
	const [visibleDialog, setVisibleDialog] = useState(false);
	const [visibleDialog2, setVisibleDialog2] = useState(false);
	const [visibleDialog3, setVisibleDialog3] = useState(false);
	const [currentBond, setCurrentBond] = useState(null);
	const [current, setCurrent] = useState(null);
	const [filelist, setFileList] = useState(null);
	const navigate = useNavigate(); // 변경된 부분


	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get('/getsafetyList2');
				if (res.data.success) {
					setData(res.data.data);
				}

			} catch (error) {
				console.error('데이터 가져오기 중 오류 발생:', error);
			}
		};
		fetchData();
	}, []);

	const handleButtonClick = (e) => {
		setVisibleDialog(true);
		setCurrentBond(e)

	};

	const handleButtonClickDown = async (e) => {
		const getfilelist = await axios.post("/getfilelist", { "bpname": "Regular Safety Technical Guidance", "record_no": e.record_no, "project": e.projectCode })
		setFileList(getfilelist.data.data)
		setVisibleDialog3(true);
	};


	const handleButtonFileDown = async (e) => {
		try {
			const response = await axios.get(`/getfile?fileid=${e}`);

			if (response.data.success) {


				// base64 문자열에서 데이터 부분만 추출
				const base64Response = response.data.data
				// .split(';base64,').pop();

				// Blob 객체 생성
				const blob = new Blob([base64toBlob(base64Response, 'application/zip')], { type: 'application/zip' });

				// Blob 객체를 사용하여 다운로드 링크 생성
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = '정기안전기술지도_파일.zip'; // 다운로드 파일명 설정
				document.body.appendChild(link);
				link.click();

				// 링크 제거
				document.body.removeChild(link);
			} else {
				Swal.fire({
					icon: 'error',
					title: '다운로드 중에 오류가 발생했습니다. 관리자에게 연락하시기 바랍니다.',
					confirmButtonText: '확인'
				});
			}

		} catch (error) {
			console.error('다운로드 중 오류 발생', error);
		}
	};

	// base64 문자열을 Blob으로 변환하는 함수
	function base64toBlob(base64Data, contentType = '', sliceSize = 512) {
		const byteCharacters = atob(base64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}



	const handleCloseModalDown = () => {
		setVisibleDialog3(false);
		setFileList(null)
	};


	const handleCloseModal = () => {
		setVisibleDialog(false);
		setCurrentBond(null)
	};

	const handleButtonClick2 = (e) => {
		setVisibleDialog2(true);
		setCurrent(e)

	};

	const handleCloseModal2 = () => {
		setVisibleDialog2(false);
		setCurrent(null)
	};


	const onStepSubmit = React.useCallback(event => {

		const affectedFiles = event.values[currentBond.record_no]
		const updatedState = [];

		affectedFiles.forEach((file) => {
			const cc = file.getRawFile();
			const filetype = file.extension;

			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};
				updatedState.push(updatedFile);

				if (updatedState.length === affectedFiles.length) {
					handleCloseModal();
					Swal.fire({
						title: '확인 요청?',
						text: "입력한 정보를 정말 제출하시겠습니까?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: '예',
						cancelButtonText: '아니오'
					}).then((result) => {
						if (result.isConfirmed) {
							sendInfo(updatedState);
						}
					});
				}
			};
			reader.readAsDataURL(cc);

		});

	})


	const sendInfo = (e) => {
		axios.post("/updatewithattach",
			{
				bpname: "Regular Safety Technical Guidance",
				project: currentBond.projectCode,
				record: currentBond.record_no,
				workflow_details: {
					"WFCurrentStepName": "평가자료 업데이트 (협력업체)",
					"WFActionName": "평가자료 전송",
				},
				files: e
			})
			.then(response => {
				if (response.data.success) {
					Swal.fire({
						title: 'Success',
						text: '성공적으로 제출되었습니다.',
						icon: 'success',
						confirmButtonText: 'OK'
					})
						.then(() => {
							window.location.reload();
						});
				} else {
					let errorMessage = response.data.error || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
					Swal.fire({
						title: 'Error',
						text: errorMessage,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}
			})
			.catch(err => {
				let errorMessage = '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
				if (err.response && err.response.data && err.response.data.error) {
					errorMessage += ' ' + err.response.data.error;
				}
				Swal.fire({
					title: 'Error',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	}

	const onStepSubmitInfo = React.useCallback(event => {
		const { values } = event;

		const dataSend = {
			record_no: current.record_no,
			project: current.projectCode,
			wf: { "WFCurrentStepName": "필수정보 업데이트 (협력업체)", "WFActionName": "제출" },
			bpname: "Regular Safety Technical Guidance",
			RSTGSMNM: values.RSTGSMNM,
			RSTGSMContactNo: values.RSTGSMContactNo,
			RSTGHSEPICNM: values.RSTGHSEPICNM,
			RSTGHSEContactNo: values.RSTGHSEContactNo,
			RSTGSiteEmail: values.RSTGSiteEmail,
			RSTGPICEmail: values.RSTGPICEmail,
			RSTGSiteOfficeAddress: values.RSTGSiteOfficeAddress,
			CommenceDate: dayjs(values.CommenceDate).format("MM-DD-YYYY"),
			RSTGVisitDate: dayjs(values.RSTGVisitDate).format("MM-DD-YYYY")
		}


		// 모든 키에 대한 값이 존재하는지 확인
		const allKeysHaveValues = Object.keys(dataSend).every(key => dataSend[key]);

		if (allKeysHaveValues) {
			setVisibleDialog2(false); // 여기에 추가
			Swal.fire({
				title: '제출하시겠습니까?',
				text: "이 작업은 되돌릴 수 없습니다!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: '예',
				cancelButtonText: '아니오'
			}).then((result) => {
				if (result.isConfirmed) {
					sendInfo2(dataSend);
				}
			});
		} else {
			// 필요한 경우, 사용자에게 모든 필드가 채워져야 함을 알리는 메시지를 표시할 수 있습니다.
			alert("모든 필드를 채워주세요.");
		}
	})


	const sendInfo2 = (dataSend) => {
		axios.post("/updaterecord", { dataSend })
			.then(response => {
				if (response.data.success) { // Assuming the API returns a `success` property in the data
					Swal.fire({
						title: '성공',
						text: '성공적으로 제출되었습니다.',
						icon: 'success',
						confirmButtonText: 'OK'
					}).then(() => {
						navigate('/home');
					});
				} else {
					Swal.fire({
						title: 'Error',
						text: response.data.data,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}
			})
			.catch(err => {
				const errorMessage = err.message || '제출에 문제가 생겼습니다. 시스템 관리자에 문의하세요.';
				Swal.fire({
					title: 'Error',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	};


	const MyItemRender = props => {
		let item = props.dataItem;
		return <div className='k-listview-item row p-2 border-bottom align-middle' style={{
			margin: 0
		}}>
			<div className='col-10'>
				<h2 style={{
					fontSize: 14,
					color: '#454545',
					marginBottom: 0
				}}>{item.file_name}</h2>
			</div>
			<div className='col-2'>
				<span onClick={(e) => { e.preventDefault(); handleButtonFileDown(item.file_id); }} className="btn-active-color-primary" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
					<i className="ki-duotone ki-folder-down fs-3">
						<span className="path1"></span>
						<span className="path2"></span>
					</i>
				</span>
			</div>
		</div>;
	};

	return (
		<>
			<div className='app-content flex-column-fluid'>
				<div className='app-container container-xxl'>

					<div className="card mb-5 mb-xl-8">
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label fw-bold fs-3 mb-1">정기 안전기술 지도</span>
								<span className="text-muted mt-1 fw-semibold fs-7">
									{data.filter(v => v.status === "Portal_Transferred").length > 0 ?
										"정기 안전기술 지도를 위해 입력할 " + data.filter(v => v.status === "Portal_Transferred").length + "건이 있습니다."
										: "정기 안전기술 지도를 위해 업로드해야할 " + data.filter(v => v.status === "Eval_Requested").length + "건이 있습니다."
									}</span>
							</h3>
							<div className="card-toolbar">
							</div>
						</div>
						<div className="card-body py-3">
							<div className="table-responsive">
								<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
									<thead>
										<tr className="fw-bold text-muted">

											<th className="min-w-100px">프로젝트 이름</th>
											<th className="min-w-250px">계약명</th>
											<th className="min-w-100px">상태</th>
											<th className="min-w-180px">계약일</th>
											<th className="min-w-180px">공사투입일</th>
											<th className="min-w-180px">방문점검일</th>
											<th className="min-w-80px">현장소장명</th>
											<th className="min-w-80px">평가점수</th>
											<th className="min-w-80px">정보 제출</th>
											<th className="min-w-100px">자료 다운로드</th>
											<th className="min-w-100px">자료 업로드</th>

										</tr>
									</thead>
									<tbody>
										{data && data.map((v, index) => (
											<tr key={index}>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.projectName}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.uconTitleSD}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.status === "Portal_Transferred" ? "정보 업데이트 필요" : v.status === "Eval_Requested" ? "자료 제출 필요" : "평가 완료"}</td>
												<td className="text-dark text-hover-primary fs-8">{v.ContractSignDate && dayjs(v.ContractSignDate).format("YYYY년 MM월 DD일")}</td>
												<td className="text-dark text-hover-primary fs-8">{v.CommenceDate && dayjs(v.CommenceDate).format("YYYY년 MM월 DD일")}</td>
												<td className="text-dark text-hover-primary fs-8">{v.RSTGVisitDate && dayjs(v.RSTGVisitDate).format("YYYY년 MM월 DD일")}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.RSTGSMNM}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">{v.RSTGEvalPoint}</td>
												<td className="text-dark fw-bold text-hover-primary fs-6" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 0 }}>
													{v.status === "Portal_Transferred" ?
														<span onClick={(e) => { e.preventDefault(); handleButtonClick2(v); }} className="btn-active-color-primary" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
															<i className="ki-duotone ki-pencil fs-3">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
														:
														""}
												</td>
												<td className="text-dark fw-bold text-hover-primary fs-6">
													{v.status === "Portal_Transferred" ? "" :
														<span onClick={(e) => { e.preventDefault(); handleButtonClickDown(v); }} className="btn-active-color-primary" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
															<i className="ki-duotone ki-magnifier fs-3">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>}
												</td>
												<td className="text-dark fw-bold text-hover-primary fs-6" >
													{v.status === "Portal_Transferred" ? "" :
														<span onClick={(e) => { e.preventDefault(); handleButtonClick(v); }} className="btn-active-color-primary" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
															<i className="ki-duotone ki-folder-up fs-3">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			{visibleDialog &&
				<Dialog title={currentBond.uconTitleSD + " 정기안전기술지도 자료 업로드"} width={600} onClose={handleCloseModal}>
					<Form className="my-auto pb-5" onSubmitClick={onStepSubmit} render={formRenderProps => <div>
						{/* 스텝 바디 */}
						<FormElement>
							<Field
								key={currentBond.record_no} className="attach"
								id={currentBond.record_no}
								name={currentBond.record_no}
								label={'정기안전기술지도 파일 첨부'}
								// hint={`파일을 pdf로 올리세요.${currentBond.보증필요리스트2.join(";")}을 한번에 첨부하세요.`} 
								component={FormUpload2}
							/>
							<div className="d-flex flex-stack pt-15" style={{ justifyContent: 'center' }}>
								<Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> 업로드</Button>
							</div>
						</FormElement>
					</div>} />

				</Dialog>
			}
			{visibleDialog2 &&
				<Dialog title={current.uconTitleSD + " 정보 업데이트"} width={800} onClose={handleCloseModal2}>
					<div className='app-content flex-column-fluid' style={{ height: 600 }}>
						<div className='app-container container-xxl'>
							<div className="card mb-5 mb-xl-8">
								<div className="card-body py-3">
									<Form className="my-auto pb-5" onSubmitClick={onStepSubmitInfo} render={formRenderProps => <div>

										<FormElement>
											<Field key={'CommenceDate'} id={'CommenceDate'} name={"CommenceDate"} label={'공사 착수일(예정일)'} component={FormDatePicker} validator={requiredValidator} />
											<Field key={'RSTGVisitDate'} id={'RSTGVisitDate'} name={"RSTGVisitDate"} label={'방문 점검 일자'} component={FormDatePicker} validator={requiredValidator} />
											<br />
											<Field key={'RSTGSMNM'} id={'RSTGSMNM'} name={"RSTGSMNM"} label={'현장소장명'} component={FormInput} validator={requiredValidator} />
											<Field key={'RSTGSMContactNo'} id={'RSTGSMContactNo'} name={"RSTGSMContactNo"} label={'현장소장 연락처'} component={FormInput} validator={requiredValidator} />
											<Field key={'RSTGHSEPICNM'} id={'RSTGHSEPICNM'} name={"RSTGHSEPICNM"} label={'안전담당자명'} component={FormInput} validator={requiredValidator} />
											<Field key={'RSTGHSEContactNo'} id={'RSTGHSEContactNo'} name={"RSTGHSEContactNo"} label={'안전담당자 연락처'} component={FormInput} validator={requiredValidator} />
											<Field key={'RSTGSiteEmail'} id={'RSTGSiteEmail'} name={"RSTGSiteEmail"} label={'이메일 공용'} component={FormInput} validator={emailValidator} />
											<Field key={'RSTGPICEmail'} id={'RSTGPICEmail'} name={"RSTGPICEmail"} label={'이메일 담당자'} component={FormInput} validator={emailValidator} />
											<Field key={'RSTGSiteOfficeAddress'} id={'RSTGSiteOfficeAddress'} name={"RSTGSiteOfficeAddress"} label={'협력사 사무실 주소'} component={FormInput} validator={requiredValidator} />

											<div className="d-flex flex-stack pt-15">
												<div className="mr-2">
												</div>
												<div>

													<Button type="button" className="btn btn-lg btn-primary" disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}> {"제출"}
														<i className="ki-duotone ki-send fs-4 ms-1">
															<span className="path1"></span>
															<span className="path2"></span>
														</i></Button>
												</div>
											</div>
										</FormElement>
									</div>} />
								</div>
							</div>
						</div>
					</div>
				</Dialog>
			}
			{visibleDialog3 &&
				<Dialog title={"파일 리스트"} width={400} onClose={handleCloseModalDown}>
					<ListView data={filelist} item={MyItemRender} style={{ width: "100%" }} />
				</Dialog>
			}
		</>
	)
};

export default SafetyPage2;